import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InvestorDashboardState } from "./investor.dashboard.reducer";

export const selectInvestorDashboardState = createFeatureSelector<InvestorDashboardState>("investorDashboard");

export const getInvestorDashboard = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state.investorDashboard
)

export const getInvestorProfileCompleteness = createSelector(
  selectInvestorDashboardState,
  (state: InvestorDashboardState) => state.investorProfileCompleteness
)

export const getIndividualInvestorProfileCompleteness = createSelector(
  selectInvestorDashboardState,
  (state: InvestorDashboardState) => state.individualInvestorProfileCompleteness
)

export const sendRequestApproval = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state
)

export const sendRequestApprovalSuccess = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state.requestApproval
)

export const sendRequestApprovalFail = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state.requestApprovalError
)

export const providingFundRequest = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state
)

export const providingFundRequestSuccess = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state.providingFundsRequest
)

export const providingFundRequestFault = createSelector(
    selectInvestorDashboardState,
    (state: InvestorDashboardState) => state.providingFundsRequestError
)
