import { ServiceProviderInformationResponseModel } from "../../domain";
import {
  ServiceProviderInfoAction,
  ServiceProviderInfoActionTypes
} from "./service-provider-info.action";

export interface ServiceProviderInfoState {
  serviceProvider: ServiceProviderInformationResponseModel,
  error: string | null
}

export const initialState: ServiceProviderInfoState = {
  serviceProvider: null,
  error: ""

};

function serviceProviderInfoSuccess(state: ServiceProviderInfoState = initialState, data: ServiceProviderInformationResponseModel): ServiceProviderInfoState {
  return {
    ...state,
    serviceProvider: data,
    error: ""
  };
}

function serviceProviderInfoFault(state: ServiceProviderInfoState = initialState, data: string): ServiceProviderInfoState {
  return {
    ...state,
    serviceProvider: null,
    error: data
  };
}

export function serviceProviderReducer(state: ServiceProviderInfoState = initialState, action: ServiceProviderInfoAction): ServiceProviderInfoState {
  switch (action.type) {
    case ServiceProviderInfoActionTypes.GetServiceProviderInfoSuccess:
      return serviceProviderInfoSuccess(state, action.payload);
    case ServiceProviderInfoActionTypes.ServiceProviderInfoFault:
      return serviceProviderInfoFault(state, action.payload);
    default:
      return state;
  }
}


