import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MentorSearchCardComponent } from './mentor-search-card.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';

@NgModule({
  declarations: [MentorSearchCardComponent],

    imports: [CommonModule, NgbTooltipModule, AvatarModule, NgbModule, PipesModule],
  exports: [MentorSearchCardComponent]

})
export class MentorSearchCardModule { }
