import { Action } from "@ngrx/store";
import {
  CorporateInformationResponseModel,
} from "../../domain";

export enum CorporateInfoActionTypes {
  GetCorporateInfo = "[Corporate] GetCorporateInfo",
  GetCorporateInfoSuccess = "[Corporate] GetCorporateInfoSuccess",
  CorporateInfoFault = "[Corporate] CorporateInfoFault",
}

export class GetCorporateInfo implements Action {
  readonly type = CorporateInfoActionTypes.GetCorporateInfo;
}

export class CorporateInfoSuccess implements Action {
  readonly type = CorporateInfoActionTypes.GetCorporateInfoSuccess;

  constructor(public payload: CorporateInformationResponseModel) {
  }
}

export class CorporateInfoFault implements Action {
  readonly type = CorporateInfoActionTypes.CorporateInfoFault;

  constructor(public payload: string) {
  }
}



export type CorporateInfoAction =
  | GetCorporateInfo
  | CorporateInfoSuccess
  | CorporateInfoFault
  ;
