import { Action } from "@ngrx/store";
import { IAds } from "../../domain/ads.model";

export enum AdsActionTypes {
  GetAds = "[ads] GetGlobalSettings",
  GetAdsSuccess = "[ads] GetGlobalSettingsSuccess",
  GetAdsFault = "[ads] GetGlobalSettingsFault",
}

export class GetAds implements Action {
  readonly type = AdsActionTypes.GetAds;
}

export class GetAdsSuccess implements Action {
  readonly type = AdsActionTypes.GetAdsSuccess;

  constructor(public payload: IAds) { }
}

export class GetAdsFault implements Action {
  readonly type = AdsActionTypes.GetAdsFault;
  constructor(public payload: string) { }
}

export type AdsActions =
  | GetAds
  | GetAdsSuccess
  | GetAdsFault;
