import { InvestorProfileCompleteness, InvestorProfileCompletenessResponse, StartupInvestorProfileCompletenessResponse } from "../../domain";
import { InvestorDashBoardActions, InvestorDashboardActionTypes } from "./investor.dashboard.action";

export interface InvestorDashboardState {
    investorDashboard: any;
    investorDashboardLoading: boolean;
    investorDashboardError: string | null | string[];

    requestApproval: string;
    requestApprovalLoading: boolean;
    requestApprovalError: string;

    providingFundsRequest: string;
    providingFundsRequestLoading: boolean;
    providingFundsRequestError: string;


    investorProfileCompleteness: InvestorProfileCompleteness;
    investorProfileCompletenessLoading: boolean;
    investorProfileCompletenessError: string | null | string[];


    individualInvestorProfileCompleteness: InvestorProfileCompleteness,
    individualInvestorProfileCompletenessLoading: boolean,
    individualInvestorProfileCompletenessError: string | null | string[];

}

export const initialState: InvestorDashboardState = {
    investorDashboard: null,
    investorDashboardLoading: false,
    investorDashboardError: "",

    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: "",

    providingFundsRequest: null,
    providingFundsRequestLoading: false,
    providingFundsRequestError: "",

    investorProfileCompleteness: null,
    investorProfileCompletenessLoading: false,
    investorProfileCompletenessError: "",



    individualInvestorProfileCompleteness: null,
    individualInvestorProfileCompletenessLoading: false,
    individualInvestorProfileCompletenessError: "",

};

function initInvestorDashboard(state: InvestorDashboardState = initialState): InvestorDashboardState {
    return {
        ...state,
        investorDashboardLoading: true,
        investorDashboardError: ""
    };
}

function investorDashboardSuccess(state: InvestorDashboardState = initialState, data: any): InvestorDashboardState {
    return {
        ...state,
        investorDashboard: data,
        investorDashboardLoading: false,
        investorDashboardError: ""
    }
}

function investorDashboardFailed(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
    return {
        ...state,
        investorDashboard: null,
        investorDashboardLoading: false,
        investorDashboardError: data
    }
}

function initRequestApproval(state: InvestorDashboardState = initialState): InvestorDashboardState {
    return {
        ...state,
        requestApprovalLoading: true,
        requestApprovalError: ""
    };
}

function requestApprovalSuccess(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
    return {
        ...state,
        requestApproval: data,
        requestApprovalLoading: false,
        requestApprovalError: ""
    }
}

function requestApprovalFailed(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
    return {
        ...state,
        requestApproval: null,
        requestApprovalLoading: false,
        requestApprovalError: data
    }
}

function initProvidingFundsRequest(state: InvestorDashboardState = initialState): InvestorDashboardState {
    return {
        ...state,
        providingFundsRequestLoading: true,
        providingFundsRequestError: ""
    };
}

function providingFundsRequestSuccess(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
    return {
        ...state,
        providingFundsRequest: data,
        providingFundsRequestLoading: false,
        providingFundsRequestError: ""
    }
}

function providingFundsRequestFailed(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
    return {
        ...state,
        providingFundsRequest: null,
        providingFundsRequestLoading: false,
        providingFundsRequestError: data
    }
}

function initInvestorProfileCompleteness(state: InvestorDashboardState = initialState): InvestorDashboardState {
  return {
      ...state,
      investorProfileCompletenessLoading: true,
      investorProfileCompletenessError: ""
  };
}

function investorProfileCompletenessSuccess(state: InvestorDashboardState = initialState, data: InvestorProfileCompleteness): InvestorDashboardState {
  return {
      ...state,
      investorProfileCompleteness: data,
      investorProfileCompletenessLoading: false,
      investorProfileCompletenessError: ""
  }
}

function investorProfileCompletenessFailed(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
  return {
      ...state,
      investorProfileCompleteness: null,
      investorProfileCompletenessLoading: false,
      investorProfileCompletenessError: data
  }
}



function initIndividualInvestorProfileCompleteness(state: InvestorDashboardState = initialState): InvestorDashboardState {
  return {
      ...state,
      individualInvestorProfileCompletenessLoading: true,
      individualInvestorProfileCompletenessError: ""
  };
}

function individualInvestorProfileCompletenessSuccess(state: InvestorDashboardState = initialState, data: InvestorProfileCompleteness): InvestorDashboardState {
  return {
      ...state,
      individualInvestorProfileCompleteness: data,
      individualInvestorProfileCompletenessLoading: false,
      individualInvestorProfileCompletenessError: ""
  }
}

function individualInvestorProfileCompletenessFailed(state: InvestorDashboardState = initialState, data: string): InvestorDashboardState {
  return {
      ...state,
      individualInvestorProfileCompleteness: null,
      individualInvestorProfileCompletenessLoading: false,
      individualInvestorProfileCompletenessError: data
  }
}


export function investorDashboardReducer(state: InvestorDashboardState = initialState, action: InvestorDashBoardActions): InvestorDashboardState {
    switch (action.type) {

        case InvestorDashboardActionTypes.GetInvestorDashboard:
            return initInvestorDashboard(state);

        case InvestorDashboardActionTypes.GetInvestorDashboardSuccess:
            return investorDashboardSuccess(state, action.payload);

        case InvestorDashboardActionTypes.GetInvestorDashboardFault:
            return investorDashboardFailed(state, action.payload);

        case InvestorDashboardActionTypes.SendRequestApproval:
            return initRequestApproval(state);

        case InvestorDashboardActionTypes.SendRequestApprovalSuccess:
            return requestApprovalSuccess(state, action.payload);

        case InvestorDashboardActionTypes.SendRequestApprovalFault:
            return requestApprovalFailed(state, action.payload);

        case InvestorDashboardActionTypes.ProvidingFundRequest:
            return initProvidingFundsRequest(state);

        case InvestorDashboardActionTypes.ProvidingFundRequestSuccess:
            return providingFundsRequestSuccess(state, action.payload);

        case InvestorDashboardActionTypes.ProvidingFundRequestFault:
            return providingFundsRequestFailed(state, action.payload);



        case InvestorDashboardActionTypes.GetProfileCompleteness:
            return initInvestorProfileCompleteness(state);

        case InvestorDashboardActionTypes.GetProfileCompletenessSuccess:
            return investorProfileCompletenessSuccess(state, action.payload);

        case InvestorDashboardActionTypes.GetProfileCompletenessFault:
            return investorProfileCompletenessFailed(state, action.payload);



        case InvestorDashboardActionTypes.GetIndividualProfileCompleteness:
          return initIndividualInvestorProfileCompleteness(state);

        case InvestorDashboardActionTypes.GetIndividualProfileCompletenessSuccess:
          return individualInvestorProfileCompletenessSuccess(state, action.payload);

        case InvestorDashboardActionTypes.GetIndividualProfileCompletenessFault:
          return individualInvestorProfileCompletenessFailed(state, action.payload);


        default:
            return state;
    }
}
