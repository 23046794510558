import { ServiceProviderProfileCompleteness } from "../../domain";
import { ServiceProviderDashBoardActions, ServiceProviderDashboardActionTypes } from "./service-provider.dashboard.action";

export interface ServiceProviderDashboardState {
  serviceProviderDashboard: any;
  serviceProviderDashboardLoading: boolean;
  serviceProviderDashboardError: string | null | string[];

  serviceProviderProfileCompleteness: ServiceProviderProfileCompleteness;
  serviceProviderProfileCompletenessLoading: boolean;
  serviceProviderProfileCompletenessError: string | null | string[];

  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;
}

export const initialState: ServiceProviderDashboardState = {
  serviceProviderDashboard: null,
  serviceProviderDashboardLoading: false,
  serviceProviderDashboardError: "",


  serviceProviderProfileCompleteness: null,
  serviceProviderProfileCompletenessLoading: false,
  serviceProviderProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initServiceProviderDashboard(state: ServiceProviderDashboardState = initialState): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderDashboardLoading: true,
    serviceProviderDashboardError: ""
  };
}

function serviceProviderDashboardSuccess(state: ServiceProviderDashboardState = initialState, data: any): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderDashboard: data,
    serviceProviderDashboardLoading: false,
    serviceProviderDashboardError: ""
  }
}

function serviceProviderDashboardFailed(state: ServiceProviderDashboardState = initialState, data: string): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderDashboard: null,
    serviceProviderDashboardLoading: false,
    serviceProviderDashboardError: data
  }
}

function initServiceProviderProfileCompleteness(state: ServiceProviderDashboardState = initialState): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderProfileCompletenessLoading: true,
    serviceProviderProfileCompletenessError: ""
  };
}

function serviceProviderProfileCompletenessSuccess(state: ServiceProviderDashboardState = initialState, data: ServiceProviderProfileCompleteness): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderProfileCompleteness: data,
    serviceProviderProfileCompletenessLoading: false,
    serviceProviderProfileCompletenessError: ""
  }
}

function serviceProviderProfileCompletenessFailed(state: ServiceProviderDashboardState = initialState, data: string): ServiceProviderDashboardState {
  return {
    ...state,
    serviceProviderProfileCompleteness: null,
    serviceProviderProfileCompletenessLoading: false,
    serviceProviderProfileCompletenessError: data
  }
}


function initRequestApproval(state: ServiceProviderDashboardState = initialState): ServiceProviderDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: ServiceProviderDashboardState = initialState, data: string): ServiceProviderDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: ServiceProviderDashboardState = initialState, data: string): ServiceProviderDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function serviceProviderDashboardReducer(state: ServiceProviderDashboardState = initialState, action: ServiceProviderDashBoardActions): ServiceProviderDashboardState {
  switch (action.type) {

    case ServiceProviderDashboardActionTypes.GetServiceProviderDashboard:
      return initServiceProviderDashboard(state);

    case ServiceProviderDashboardActionTypes.GetServiceProviderDashboardSuccess:
      return serviceProviderDashboardSuccess(state, action.payload);

    case ServiceProviderDashboardActionTypes.GetServiceProviderDashboardFault:
      return serviceProviderDashboardFailed(state, action.payload);


    case ServiceProviderDashboardActionTypes.GetProfileCompleteness:
      return initServiceProviderProfileCompleteness(state);

    case ServiceProviderDashboardActionTypes.GetProfileCompletenessSuccess:
      return serviceProviderProfileCompletenessSuccess(state, action.payload);

    case ServiceProviderDashboardActionTypes.GetProfileCompletenessFault:
      return serviceProviderProfileCompletenessFailed(state, action.payload);


    case ServiceProviderDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case ServiceProviderDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case ServiceProviderDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
