import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon, SweetAlertPosition } from 'sweetalert2';
import { ImagekitUrlAppenderPipe } from '../pipes/imagekit-url-appender.pipe';
import { AssetsImagekitUrlAppenderPipe } from '../pipes/asset-imagekit-url-appender.pipe';

@Injectable({ providedIn: 'root' })
export class ToastAlertService {

  constructor(private imagekitUrlAppenderPipe: ImagekitUrlAppenderPipe, private assetsImagekitUrlAppender: AssetsImagekitUrlAppenderPipe) { }

  /**
   * Show Toast
   * @param type
   * @param title
   * @param side
   * @param time
   * @param showProgressBar
   */
  showToast(title: string, type: SweetAlertIcon = "success", side: SweetAlertPosition = 'top-end', time: number = 3500, showProgressBar: boolean = true) {
    const Toast = Swal.mixin({
      toast: true,
      position: side ? side : 'top-end',
      showConfirmButton: false,
      timer: time,
      showCloseButton: true,
      timerProgressBar: showProgressBar ? showProgressBar : true,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })
    Toast.fire({
      icon: type,
      title: title,
    })
  }


  showHtmlToast(title: string, image: string, url: string, side: SweetAlertPosition = 'top-end', time: number = 10000, showProgressBar: boolean = true) {
    const imageUrl = !image ? '/assets/images/upload-image-placeholder.png' :this.imagekitUrlAppenderPipe.transform(image)+ '?tr=w-50,h-50,cm-pad_resize'
    const Toast = Swal.mixin({
      toast: true,
      position: side ? side : 'top-end',
      showConfirmButton: false,
      customClass: 'custom-swal-notification',
      timer: time,
      showCloseButton: true,
      timerProgressBar: showProgressBar ? showProgressBar : true,
      html: `
      <div class="d-flex align-items-center">
        <img src=${imageUrl} style="height: 50px;" class="me-3 rounded"/>

        <div class="d-flex flex-column">
          ${title}
          <a href="${url ?  url : ''}" class="${url? 'btn btn-sm btn-primary': 'd-none'}">
            View
          </a>
        </div>
      </div>
      `,

      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
      }
    })

    Toast.fire({
      // icon: type,
      // title: title,
    })
  }

}
