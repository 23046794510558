import { InvestmentInfoModel } from "./investment-types.model";
import { OrgRepresentativeModel } from "./org-representative.model";
import { IdNameOptions } from "./startup.model";

export interface OrganizationInformationModel {
    organizationName: string;
    establishmentYear: number;
    organizationTypeId: number;
    aboutUs: string;
    portfolioSize: number;
    website: string;
    displayWebsite?: string;
    organizationLogo: string;
    organizationType: OrganizationTypesModel;
    representative: OrgRepresentativeModel;
    investmentDetails: InvestmentInfoModel;
    uuid?: string;
    investorType: InvestorTypes;
    user: { accountRole: string; avatar: string; name: string, uuid: string }[],
    keyInvestments: string;
    linkedinUrl?: string;
    twitterUrl?: string;
    isApproved?: boolean;
    connectionRequestDocument?: string;
    askForConnectionDocument?: boolean;
    registeredCountry: string,registeredCity: string , registeredState: string
    registeredCountryR: IdNameOptions;
    registeredStateR: IdNameOptions;
    registeredCityR: IdNameOptions;

    shortProfileLink: string
    facebookUrl: string,
    instagramUrl: string,
    youtubeUrl: string,

}

export interface OrganizationTypesModel {
    id: number;
    isActive: boolean;
    name: string;
}


export interface OrganizationTypesReponseModel {
    organization_types: OrganizationTypesModel[];
}

export interface OrganizationInformationResponseModel {
    data: OrganizationInformationModel
    message: string;
    status_code: number;
}

export enum InvestorTypes {
    INDIVIDUAL = 'individual',
    ORGANIZATION = 'organization',
    SYNDICATE = 'syndicate',
}
