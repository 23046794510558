import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as ProgramOfficeDashboardActions from "./program-office.dashboard.action";
import { ProgramOfficeDashboardActionTypes } from './program-office.dashboard.action';
import { ProgramOfficeService } from "../../service/program-office.service";

@Injectable()
export class ProgramOfficeDashboardEffect {

  getProgramOfficeDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<ProgramOfficeDashboardActions.GetProgramOfficeDashboard>(ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboard),
    exhaustMap(() => this.programOfficeService.getProgramOfficeDashboard().pipe(
      map((data: any) => new ProgramOfficeDashboardActions.GetProgramOfficeDashboardSuccess(data)),
      catchError((err: HttpErrorResponse) => of(new ProgramOfficeDashboardActions.GetProgramOfficeDashboardFault(err.error.message)))
    ))
  ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<ProgramOfficeDashboardActions.GetProfileCompleteness>(ProgramOfficeDashboardActionTypes.GetProfileCompleteness),
    map((action: ProgramOfficeDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.programOfficeService.getProgramOfficeProfileCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new ProgramOfficeDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new ProgramOfficeDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<ProgramOfficeDashboardActions.SendRequestApproval>(ProgramOfficeDashboardActionTypes.SendRequestApproval),
    map((action: ProgramOfficeDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.programOfficeService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new ProgramOfficeDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new ProgramOfficeDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  constructor(
    private actions$: Actions,
    private programOfficeService: ProgramOfficeService
  ) { }
}
