import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import { IJobs } from "../domain/jobs.model";
import * as JobsAction from 'src/app/core/state/jobs';
import { Router } from "@angular/router";
import { CommunityWallFileType, ICommunityPost, ReactionType } from "../domain/community-feed";
import * as FeedAction from 'src/app/core/state/feed';

@Injectable({
  providedIn: 'root'
})
export class CommunityFeedService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store,
    private router: Router) { }

  public createPost(post: ICommunityPost): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts';
    return this.http.post(url, post).pipe(
      // tap(() => {
      //   this.getProfileCompleteness()
      // }),
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving post!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public getPosts(filters): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts';
    return this.http.get(url, { params: filters }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public getPostsByType(type: 'comments' | 'reactions', filters): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts/me/' + type;
    return this.http.get(url, { params: filters }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  public getPostsByUser(filters, userId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts/user/' + userId;
    return this.http.get(url, { params: filters }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public getStats(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts/me/stats';
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }


  public getCommentsOnPost(postId: string, filters = {}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comments`;
    return this.http.get(url, { params: filters }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }
  public getReactionsPost(postId: string, filters = {}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/reactions`;
    return this.http.get(url, { params: filters }).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public postComment(postId, comment): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comments`;
    return this.http.post(url, { comment }).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving comment!'), 'error');
        return throwError(() => fault);
      })
    );
  }
  public postCommentReply(postId, parentCommentId, comment): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comments/${parentCommentId}/reply`;
    return this.http.post(url, { comment }).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving comment!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteComment(postId, commentId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comments/${commentId}`;
    return this.http.delete(url).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting comment!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteCommentReply(postId, parentCommentId, replyId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comments/${replyId}`;
    return this.http.delete(url).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting comment!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deletePost(postId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}`;
    return this.http.delete(url).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while deleting comment!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public postReaction(postId, reaction: ReactionType): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/react/${reaction}`;
    return this.http.post(url, {}).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving reaction!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public reactionOnComment(postId, commentId, reaction: ReactionType): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/comment/${commentId}/react/${reaction}`;
    return this.http.post(url, {}).pipe(
      tap(()=> {
        this.store.dispatch(new FeedAction.GetStats());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving reaction!'), 'error');
        return throwError(() => fault);
      })
    );
  }



  public getReactionCount(postId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}/react/count`;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public uploadFile(logo: File, fileType: CommunityWallFileType): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    payload.append('fileType', fileType);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + 'posts/upload-file';
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading file'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updatePost(postId, post): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED) + `posts/${postId}`;
    return this.http.patch(url, post).pipe(
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating post!'), 'error');
        return throwError(() => fault);
      })
    );
  }



  public getSinglePost(postId): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMMUNITY_FEED_PUBLIC) + 'posts/' + postId;
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Post not found!'), 'error');
        return throwError(() => fault);
      })
    );
  }

}
