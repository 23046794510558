import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileDetails, IProfileType } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getProfileData, getProfileTypes } from 'src/app/core/state/profile';
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import * as AuthActions from "src/app/core/state/auth/auth.action";
import { StorageService } from '../../utils/storage.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';


@Component({
  selector: 'app-change-profile-type-modal',
  templateUrl: './change-profile-type-modal.component.html',
  styleUrls: ['./change-profile-type-modal.component.scss']
})
export class ChangeProfileTypeModalComponent implements OnInit, OnDestroy {
  profileTypes: IProfileType[] = []
  private destroyed$: Subject<void> = new Subject();
  pendingTypes = [];

  allPossibleTypes = [
    ACCOUNT_TYPE.STARTUP,
    ACCOUNT_TYPE.INVESTOR,
    ACCOUNT_TYPE.CORPORATE,
    ACCOUNT_TYPE.MENTOR,
    ACCOUNT_TYPE.SERVICE_PROVIDER,
  ]
  ACCOUNT_TYPE = ACCOUNT_TYPE

  labelMap = {
    [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'Service Provider'
  }

  view = 'default';
  public getProfileData$: Observable<IProfileDetails>;
  profileData: IProfileDetails;
  brandDetails: Partial<IBrandDetails>;

  brandKeyMap = {
    [ACCOUNT_TYPE.STARTUP]: 'startups',
    [ACCOUNT_TYPE.INVESTOR]: 'investors',
    [ACCOUNT_TYPE.MENTOR]: 'mentors',
    [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'service_providers',
    [ACCOUNT_TYPE.CORPORATE]: 'corporates',
  }

  constructor(
    private readonly store: Store,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private storageService: StorageService,
    private router: Router,
    private uiLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {


    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res
        this.store.pipe(select(getProfileTypes)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
          if (res) {
            if(!res.length) {
              this.store.dispatch(new ProfileActions.GetProfileTypes())
              return;
            }
            this.profileTypes = res;
            this.profileTypes = this.profileTypes.filter((e)=> {
              return this.brandDetails.users[this.brandKeyMap[e.profileType]]
            })

            this.pendingTypes = Object.values(this.allPossibleTypes).filter((e) => !res.find((t) => e === t.profileType))
            this.pendingTypes = this.pendingTypes.filter((e)=> {
              return this.brandDetails.users[this.brandKeyMap[e]]
            })
          }else{
            this.store.dispatch(new ProfileActions.GetProfileTypes())
          }
        })

      }
    })



    this.getProfileData$ = this.store.pipe(select(getProfileData));
    this.getProfileData$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.profileData = res;
      } else {
        this.profileData = undefined;
      }
    })


  }

  closeModal(): void {
    this.activeModal.close();
  }

  switchingTOType;
  switchType(type: IProfileType) {
    this.switchingTOType = type;
    this.uiLoader.start()
    this.profileService.switchProfileTypes(type.profileType).subscribe((res) => {
      if (res) {
        this.handleTypeSelectResponse(res)
      } else {
        this.uiLoader.stop()
      }
    }, err => {
      this.uiLoader.stop()
    })
  }

  get textLoading () {
    return this.switchingTOType ?
    `Switching profile. Please wait...`
     : `Please wait while we create your ${this.labelMap[this.createType] || this.createType}  profile. It may take a few seconds.`
  }

  handleTypeSelectResponse(res) {
    setTimeout(() => {
      this.storageService.setObject('user', res);
      this.store.dispatch(new AuthActions.VerifyOtpSuccess(res as any));

      setTimeout(() => {
        this.store.dispatch(new ProfileActions.GetProfile(true));
      }, 500);
    }, 500)
  }

  createType ;
  createProfile(payload) {
    this.createType = payload['userType'];
    this.uiLoader.start()
    this.profileService.createProfileTypes(payload).subscribe((res) => {
      if (res) {
        setTimeout(()=>{
          this.handleTypeSelectResponse(res)
        }, 2500)
      } else {
        this.uiLoader.stop()
      }
    }, err => {
      this.uiLoader.stop()
    })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleCreateProfile(type: ACCOUNT_TYPE) {
    if (type === ACCOUNT_TYPE.MENTOR || type === ACCOUNT_TYPE.SERVICE_PROVIDER) {
      const payload = {
        applyingForSpecificEvent: false,
        userType: type
      }
      this.createProfile(payload)
      return;
    }
    this.view = type
    console.log('view', this.view)
  }

  handleCancleCreateType() {
    this.view = 'default'
  }

}


// all
// =====
// userType

// investor
// ======
// investorType
// website (syndicate)
// organizationName (org)

// startup
// ====
// companyName
// servicesLookingFor

// corporate
// ======
// companyName
// designation
