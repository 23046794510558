import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum PartnerDashboardActionTypes {
  GetProfileCompleteness = "[Partner Dashboard] GetPartnerProfileCompleteness",
  GetProfileCompletenessSuccess = "[Partner Dashboard] GetPartnerProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[Partner Dashboard] GetPartnerProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[Partner Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[Partner Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[Partner Dashboard] SendRequestApprovalFault",

  GetPartnerDashboard = "[Partner Dashboard] GetPartnerDashboard",
  GetPartnerDashboardSuccess = "[Partner Dashboard] GetPartnerDashboardSuccess",
  GetPartnerDashboardFault = "[Partner Dashboard] GetPartnerDashboardFault",
}


export class GetPartnerDashboard implements Action {
  readonly type = PartnerDashboardActionTypes.GetPartnerDashboard;
}

export class GetPartnerDashboardSuccess implements Action {
  readonly type = PartnerDashboardActionTypes.GetPartnerDashboardSuccess;
  constructor(public payload: any) { }
}

export class GetPartnerDashboardFault implements Action {
  readonly type = PartnerDashboardActionTypes.GetPartnerDashboardFault;
  constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = PartnerDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = PartnerDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = PartnerDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = PartnerDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = PartnerDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = PartnerDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type PartnerDashBoardActions =
  GetPartnerDashboard
  | GetPartnerDashboardSuccess
  | GetPartnerDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
