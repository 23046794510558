import {  FaqModel } from "../../domain";
import { IPitchDeck } from "../../domain/pitch-deck-recorder.model";
import {
    PitchActions,
    PitchDeckActionTypes
} from "./pitch-deck.action";

export interface PitchState {
    pitch: IPitchDeck,
    error: string | null
 }

export const initialState: PitchState = {
    pitch: null,
    error:""

};

function pitchSuccess(state: PitchState = initialState, data: any): PitchState {
    return {
        ...state,
        pitch:data,
        error:""

    };
}

function pitchFault(state: PitchState = initialState, data: any): PitchState {
    return {
        ...state,
        pitch:null,
        error:data

    };
}

export function pitchReducer(state: PitchState = initialState, action: PitchActions): PitchState {
    switch (action.type) {
            case PitchDeckActionTypes.PitchDeckSuccess:  
                 return pitchSuccess(state,action.payload)
            case PitchDeckActionTypes.UploadPitchDeckSuccess:  
                 return pitchSuccess(state,action.payload)
            case PitchDeckActionTypes.UpdateScriptSuccess:  
                 return pitchSuccess(state,action.payload)
            case PitchDeckActionTypes.DeletePitchDeckSucess:  
                 return pitchSuccess(state,action.payload)
            case PitchDeckActionTypes.PitchDeckFault:
                return pitchFault(state, action.payload);
        default:
            return state;
    }
}


