import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CorporateInfoState } from "./corporate-info.reducer";

export const selectCorporateState = createFeatureSelector<CorporateInfoState>("corporate");

export const getCorporateInfo = createSelector(
  selectCorporateState,
  (state: CorporateInfoState) => state.corporate
);

