import { Action } from "@ngrx/store";
import { IGlobalSettings } from "../../domain/settings.model";
import { IBrandDetails } from "../../domain/brand.model";
import { IFilter } from "../../domain/filters.model";

export enum FiltersActionTypes {
  GetFilters = "[Filters] GetFilters",
  GetFiltersSuccess = "[Filters] GetFiltersSuccess",
  GetFiltersFault = "[Filters] GetFiltersFault",

  SetStartupFilterOptions = "[Filters] SetStartupFilterOptions",
  SetInvestorFilterOptions = "[Filters] SetInvestorFilterOptions",
  SetMentorFilterOptions = "[Filters] SetMentorFilterOptions",
  SetCorporateFilterOptions = "[Filters] SetCorporateFilterOptions",
  SetServiceProviderFilterOptions = "[Filters] SetServiceProviderFilterOptions",

  SetAppliedSavedFilter = "[Filters] SetAppliedSavedFilter",
  SetAppliedSavedFilterSuccess = "[Filters] SetAppliedSavedFilterSuccess",
}

export class GetFilters implements Action {
  readonly type = FiltersActionTypes.GetFilters;
}

export class GetFiltersSuccess implements Action {
  readonly type = FiltersActionTypes.GetFiltersSuccess;

  constructor(public payload: IFilter[]) { }
}

export class GetFiltersFault implements Action {
  readonly type = FiltersActionTypes.GetFiltersFault;
  constructor(public payload: string) { }
}

export class SetStartupFilterOptions implements Action {
  readonly type = FiltersActionTypes.SetStartupFilterOptions;

  constructor(public payload: any) { }
}

export class SetInvestorFilterOptions implements Action {
  readonly type = FiltersActionTypes.SetInvestorFilterOptions;

  constructor(public payload: any) { }
}

export class SetMentorFilterOptions implements Action {
  readonly type = FiltersActionTypes.SetMentorFilterOptions;

  constructor(public payload: any) { }
}
export class SetCorporateFilterOptions implements Action {
  readonly type = FiltersActionTypes.SetCorporateFilterOptions;

  constructor(public payload: any) { }
}
export class SetServiceProviderFilterOptions implements Action {
  readonly type = FiltersActionTypes.SetServiceProviderFilterOptions;

  constructor(public payload: any) { }
}

export class SetAppliedSavedFilter implements Action {
  readonly type = FiltersActionTypes.SetAppliedSavedFilter;

  constructor(public payload: any) { }
}

export class SetAppliedSavedFilterSuccess implements Action {
  readonly type = FiltersActionTypes.SetAppliedSavedFilterSuccess;
}


export type FiltersActions =
  | GetFilters
  | GetFiltersSuccess
  | GetFiltersFault
  | SetStartupFilterOptions
  | SetInvestorFilterOptions
  | SetMentorFilterOptions
  | SetCorporateFilterOptions
  | SetServiceProviderFilterOptions
  | SetAppliedSavedFilter
  | SetAppliedSavedFilterSuccess
  ;
