import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  InvididualProfileResponseModel,
  MentorInformationResponseModel
} from "../../domain";
import { MentorService } from "../../service/mentors.service";
import * as IndividualProfileInfoAction from "./individual-profile-info.action";
import { IndividualProfileInfoActionTypes } from "./individual-profile-info.action";
import { IndividualProfileService } from "../../service/individual-profile.service";

@Injectable()
export class IndividualProfileInfoEffect {

  getIndividualProfileInfo$ = createEffect(() => this.actions$.pipe(
    ofType<IndividualProfileInfoAction.GetIndividualProfileInfo>(IndividualProfileInfoActionTypes.GetIndividualProfileInfo),
    exhaustMap(() =>
      this.mentorService.getIndividualInfo().pipe(
        map((data: InvididualProfileResponseModel) => new IndividualProfileInfoAction.GetIndividualProfileInfoSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new IndividualProfileInfoAction.IndividualProfileInfoFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private mentorService: IndividualProfileService) {
  }
}
