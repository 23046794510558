import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiEndpointService } from './api-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class InvestorCompareService {

  constructor(private http: HttpClient) { }

  public getInvestorsCompare(ids: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_COMPARE);
    let queryParams = new HttpParams();
    queryParams = queryParams.append('ids', ids);
    return this.http.get(url, { params: queryParams }).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`investorCompare( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }
}
