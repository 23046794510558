<div class="modal-header " *ngIf="globalSettings">
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center justify-content-between">
      <h2 class="text-black fw-bolder fs-2 text-center">
        <!-- Rate your experience on {{globalSettings.branding.brandName}}! -->
        {{title}}
      </h2>
      <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
    </div>

    <div class="mt-5 d-flex justify-content-between align-items-center w-100 position-relative" style="height: 100px;">
      <div class="text-white flex-column d-flex justify-content-center align-items-center wrapper-logo" [class.wrapper-bg]="selectedReaction === PlatformFeedbackEnum.BELOW_AVERAGE">
        <div class="wrapper-img shadow-lg" >
          <!-- <button class="reaction-like" (click)="selectReaction(PlatformFeedbackEnum.BELOW_AVERAGE)"></button> -->
          <img src="assets/images/below-average.png" class="img-emoji"
            (click)="selectReaction(PlatformFeedbackEnum.BELOW_AVERAGE)" />
        </div>
        <label>Below Average</label>
      </div>

      <div class="text-white flex-column d-flex justify-content-center align-items-center wrapper-logo" [class.wrapper-bg]="selectedReaction === PlatformFeedbackEnum.AVERAGE">
        <div class="wrapper-img shadow-lg">
          <!-- <button class="reaction-love " (click)="selectReaction(PlatformFeedbackEnum.AVERAGE)"></button> -->
          <img src="assets/images/average.png" class="img-emoji"
            (click)="selectReaction(PlatformFeedbackEnum.AVERAGE)" />

        </div>
        <label>Average</label>
      </div>

      <div class="text-white flex-column d-flex justify-content-center align-items-center wrapper-logo"  [class.wrapper-bg]="selectedReaction === PlatformFeedbackEnum.GOOD">
        <div class="wrapper-img shadow-lg">

          <!-- <button class="reaction-haha " (click)="selectReaction(PlatformFeedbackEnum.GOOD)"></button> -->
          <img src="assets/images/good.png" class="img-emoji" (click)="selectReaction(PlatformFeedbackEnum.GOOD)" />
        </div>
        <label>Good</label>
      </div>

      <div class="text-white flex-column d-flex justify-content-center align-items-center wrapper-logo" [class.wrapper-bg]="selectedReaction === PlatformFeedbackEnum.EXCELLENT">
        <div class="wrapper-img shadow-lg" >
          <!-- <button class="reaction-wow " (click)="selectReaction(PlatformFeedbackEnum.EXCELLENT)"></button> -->
          <img src="assets/images/excellent.png" class="img-emoji"
            (click)="selectReaction(PlatformFeedbackEnum.EXCELLENT)" />
        </div>
        <label>Excellent</label>
      </div>

      <div class="text-white flex-column d-flex justify-content-center align-items-center wrapper-logo"  [class.wrapper-bg]="selectedReaction === PlatformFeedbackEnum.LOVING_IT">
        <div class="wrapper-img shadow-lg">
          <!-- <button class="reaction-sad " (click)="selectReaction(PlatformFeedbackEnum.LOVING_IT)"></button> -->
          <img src="assets/images/loving-it.png" class="img-emoji"
            (click)="selectReaction(PlatformFeedbackEnum.LOVING_IT)" />
        </div>
        <label>Loving it!</label>
      </div>

    </div>
  </div>

</div>

<ng-container *ngIf="PlatformFeedbackEnum.BELOW_AVERAGE === selectedReaction
  || PlatformFeedbackEnum.AVERAGE === selectedReaction
  || PlatformFeedbackEnum.GOOD === selectedReaction
">
  <div class="modal-body px-10 py-8">
    <label class="text-gray-800 fs-7 mb-2">
      Kindly provide feedback for our continual improvement efforts.</label>
    <textarea placeholder="Enter your feedback/comments." class="form-control" [(ngModel)]="feedback" rows="4"></textarea>
  </div>

  <div class="modal-footer">
    <button class="btn btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary me-2" (click)="closeModal()">
      Cancel
    </button>
    <button class="btn btn-theme text-white ls-2 text-uppercase fw-boldest"
      [disabled]="!selectedReaction || loading" (click)="handleSubmit()">
      Submit

      <span class="indicator-progress" [class.d-inline-block]="loading">
        <span class="spinner-border spinner-border-sm align-middle ms-2"> </span>
      </span>
    </button>
  </div>
</ng-container>
