import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { CorporateProfileCompleteness } from "../../domain";
import { CorporateService } from "../../service/corporate.service";
import * as CorporateDashboardActions from "./corporate.dashboard.action";
import { CorporateDashboardActionTypes } from './corporate.dashboard.action';

@Injectable()
export class CorporateDashboardEffect {

  getCorporateDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<CorporateDashboardActions.GetCorporateDashboard>(CorporateDashboardActionTypes.GetCorporateDashboard),
    exhaustMap(() => this.corporateService.getCorporateDashboard().pipe(
      map((data: any) => new CorporateDashboardActions.GetCorporateDashboardSuccess(data)),
      catchError((err: HttpErrorResponse) => of(new CorporateDashboardActions.GetCorporateDashboardFault(err.error.message)))
    ))
  ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<CorporateDashboardActions.GetProfileCompleteness>(CorporateDashboardActionTypes.GetProfileCompleteness),
    map((action: CorporateDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.corporateService.getCorporateProfileCompleteness().pipe(
      mergeMap((data: CorporateProfileCompleteness) => [
        new CorporateDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new CorporateDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<CorporateDashboardActions.SendRequestApproval>(CorporateDashboardActionTypes.SendRequestApproval),
    map((action: CorporateDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.corporateService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new CorporateDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new CorporateDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  // providingFunds$ = createEffect(() => this.actions$.pipe(
  //     ofType<InvestorDashBoardActions.ProvidingFundRequest>(InvestorDashboardActionTypes.ProvidingFundRequest),
  //     map((action: InvestorDashBoardActions.ProvidingFundRequest) => action),
  //     exhaustMap(() => this.investorDashboardService.providingFunds().pipe(
  //         mergeMap((data: string) => [
  //             new InvestorDashBoardActions.ProvidingFundRequestSuccess(data),
  //         ]),
  //         catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.ProvidingFundRequestFault(err.error.message)))
  //     )),
  // ));

  constructor(
    private actions$: Actions,
    private corporateService: CorporateService
  ) { }
}
