import { CorporateInformationResponseModel } from "../../domain";
import {
  CorporateInfoAction,
  CorporateInfoActionTypes
} from "./corporate-info.action";

export interface CorporateInfoState {
  corporate: CorporateInformationResponseModel,
  error: string | null
}

export const initialState: CorporateInfoState = {
  corporate: null,
  error: ""

};

function corporateInfoSuccess(state: CorporateInfoState = initialState, data: CorporateInformationResponseModel): CorporateInfoState {
  return {
    ...state,
    corporate: data,
    error: ""
  };
}

function corporateInfoFault(state: CorporateInfoState = initialState, data: string): CorporateInfoState {
  return {
    ...state,
    corporate: null,
    error: data
  };
}

export function corporateReducer(state: CorporateInfoState = initialState, action: CorporateInfoAction): CorporateInfoState {
  switch (action.type) {
    case CorporateInfoActionTypes.GetCorporateInfoSuccess:
      return corporateInfoSuccess(state, action.payload);
    case CorporateInfoActionTypes.CorporateInfoFault:
      return corporateInfoFault(state, action.payload);
    default:
      return state;
  }
}


