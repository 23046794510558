import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ToastAlertService } from 'src/app/shared/utils/toast.service';
import { RegisterModel, SignupCredentials, SingUpSendOtpResponse, SingUpVerifyOtpResponse } from '../domain';
import { ApiEndpointService } from './api-endpoint.service';
import md5 from 'md5';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {

  constructor(private http: HttpClient, private toastService: ToastAlertService) { }

  public sendOTP(signupCredentials: SignupCredentials): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SIGN_UP_SEND_OTP);
    const params = {
      type: signupCredentials.type,
      countryCode: signupCredentials.countryCode,
      mobileNumber: signupCredentials.mobileNumber,
      emailAddress: signupCredentials.emailAddress
    };
    return this.http.post(url, params).pipe(
      map((response: SingUpSendOtpResponse) => {

        console.trace('============== sendOTP() response =============');

        return response.data
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`sendOTPFault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public verifyOTP(signupCredentials: SignupCredentials): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.SIGN_UP_VERIFY_OTP);
    const params = {
      type: signupCredentials.type,
      countryCode: signupCredentials.countryCode,
      mobileNumber: signupCredentials.mobileNumber,
      emailAddress: signupCredentials.emailAddress,
      code: md5(signupCredentials.code.toString())
    };
    return this.http.post(url, params).pipe(
      map((response: SingUpVerifyOtpResponse) => {
        return response.data
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`verifyOTPFault( ${fault.error.message} )`);
        this.toastService.showToast(fault?.error?.message || 'Error while verifying OTP', 'error');
        return throwError(() => fault);
      })
    );
  }

  public registerUser(userData: RegisterModel): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.REGISTER);
    const params = userData;
    return this.http.post(url, params).pipe(
      map((response: any) => {
        return response.data
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`register( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public registerOtherUser(userData: RegisterModel,userType = 'other'): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.REGISTER) + userType;
    const params = userData;
    return this.http.post(url, params).pipe(
      map((response: any) => {
        return response.data
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`register( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

}
