import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { distinctUntilChanged, finalize, first, Observable, Subject, take, takeUntil, tap } from 'rxjs';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { getGlobalSettings } from 'src/app/core/state/global';

@Component({
  selector: 'app-logo-renderer',
  templateUrl: './logo-renderer.component.html',
  styleUrls: ['./logo-renderer.component.scss']
})
export class LogoRendererComponent implements OnInit, OnDestroy {
  globalSettings: IGlobalSettings;
  public globalSettings$: Observable<IGlobalSettings>;
  private destroyed$: Subject<void> = new Subject();
  logoUrl= 'assets/images/logos/logo-2.svg'
  headerLogoUrl= 'assets/images/logos/logo-2.svg'
  @Input() class = '';
  @Input() headerLogo = false;
  constructor(
    private readonly store: Store,
  ) { }

  ngOnInit(): void {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
        this.logoUrl = res?.branding?.logo || 'assets/images/logos/logo-2.svg'
        this.headerLogoUrl = res?.branding?.headerLogo || 'assets/images/logos/logo-2.svg'
        console.log("globalSettings?.branding?.logo", res?.branding?.logo)
      }
    })
  }


  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
