import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvestorsSearchCardComponent } from './investors-search-card.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [
    InvestorsSearchCardComponent
  ],
  imports: [
    CommonModule,
    NgbTooltipModule,
    AvatarModule,
    PipesModule,
    NgbModule
  ],
  exports: [
    InvestorsSearchCardComponent
  ]
})
export class InvestorsSearchCardModule { }
