import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IAds } from "../domain/ads.model";
import { ApiEndpointService } from "./api-endpoint.service";

@Injectable({
    providedIn: "root"
})
export class AdsService {

    constructor(private http: HttpClient, private toastService: ToastAlertService,) { }

    public getAllAds(): Observable<IAds> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ADS);
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                // this.toastService.showToast(fault?.error?.message || 'Error while fetching partner information list', 'error');
                return throwError(() => fault);
            })
        );
    }

}
