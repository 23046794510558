import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IndividualProfileInfoState } from "./individual-profile-info.reducer";

export const selectIndividualProfileState = createFeatureSelector<IndividualProfileInfoState>("indidualProfile");

export const getIndividualProfileInfo = createSelector(
  selectIndividualProfileState,
  (state: IndividualProfileInfoState) => state.invididualProfile
);

