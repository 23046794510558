import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ɵDomSharedStylesHost } from '@angular/platform-browser';
import { CustomDomSharedStylesHost } from './shared_styles_host';



@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  providers: [
    { provide: ɵDomSharedStylesHost, useClass: CustomDomSharedStylesHost },
  ],
})
export class InlineStylesCspModule { }
