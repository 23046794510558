import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  MentorInformationResponseModel
} from "../../domain";
import { MentorService } from "../../service/mentors.service";
import * as MentorInfoAction from "./mentor-info.action";
import { MentorInfoActionTypes } from "./mentor-info.action";

@Injectable()
export class MentorInfoEffect {

  getMentorInfo$ = createEffect(() => this.actions$.pipe(
    ofType<MentorInfoAction.GetMentorInfo>(MentorInfoActionTypes.GetMentorInfo),
    exhaustMap(() =>
      this.mentorService.getMentorInfo().pipe(
        map((data: MentorInformationResponseModel) => new MentorInfoAction.MentorInfoSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new MentorInfoAction.MentorInfoFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private mentorService: MentorService) {
  }
}
