import { PartnerInformationResponseModel } from "../../domain";
import {
  PartnerInfoAction,
  PartnerInfoActionTypes
} from "./partner-info.action";

export interface PartnerInfoState {
  partner: PartnerInformationResponseModel,
  error: string | null
}

export const initialState: PartnerInfoState = {
  partner: null,
  error: ""
};

function partnerInfoSuccess(state: PartnerInfoState = initialState, data: PartnerInformationResponseModel): PartnerInfoState {
  return {
    ...state,
    partner: data,
    error: ""
  };
}

function partnerInfoFault(state: PartnerInfoState = initialState, data: string): PartnerInfoState {
  return {
    ...state,
    partner: null,
    error: data
  };
}

export function partnerReducer(state: PartnerInfoState = initialState, action: PartnerInfoAction): PartnerInfoState {
  switch (action.type) {
    case PartnerInfoActionTypes.GetPartnerInfoSuccess:
      return partnerInfoSuccess(state, action.payload);
    case PartnerInfoActionTypes.PartnerInfoFault:
      return partnerInfoFault(state, action.payload);
    default:
      return state;
  }
}


