import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { catchError, map, Observable, throwError } from "rxjs";
import { SendNotificationPayload } from "src/app/modules/notifications/notifications.model";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";


@Injectable({
  providedIn: "root"
})
export class NotificationsService {

  constructor(private http: HttpClient, private toastService: ToastAlertService) { }

  public fetchNotifications(payload: { pageNumber: number, limit: number }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.NOTIFICATIONS);
    const urlWithQueryParams = url + `?pageNumber=${payload.pageNumber}&limit=${payload.limit}`
    return this.http.get(urlWithQueryParams).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while fetching notifications data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public sendPlatformNotification(payload: SendNotificationPayload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.NOTIFICATIONS + "/platform");
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while sending the platform notification', 'error');
        return throwError(() => fault);
      })
    );
  }

  public fetchNotificationCount(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.NOTIFICATIONS + "/count");
    return this.http.get(url).pipe(
      map((response: any): any => {
        if (response?.data) {
          return response.data;
        }
      }),
      catchError((fault: HttpErrorResponse) => {
        // this.toastService.showToast(fault?.error?.message || 'Error while fetching notifications count', 'error');
        return throwError(() => fault);
      })
    );
  }

  public markAllReadNotifications(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.NOTIFICATIONS + "/mark-all-read");
    return this.http.patch(url, null).pipe(
      map((response: any): any => {
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        // this.toastService.showToast(fault?.error?.message || 'Error while mark all read notifications', 'error');
        return throwError(() => fault);
      })
    );
  }
}
