import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProfileState } from "./profile.reducer";

export const selectProfileState = createFeatureSelector<ProfileState>("profile");

export const getProfileData = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profileData
);


export const getProfileTypes = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profileTypes
);

