import { createFeatureSelector, createSelector } from "@ngrx/store";
import { StartUpState } from "./startup.reducer";

export const selectStartUpState = createFeatureSelector<StartUpState>("startup");

export const getStartUpInfoLoader = createSelector(
  selectStartUpState,
  (state: StartUpState) => state.pending
);

export const getStartUpInfo = createSelector(
  selectStartUpState,
  (state: StartUpState) => state.startupInfo
);

export const getFoundersList = createSelector(
  selectStartUpState,
  (state: StartUpState) => state.founders
);

export const getAdvisoryBoardList = createSelector(
  selectStartUpState,
  (state: StartUpState) => state.advisoryBoard
);

export const getCommitmentsList = createSelector(
  selectStartUpState,
  (state: StartUpState) => state.commitments
);

