import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError,
  distinctUntilChanged,
  map,
  take,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import {
  InvididualProfileModel,
  MentorInformationModel, ServiceProviderProfileCompleteness
} from "../domain";
import { ApiEndpointService } from "./api-endpoint.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import * as IndividualAction from 'src/app/core/state/individual-profile-dashboard/individual-profile.dashboard.action';
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import { GlobalService } from "./global.service";
import { AuthService } from "./auth.service";
import { getBrandDetails } from "../state/global";

@Injectable({
  providedIn: "root"
})
export class IndividualProfileService {
  profileData: IProfileDetails;
  /**
   * Constructor.
   */
  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store, private globalService: GlobalService, private authService: AuthService) {
    this.store.pipe(select(getProfileData))
      .pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          this.profileData = res;
        }
      })
  }

  getProfileCompleteness() {
    return this.store.dispatch(new IndividualAction.GetProfileCompleteness());
  }

  public patchInvididualProfileInfo(mentorInfo: InvididualProfileModel): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVUDIALS_INFORMATION);
    return this.http.patch(url, mentorInfo).pipe(
      tap(() => {
        this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`loginFault( ${fault?.error?.message} )`);
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving mentor info!'), 'error');
        return throwError(() => fault);
      })
    );
  }



  // public patchEngagementInfo(mentorInfo: MentorInformationModel): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ENGAGEMENT_INFO);
  //   return this.http.patch(url, mentorInfo).pipe(
  //     tap(() => {
  //       this.getProfileCompleteness()
  //     }),
  //     catchError((fault: HttpErrorResponse) => {
  //       console.warn(`loginFault( ${fault?.error?.message} )`);
  //       this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving engagement info!'), 'error');
  //       return throwError(() => fault);
  //     })
  //   );
  // }


  public getInvididualProfileCompleteness(): Observable<ServiceProviderProfileCompleteness> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_PROFILE_COMPLETENESS);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`getInvididualProfileCompleteness GET ( Received getInvididualProfileCompleteness `);
        return response.data;
      }),
      tap((res: any)=>{
        if(res?.isRejected){
          setTimeout(()=>{

            this.store.pipe(select(getBrandDetails)).pipe(take(1)).subscribe((res)=> {
              if(res) {
                if(res.features.logout_on_rejection)  {
                  this.toastService.showToast('Your profile is rejected', 'error');
                  this.authService.logout();
                }
              }
            })

          }, 1000)
        }
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`getIndividualInvestorCompleteness( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public getIndividualInfo(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVUDIALS_INFORMATION);
    return this.http.get(url).pipe(
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public uploadLogo(logo: File, refetchProfile = false): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_LOGO_UPLOAD);
    console.info(`coporate up logo`, payload);
    return this.http.post(url, payload).pipe(
      tap(() => {
        if (refetchProfile) {
          this.store.dispatch(new ProfileActions.GetProfile());
        }
        this.getProfileCompleteness()
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading logo!'), 'error');
        console.warn(`save coporate Uplogo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public sendApprovalRequest(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_DASHBOARD_APPROVAL);
    return this.http.patch(url, {}).pipe(
      tap(() => this.getProfileCompleteness()),
      map((response: any) => {
        return response.message;
      }),
      catchError((fault: HttpErrorResponse) => {
        return throwError(() => fault);
      })
    );
  }

  public getPublicProfileData(uuid: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_PUBLIC_PROFILE + `/${uuid}`);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while getting mentor public profile data', 'error');
        return throwError(() => fault);
      })
    );
  }

  public getIndividualDashboard(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDIVIDUALS_DASHBOARD);
    return this.http.get(url).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while fetching dashboard details!'), 'error');
        return throwError(() => fault);
      })
    );
  }

}
