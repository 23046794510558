<div class="modal-header">
  <h3 class="modal-title">Create Investor Profile</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div [formGroup]="accountInformationForm" *ngIf="accountInformationForm">
    <div class="w-100">


      <div class="chooseInvestorType">
        <div class="pb-5 pb-lg-5 ">
          <h2 class="fw-bolder d-flex align-items-center text-dark">
            investing as
          </h2>
        </div>

        <div class="fv-row fv-plugins-icon-container">

          <div class="row">

            <div class="col-lg-6 mb-5">

              <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5">
                <div class="d-flex align-items-center me-2">
                  <div class="form-check form-check-custom form-check-solid form-check-primar me-2">
                    <input class="form-check-input" type="radio" name="investorType" value="organization"
                      formControlName="investorType" />
                  </div>
                  <div class="flex-grow-1">
                    <h2 class="d-flex align-items-center fs-6 fw-bolder flex-wrap pt-1">
                      Organization
                    </h2>
                  </div>
                </div>
              </label>

            </div>



            <div class="col-lg-6 mb-5" *ngIf="brandDetails?.features?.individual_investor">
              <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5">
                <div class="d-flex align-items-center me-2">
                  <div class="form-check form-check-custom form-check-solid form-check-primar me-2">
                    <input class="form-check-input" type="radio" name="investorType" value="individual"
                      formControlName="investorType" />
                  </div>
                  <div class="flex-grow-1">
                    <h2 class="d-flex align-items-center fs-6 fw-bolder flex-wrap pt-1">
                      Individual Investor
                    </h2>
                  </div>
                </div>
              </label>
              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>





            <div class="col-lg-6 mb-5" *ngIf="brandDetails?.features?.syndicate">

              <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5">
                <div class="d-flex align-items-center me-2">
                  <div class="form-check form-check-custom form-check-solid form-check-primar me-2">
                    <input class="form-check-input" type="radio" name="investorType" value="syndicate"
                      formControlName="investorType" />
                  </div>
                  <div class="flex-grow-1">
                    <h2 class="d-flex align-items-center fs-6 fw-bolder flex-wrap pt-1">
                      Syndicate
                    </h2>
                  </div>
                </div>
              </label>

              <div class="fv-plugins-message-container invalid-feedback"></div>
            </div>

          </div>

        </div>
      </div>


    </div>


    <div class=" mb-7" *ngIf="
            investorType !== InvestorTypes.INDIVIDUAL
          ">
      <label for="organizationName" class="mb-2">Organization name</label>
      <input type="text" class="form-control" id="organizationName" placeholder="Company Name"
        formControlName="organizationName" [ngClass]="{
              'is-invalid':
                !accountInformationForm.get('organizationName').valid &&
                (accountInformationForm.get('organizationName')?.touched ||
                  accountInformationForm.get('organizationName')?.dirty),
              'is-valid':
                accountInformationForm.get('organizationName').value &&
                accountInformationForm.get('organizationName').valid &&
                (accountInformationForm.get('organizationName')?.touched ||
                  accountInformationForm.get('organizationName')?.dirty)
            }" />
      <span class="invalid-feedback d-block" *ngIf="
              accountInformationForm
                .get('organizationName')
                ?.hasError('required') &&
              (accountInformationForm.get('organizationName')?.touched ||
                accountInformationForm.get('organizationName')?.dirty)
            ">
        Organization name is required
      </span>
    </div>
  </div>


  <!-- <div class="col-12" *ngIf="investorType === InvestorTypes.SYNDICATE">
    <div class="form-floating mb-7">
      <input type="text" class="form-control" id="website" placeholder="Website" formControlName="website" [ngClass]="{
            'is-invalid':
              !accountInformationForm.get('website').valid &&
              (accountInformationForm.get('website')?.touched ||
                accountInformationForm.get('website')?.dirty),
            'is-valid':
              accountInformationForm.get('website').value &&
              accountInformationForm.get('website').valid &&
              (accountInformationForm.get('website')?.touched ||
                accountInformationForm.get('website')?.dirty)
          }" />
      <label for="website">Website</label>
      <span class="invalid-feedback d-block ms-1" *ngIf="
          accountInformationForm.get('website')?.hasError('required') &&
            (accountInformationForm.get('website')?.touched ||
            accountInformationForm.get('website')?.dirty)
          ">
        Website is required
      </span>
      <span class="invalid-feedback d-block ms-1" *ngIf="
          accountInformationForm.get('website')?.hasError('pattern') &&
            (accountInformationForm.get('website')?.touched ||
            accountInformationForm.get('website')?.dirty)
          ">
        Invalid Website Url
      </span>
    </div>
  </div> -->

</div>
<div class="modal-footer ">
  <button class="btn btn-sm btn-theme text-white ls-2 text-uppercase fw-boldest" [disabled]="isDisabledApply"
    (click)="onApplyBtnClick()">
    Submit
  </button>
  <button class="btn btn-sm btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary" (click)="handleCancle()">
    Cancel
  </button>
</div>
