import { Action } from "@ngrx/store";
import {
  InvididualProfileResponseModel,
} from "../../domain";

export enum IndividualProfileInfoActionTypes {
  GetIndividualProfileInfo = "[Individual] IndividualProfile",
  GetIndividualProfileInfoSuccess = "[Individual] GetIndividualProfileInfoSuccess",
  IndividualProfileInfoFault = "[Individual] IndividualProfileInfoFault",
}

export class GetIndividualProfileInfo implements Action {
  readonly type = IndividualProfileInfoActionTypes.GetIndividualProfileInfo;
}

export class GetIndividualProfileInfoSuccess implements Action {
  readonly type = IndividualProfileInfoActionTypes.GetIndividualProfileInfoSuccess;

  constructor(public payload: InvididualProfileResponseModel) {
  }
}

export class IndividualProfileInfoFault implements Action {
  readonly type = IndividualProfileInfoActionTypes.IndividualProfileInfoFault;

  constructor(public payload: string) {
  }
}



export type IndividualProfileAction =
  | GetIndividualProfileInfo
  | GetIndividualProfileInfoSuccess
  | IndividualProfileInfoFault
  ;
