import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { PartnersInformation } from "../../domain/partners.model";
import { ChallengeService } from "../../service/challenge.service";
import * as ChallengesAction from "./challenges.action";
import { ChallengesActionTypes } from "./challenges.action";

@Injectable()
export class ChallengesEffect {
    constructor(private actions$: Actions, private challengeService: ChallengeService) { }

    getJobsList$ = createEffect(() => this.actions$.pipe(
        ofType<ChallengesAction.GetChallengesList>(ChallengesActionTypes.GetChallengesList),
        map((action: ChallengesAction.GetChallengesList) => action),
        exhaustMap((action) => this.challengeService.getChallengesList(action).pipe(
            mergeMap((data: PartnersInformation) => [
                new ChallengesAction.GetChallengesListSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new ChallengesAction.GetChallengesListFault(err.error.message)))
        ))
    ))

}
