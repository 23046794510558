import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  Auth,
  FaqModel,
  VerifyOtpCredentials
} from "../../domain";
import { IPitchDeck } from "../../domain/pitch-deck-recorder.model";
import { PitchDeckService } from "../../service/pitch-deck-record.service";
import * as PitchActions from "./pitch-deck.action";
import { PitchDeckActionTypes } from "./pitch-deck.action";

@Injectable()
export class PitchEffect {

  getPitch$ = createEffect(() => this.actions$.pipe(
    ofType<PitchActions.GetPitchDeck>(PitchDeckActionTypes.GetPitchDeck),
    exhaustMap(() =>
      this.pitchDeckService.getPitchInfo().pipe(
        map((data: IPitchDeck) => new PitchActions.PitchDeckSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new PitchActions.PitchDeckFault(err.message)))
      )
    )
  )
  );

  deletePitch$ = createEffect(() => this.actions$.pipe(
    ofType<PitchActions.DeletePitchDeck>(PitchDeckActionTypes.DeletePitchDeck),
    mergeMap((action) =>
      this.pitchDeckService.delete(action.categoryId,action.topicId).pipe(
        map((data: IPitchDeck) => new PitchActions.DeletePitchDeckSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new PitchActions.PitchDeckFault(err.message)))
      )
    )
  )
  
  );

  uploadPitch$ = createEffect(() => this.actions$.pipe(
    ofType<PitchActions.UploadPitchDeck>(PitchDeckActionTypes.UploadPitchDeck),
    mergeMap((action) =>
      this.pitchDeckService.uploadPitchFile(action.file,action.categoryId,action.topicId,action.videoTime).pipe(
        map((data: IPitchDeck) => new PitchActions.UploadPitchDeckSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new PitchActions.PitchDeckFault(err.message)))
      )
    )
  )
  
  );

  updateScript$ = createEffect(() => this.actions$.pipe(
    ofType<PitchActions.UpdateScript>(PitchDeckActionTypes.UpdateScript),
    mergeMap((action) =>
      this.pitchDeckService.updatePitchScript(action.categoryId,action.topicId,action.script).pipe(
        map((data: IPitchDeck) => new PitchActions.UpdateScriptSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new PitchActions.PitchDeckFault(err.message)))
      )
    )
  )
  
  );

  constructor(private actions$: Actions, private store$: Store<any>, private pitchDeckService: PitchDeckService) {
  }
}
