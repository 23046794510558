import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MultiSelectDropdownComponent } from './multi-select-dropdown.component';

@NgModule({
  declarations: [
    MultiSelectDropdownComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDropdownModule,
    NgSelectModule
  ],
  exports: [
    MultiSelectDropdownComponent
  ],
})
export class MultiSelectDropdownModule { }
