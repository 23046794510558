<div class="modal-header">
  <h3 class="modal-title">Create Corporate Profile</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="w-100">


      <div class="row mt-4" [formGroup]="accountInformationForm" *ngIf="accountInformationForm">
        <div class="col-12">
          <div class=" mb-7">
            <label for="companyName ms-3" class="mb-2">Company name</label>
            <input type="text" class="form-control" id="companyName" placeholder="Company Name"
              formControlName="companyName" [ngClass]="{
              'is-invalid':
                !accountInformationForm.get('companyName').valid &&
                (accountInformationForm.get('companyName')?.touched ||
                  accountInformationForm.get('companyName')?.dirty),
              'is-valid':
                accountInformationForm.get('companyName').value &&
                accountInformationForm.get('companyName').valid &&
                (accountInformationForm.get('companyName')?.touched ||
                  accountInformationForm.get('companyName')?.dirty)
            }" />
            <span class="invalid-feedback d-block" *ngIf="
              accountInformationForm.get('companyName')?.hasError('required') &&
              (accountInformationForm.get('companyName')?.touched ||
                accountInformationForm.get('companyName')?.dirty)
            ">
              Company name required
            </span>
          </div>
        </div>

        <div class="col-12">
          <div class=" mb-7">
            <label for="designation ms-3" class="mb-2">Your Designation</label>
            <input type="text" class="form-control" id="designation" placeholder="Your Designation"
              formControlName="designation" [ngClass]="{
              'is-invalid':
                !accountInformationForm.get('designation').valid &&
                (accountInformationForm.get('designation')?.touched ||
                  accountInformationForm.get('designation')?.dirty),
              'is-valid':
                accountInformationForm.get('designation').value &&
                accountInformationForm.get('designation').valid &&
                (accountInformationForm.get('designation')?.touched ||
                  accountInformationForm.get('designation')?.dirty)
            }" />
            <span class="invalid-feedback d-block" *ngIf="
              accountInformationForm.get('designation')?.hasError('required') &&
              (accountInformationForm.get('designation')?.touched ||
                accountInformationForm.get('designation')?.dirty)
            ">
              Designation is required
            </span>
          </div>
        </div>

      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-sm btn-theme text-white ls-2 text-uppercase fw-boldest" [disabled]="isDisabledApply"
        (click)="onApplyBtnClick()">
        Submit
      </button>
      <button class="btn btn-sm btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary"
        (click)="handleCancle()">
        Cancel
      </button>
    </div>
