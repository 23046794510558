<ngx-ui-loader
  textPosition="center-center"
  [text]="textLoading"
></ngx-ui-loader>
<ng-container *ngIf="view === 'default'">
  <div class="modal-header">
    <h3 class="modal-title">Switch Profile</h3>
    <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
  </div>
  <div class="modal-body">
    <div *ngIf="brandDetails">
      <div class="w-100">
        <h4 class="text-primary mb-3">
          Active Profiles
        </h4>

        <ng-container *ngFor="let item of profileTypes">
          <div>
            <div class="alert alert-primary alert-width-100 d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <div class="symbol symbol-45px me-5 p-1 border border-gray-300 rounded bg-white">
                  <img *ngIf="item.organizationLogo"
                    [src]="(item.organizationLogo | imagekitUrlAppender) + '?tr=w-150,h-150,cm-pad_resize'" alt="" />
                  <ngx-avatars bgColor="#eaeaea" *ngIf="!item.organizationLogo" [name]="item.organizationName"
                    cornerRadius="4" size="45" [round]="false"></ngx-avatars>
                </div>
                <div class="d-flex flex-column">
                  <span class="text-dark fs-4">
                    {{item.organizationName}}
                  </span>
                  <span class="badge badge-dark fs-8 mt-1" style="width: fit-content;">
                    {{(labelMap[item.profileType] || item.profileType) | titlecase}}
                  </span>
                </div>
              </div>

              <div>
                <button class="btn btn-primary text-white ls-2 text-uppercase fw-boldest btn-sm"
                  (click)="switchType(item)" *ngIf="profileData.accountType!== item.profileType">
                  Switch
                </button>
                <button class="btn ls-2 text-uppercase fw-boldest btn-sm"
                disabled
                style="background-color: #bebebe;color: #1f1f1f;"
                  *ngIf="profileData.accountType=== item.profileType">
                  Current
                </button>
              </div>

            </div>
          </div>
        </ng-container>

        <h4 class="text-dark my-5" *ngIf="pendingTypes.length">
          Create New Profile
        </h4>


        <div class="row">

          <div *ngFor="let item of pendingTypes" class=" col-6 gap-2" (click)="handleCreateProfile(item)">
            <div
              class="alert alert-primary alert-width-100 d-flex justify-content-between align-items-center cursor-pointer">
              <div class="d-flex align-items-center justify-content-center w-100">
                + As {{(labelMap[item] || item) | titlecase}}
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="view === ACCOUNT_TYPE.STARTUP">
  <app-create-startup-profile-type (onApply)="createProfile($event)" (onClose)="handleCancleCreateType()"></app-create-startup-profile-type>
</ng-container>

<!--
<ng-container *ngIf="view === ACCOUNT_TYPE.MENTOR">
  <app-create-mentor-profile-type (onApply)="createProfile($event)"></app-create-mentor-profile-type>
</ng-container> -->


<ng-container *ngIf="view === ACCOUNT_TYPE.CORPORATE">
  <app-create-corporate-profile-type (onApply)="createProfile($event)" (onClose)="handleCancleCreateType()"></app-create-corporate-profile-type>
</ng-container>


<ng-container *ngIf="view === ACCOUNT_TYPE.INVESTOR">
  <app-create-investor-profile-type (onApply)="createProfile($event)" (onClose)="handleCancleCreateType()"></app-create-investor-profile-type>
</ng-container>
