import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProgramOfficeDashboardState } from "./program-office.dashboard.reducer";

export const selectProgramOfficeDashboardState = createFeatureSelector<ProgramOfficeDashboardState>("programOfficeDashboard");

export const getProgramOfficeProfileCompleteness = createSelector(
  selectProgramOfficeDashboardState,
  (state: ProgramOfficeDashboardState) => state.programOfficeProfileCompleteness
)

export const getProgramOfficeDashboard = createSelector(
  selectProgramOfficeDashboardState,
  (state: ProgramOfficeDashboardState) => state.programOfficeDashboard
)
