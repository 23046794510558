import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
    OrganizationInformationResponseModel
} from "../../domain";
import { InvestorService } from "../../service/investors.service";
import * as InvestorsOrgInfoActionFaqActions from "./investors-organization-info.action";
import { InvestorsOrgInfoActionTypes } from "./investors-organization-info.action";

@Injectable()
export class InvestorsOrgInfoEffect {

    getInvestorsOrgInfo$ = createEffect(() => this.actions$.pipe(
    ofType<InvestorsOrgInfoActionFaqActions.GetInvestorsOrgInfo>(InvestorsOrgInfoActionTypes.GetInvestorsOrgInfo),
    exhaustMap(() =>
      this.investorService.getInvestorOrganizationInfo().pipe(
        map((data: OrganizationInformationResponseModel) => new InvestorsOrgInfoActionFaqActions.InvestorsOrgInfoSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new InvestorsOrgInfoActionFaqActions.InvestorsOrgInfoFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private investorService: InvestorService) {
  }
}
