import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, mergeMap, of } from "rxjs";
import { IInvestorsCompareResponse } from "../../domain/startup-investor-compare.model";
import { InvestorCompareService } from "../../service";
import * as InvestorCompareActions from "./investor.compare.action";
import { InvestorCompareActionTypes } from "./investor.compare.action";

@Injectable()
export class InvestorCompareEffect {

    getCompareDashboard$ = createEffect(() => this.actions$.pipe(
        ofType<InvestorCompareActions.GetInvestorCompare>(InvestorCompareActionTypes.GetInvestorCompare),
        map((action: InvestorCompareActions.GetInvestorCompare) => action.payload),
        exhaustMap((ids: string) => this.investorCompareService.getInvestorsCompare(ids).pipe(
            mergeMap((data: IInvestorsCompareResponse) => [
                new InvestorCompareActions.GetInvestorCompareSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new InvestorCompareActions.GetInvestorCompareFault(err.error.message)))
        ))
    ));

    constructor(private actions$: Actions, private investorCompareService: InvestorCompareService) { }
}
