import { Auth } from "../../domain";
import {
    AuthActions,
    AuthActionTypes
} from "./auth.action";

export interface AuthState {
    user: Auth | null;
    pending: boolean;
    error: string | null;
}

export const initialState: AuthState = {
    user: JSON.parse(localStorage.getItem('user')),
    pending: false,
    error: ""
};

function initAuth(state: AuthState = initialState): AuthState {
    return {
        ...state,
        pending: true,
        error: ""
    };
}

function authSuccess(state: AuthState = initialState, data: Auth): AuthState {
    return {
        ...state,
        user: data,
        pending: false,
        error: ""
    };
}

function authFailed(state: AuthState = initialState, data: string): AuthState {
    return {
        ...state,
        user: null,
        pending: false,
        error: data
    };
}

function resetAuthError(state: AuthState = initialState): AuthState {
    return {
        ...state,
        error: ""
    };
}
function resetAuthState() {
    return {
        ...initialState
    }
}

export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
    switch (action.type) {

        case AuthActionTypes.VerifyOtp:
            return initAuth(state);

        case AuthActionTypes.VerifyOtpSuccess:
            return authSuccess(state, action.payload);

        case AuthActionTypes.VerifyOtpFault:
            return authFailed(state, action.payload);

        case AuthActionTypes.ResetAuthError:
            return resetAuthError(state);

        case AuthActionTypes.LogOutSuccess:
            return resetAuthState();

        default:
            return state;
    }
}


