import { ProgramOfficeProfileCompleteness, ServiceProviderProfileCompleteness } from "../../domain";
import { ProgramOfficeDashBoardActions, ProgramOfficeDashboardActionTypes } from "./program-office.dashboard.action";

export interface ProgramOfficeDashboardState {
  programOfficeDashboard: any;
  programOfficeDashboardLoading: boolean;
  programOfficeDashboardError: string | null | string[];

  programOfficeProfileCompleteness: ProgramOfficeProfileCompleteness;
  programOfficeProfileCompletenessLoading: boolean;
  programOfficeProfileCompletenessError: string | null | string[];


  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;

}

export const initialState: ProgramOfficeDashboardState = {
  programOfficeDashboard: null,
  programOfficeDashboardLoading: false,
  programOfficeDashboardError: "",


  programOfficeProfileCompleteness: null,
  programOfficeProfileCompletenessLoading: false,
  programOfficeProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initInvestorDashboard(state: ProgramOfficeDashboardState = initialState): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeDashboardLoading: true,
    programOfficeDashboardError: ""
  };
}

function investorDashboardSuccess(state: ProgramOfficeDashboardState = initialState, data: any): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeDashboard: data,
    programOfficeDashboardLoading: false,
    programOfficeDashboardError: ""
  }
}

function investorDashboardFailed(state: ProgramOfficeDashboardState = initialState, data: string): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeDashboard: null,
    programOfficeDashboardLoading: false,
    programOfficeDashboardError: data
  }
}

function initProgramOfficeProfileCompleteness(state: ProgramOfficeDashboardState = initialState): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeProfileCompletenessLoading: true,
    programOfficeProfileCompletenessError: ""
  };
}

function programOfficeProfileCompletenessSuccess(state: ProgramOfficeDashboardState = initialState, data: ProgramOfficeProfileCompleteness): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeProfileCompleteness: data,
    programOfficeProfileCompletenessLoading: false,
    programOfficeProfileCompletenessError: ""
  }
}

function programOfficeProfileCompletenessFailed(state: ProgramOfficeDashboardState = initialState, data: string): ProgramOfficeDashboardState {
  return {
    ...state,
    programOfficeProfileCompleteness: null,
    programOfficeProfileCompletenessLoading: false,
    programOfficeProfileCompletenessError: data
  }
}


function initRequestApproval(state: ProgramOfficeDashboardState = initialState): ProgramOfficeDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: ProgramOfficeDashboardState = initialState, data: string): ProgramOfficeDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: ProgramOfficeDashboardState = initialState, data: string): ProgramOfficeDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function programOfficeDashboardReducer(state: ProgramOfficeDashboardState = initialState, action: ProgramOfficeDashBoardActions): ProgramOfficeDashboardState {
  switch (action.type) {

    case ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboard:
      return initInvestorDashboard(state);

    case ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboardSuccess:
      return investorDashboardSuccess(state, action.payload);

    case ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboardFault:
      return investorDashboardFailed(state, action.payload);


    case ProgramOfficeDashboardActionTypes.GetProfileCompleteness:
      return initProgramOfficeProfileCompleteness(state);

    case ProgramOfficeDashboardActionTypes.GetProfileCompletenessSuccess:
      return programOfficeProfileCompletenessSuccess(state, action.payload);

    case ProgramOfficeDashboardActionTypes.GetProfileCompletenessFault:
      return programOfficeProfileCompletenessFailed(state, action.payload);


    case ProgramOfficeDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case ProgramOfficeDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case ProgramOfficeDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
