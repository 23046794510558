import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import { IAds } from "../../domain/ads.model";
import { AdsService } from "../../service/ads.service";
import * as AdsActions from "./ads.action";
import { AdsActionTypes } from "./ads.action";

@Injectable()
export class AdsEffect {

  getAds$ = createEffect(() => this.actions$.pipe(
    ofType<AdsActions.GetAds>(AdsActionTypes.GetAds),
    exhaustMap(() =>
      this.adsService.getAllAds().pipe(
        map((data: IAds) => new AdsActions.GetAdsSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new AdsActions.GetAdsFault(err.message)))
      )
    )
  ));

  constructor(private actions$: Actions, private adsService: AdsService) {
  }
}
