import { Pipe, PipeTransform } from '@angular/core';
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import { getAccountTypeTitle, toSingular } from '../utils/common-methods';

@Pipe({
  name: 'accountTypeToLabel'
})
export class AccountTypeToLabelPipe implements PipeTransform {

  transform(value: ACCOUNT_TYPE, singular = true): string {
    return singular ? toSingular(getAccountTypeTitle(value)) : getAccountTypeTitle(value)
  }

}
