import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  static readonly storageKeys = {
    user: 'user',
    brandDetails: 'brandDetails',
    profile: 'profile',
    // to trick users so they don't guess form url
    autoApplyProgram: 'requestId',
  };

  getItem(key: string) {
    const item  = localStorage.getItem(key);
    return (item) ? item : null;
  }

  setItem(key: string, value: any): void {
   localStorage.setItem(key, value);
  }

  getObject(key: string) {
    const item  = localStorage.getItem(key);
    return (item) ? JSON.parse(item) : null;
  }

  setObject(key: string, value: any) {
    if(key === 'user') {
      value = {
        userId: value.userId || value?.user?.userId,
        name: value.name || value?.user?.name,
        accountType:value.accountType || value?.user?.accountType
      }
    }

    if(key === 'profile')  {
      value = {...value}
      delete value.email;
      delete value.gender;
      delete value.mobileNumber;
      delete value.whatsappNumber;
    }
    localStorage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }

  clear() {
    localStorage.clear();
  }
}
