<div class="d-flex protected-sidebar flex-column flex-shrink-0 p-3 bg-white h-100vh justify-content-between shadow-sm"
  [ngClass]="{
  'sidebar-dark':mode === 'dark',
  'sidebar-light':mode !== 'dark'
}" style="width: 280px;">
  <div>
    <!-- [class.sidebar-dark]="mode === 'dark'"  -->
    <div class="d-flex align-items-center mb-3 mb-md-0 me-md-auto link-body-emphasis text-decoration-none"
      style="height: 55px;">
      <a [href]="brandDetails?.features?.main_website_url" target="_blank" class="d-flex align-items-center"
        *ngIf="brandDetails?.features?.main_website_url">
        <app-logo-renderer class="h-25px h-lg-40px" [headerLogo]="mode === 'dark'"></app-logo-renderer>
      </a>
      <a routerLink="/" class="d-flex align-items-center" *ngIf="!brandDetails?.features?.main_website_url">
        <app-logo-renderer class="h-25px h-lg-40px" [headerLogo]="mode === 'dark'"></app-logo-renderer>
      </a>
    </div>

    <ng-container *ngIf="profileData">
      <div class="separator separator-dashed my-2"></div>
      <div class="sidebar-profile">
        <!-- <hr> -->
        <div class="me-3 d-flex position-relative cursor-pointer" routerLink="/">
          <div class="w-100">
            <app-user-profile-menu ></app-user-profile-menu>
          </div>

          <div [ngbTooltip]="'Switch Profile'" placement="bottom"
          *ngIf="brandDetails?.features?.multiple_profiles && this.showSwitchProfileBtn"
          class="position-absolute d-flex justify-content-center align-items-center cursor-pointer"
          style="right: 0;top:50%;transform:translateY(-50%);" (click)="openChangeProfileModal()">
            <i class="bi bi-arrow-repeat fs-2 text-dark switchprofilebtn"></i>
          </div>

        </div>
      </div>
    </ng-container>


    <div class="px-3 mt-2" *ngIf="profileData && this.showMessagesAndConnectionTab && !isLimitedAccess">
      <div class="btn-group" *ngIf="profileData">
        <button type="button" class="btn btn-outline-secondary btn-xs d-flex align-items-center px-0 py-3" *ngIf="brandDetails?.features?.chat
        " routerLink="/chat/conversations">
          <div class="btn-icon bg-hover-white bg-hover-opacity-10 w-25px w-md-30px position-relative ms-3"
            id="kt_drawer_chat_toggle" >
            <i class="bi bi-chat-fill fs-2 "></i>
            <span *ngIf="notificationsCount?.unreadMessageCount"
              class="position-absolute top-10 start-85 translate-middle badge badge-circle badge-dark bg-red count-badge">
              {{ notificationsCount?.unreadMessageCount || 0}}
            </span>
          </div>
          <span class="fs-8">
            Messages
          </span>
        </button>
        <!-- [routerLink]="notificationsCount?.pendingConnectionCount > 1? '/connections/pending-requests' : '/connections'"> -->
        <button type="button" class="btn btn-outline-secondary btn-xs  d-flex align-items-center px-0  py-3" *ngIf="brandDetails.features?.connections
        "
          [routerLink]="'/connections'">
          <div class=" btn-icon  bg-hover-white bg-hover-opacity-10 w-35px w-md-40px position-relative "
            id="kt_drawer_chat_toggle" >
            <i class="bi bi-people-fill fs-2 "></i>
            <span *ngIf="notificationsCount?.pendingConnectionCount"
              class="position-absolute top-10 start-85 translate-middle badge badge-circle bg-green bg-red count-badge">
              {{ notificationsCount?.pendingConnectionCount || 0 }}
            </span>
          </div>
          <span class="fs-8">
            Connections
          </span>
        </button>

      </div>
    </div>

    <div class="separator separator-dashed mt-3 mb-0"></div>
    <ul class="nav nav-pills flex-column mb-auto mt-2 nav-items-wrapper">



      <ng-container *ngFor="let item of navItems">

        <li class="nav-item" *ngIf="(!item.children || !item.children.length) && item.route"
          (click)="onSelectNavItem(item)">
          <ng-container *ngIf="(!item.featureKey || brandDetails.features[item.featureKey])">
            <div class="nav-link header-nav-hover header-nav-icon cursor-pointer d-flex align-items-center justify-content-between" [class.active]="isItemSelected(item)">
              <div class="d-flex align-items-center">
                <i class="{{item.iconClass}} me-3 fs-3" *ngIf="item.iconClass"></i>
                {{brandDetails.features[item.titleKey] || item.title}}
              </div>

              <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
                *ngIf="item.id === 'my-account' && brandDetails.features?.whatsapp_otp_verification && !profileData?.isWhatsappNumberVerified"
                [ngbTooltip]="'Verify Whatsapp number to start receive notifications.'" placement="bottom">
              </span>

              <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
              *ngIf="item.id === 'engage' && notificationsCount.pendingAcceptanceMeetingCount"
              [ngbTooltip]="'You have some meeting requests.'" placement="bottom">

            </span>


              <span class="bi bi-exclamation-circle fs-3  text-red ms-2 "
              *ngIf="item.id === 'programs' && brandDetails.features?.programs_public_view && notificationsCount.pendingProgramFormSubmissionCount>0"
              [ngbTooltip]="'You have partially completed applications.'" container="body" placement="bottom">
            </span>
            </div>
          </ng-container>
        </li>

        <li class="nav-item" *ngIf="(!item.children || !item.children.length) && !item.route && item.id == 'my-profile'" (click)="onSelectNavItem(item)">
          <ng-container *ngIf="(!item.featureKey || brandDetails.features[item.featureKey])">
            <div class="nav-link header-nav-hover header-nav-icon justify-content-between cursor-pointer d-flex align-items-center" [class.active]="isItemSelected(item)">
              <div class="d-flex align-items-center">
                <i class="{{item.iconClass}} me-3 fs-3" *ngIf="item.iconClass"></i>
                {{brandDetails.features[item.titleKey] || item.title}}
              </div>


              <div class="d-flex align-items-center" *ngIf="profileService.profileCompleteness && profileService.profileCompleteness?.percentage < 100 && !profileService.profileCompleteness?.isApproved">
                <span class="fs-8" *ngIf="profileService.profileCompleteness?.percentage">
                  {{profileService.profileCompleteness?.percentage}}%
                </span>
                <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
                  [ngbTooltip]="'Your profile is incomplete'" placement="bottom">
                </span>
              </div>

            </div>
          </ng-container>
        </li>

        <li class="nav-item accordian-button-sidebar header-nav-icon" *ngIf="item.children.length && !item.route"
          [class.active]="isExpanded(item.title)">
          <ngb-accordion #acc="ngbAccordion" [activeIds]="expandedItems"
          (shown)="itemShown($event)" (hidden)="itemHidden($event)">
            <ngb-panel [id]="item.title">
              <ng-template ngbPanelTitle>
                <div class="d-flex align-items-center justify-content-between w-100 pe-3">
                  <div class="d-flex align-items-center text-mob">
                    <i class="{{item.iconClass}} me-3 fs-3" *ngIf="item.iconClass"></i>
                    {{brandDetails.features[item.titleKey] || item.title}}
                  </div>

                  <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
                  *ngIf="item.id === 'engage' && (notificationsCount.pendingAcceptanceMeetingCount || pendingMetricsCount)"
                  container="body"
                  [ngbTooltip]="notificationsCount.pendingAcceptanceMeetingCount?'Pending meeting acceptance.': pendingMetricsCount+' metrics pending.'" placement="bottom">
                </span>


                <!-- <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
                  *ngIf="item.id === 'engage' && notificationsCount.pendingAcceptanceMeetingCount"
                  container="body"
                  [ngbTooltip]="'Pending meeting acceptance.'" placement="bottom">
                </span>
                 -->

                <!-- <span class="bi bi-exclamation-circle fs-3  text-red ms-2"
                *ngIf="item.id === 'engage' && brandDetails.features?.programs_public_view && notificationsCount.pendingProgramFormSubmissionCount>0"
                [ngbTooltip]="'You have partially completed applications.'" container="body" placement="bottom">
              </span> -->
                </div>

              </ng-template>

              <ng-template ngbPanelContent>
                <div>
                  <ng-container *ngFor="let childItem of item.children">
                    <ng-container *ngIf="(!childItem.featureKey || brandDetails.features[childItem.featureKey])">

                      <div class="menu-item px-2 d-flex align-items-center justify-content-between " (click)="onSelectNavItem(childItem, item, acc)">
                        <div class="menu-link px-5" style="flex: auto;" [class.active-link]="isChildItemSelected(childItem)"
                          ngbDropdownItem>
                          {{brandDetails.features[childItem.titleKey] ||  childItem.title}}
                        </div>


                        <span class="bi bi-exclamation-circle fs-3  text-red me-2"
                          *ngIf="childItem.id === 'growth-metrics' && pendingMetricsCount"
                          container="body"
                          [ngbTooltip]="pendingMetricsCount+' metrics pending.'" placement="bottom">
                        </span>

                        <span class="bi bi-exclamation-circle fs-3  text-red me-2"
                        *ngIf="childItem.id === 'calender' && notificationsCount.pendingAcceptanceMeetingCount"
                        container="body"
                        [ngbTooltip]="'Pending meeting acceptance.'" placement="bottom">
                      </span>


                      </div>

                    </ng-container>
                  </ng-container>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </li>
      </ng-container>




      <li class="nav-item" *ngIf="profileData?.accountType === ACCOUNT_TYPE.PARTNER || (profileData?.accountType === ACCOUNT_TYPE.PROGRAM_OFFICE && profileData?.partnerId !== null)"
(click)="handlePartnerAdminLoging()">
        <ng-container >
          <!-- profileCompleteness?.isApproved -->
           <div [ngbTooltip]="profileCompleteness?.isApproved ? '' : 'Gets activated once the profile is approved.'">
             <button class="nav-link header-nav-hover header-nav-icon cursor-pointer d-flex align-items-center justify-content-between w-100 admin-console-btn"
             [disabled]="!profileCompleteness?.isApproved">
               <div class="d-flex align-items-center">
                 <i class="bi bi-shield-lock me-3 fs-3" ></i>
                 Admin Console
               </div>
             </button>
           </div>
        </ng-container>
      </li>
      
    </ul>
  </div>

  <div class="sidebar-profile" *ngIf="profileData">
    <button class="btn btn-dark border text-white btn-sm w-100" (click)="handleLogoutWithConfirm()">
      <i class="bi bi-box-arrow-right"></i> Logout
    </button>
  </div>
</div>
