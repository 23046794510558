import { Action } from "@ngrx/store";
import { StartupInvestorDashboardResponse } from "../../domain/startup-investor-dashboard.model";
import { IOnGoingCommitments } from "../../domain/financial-details.model";
import { IFoundersInfo, IStartUpInfoBase, IStartUpInfoFetch } from "../../domain/startup.model";

export enum StartUpActionTypes {
  GetStartUpInfo = "[StartUp] GetStartUpInfo",
  GetStartUpInfoSuccess = "[StartUp] GetStartUpInfoSuccess",
  GetStartUpInfoFault = "[StartUp] GetStartUpInfoFault",

  SaveStartUpInfo = "[StartUp] SaveStartUpInfo",
  SaveStartUpInfoSuccess = "[StartUp] SaveStartUpInfoSuccess",
  SaveStartUpInfoFault = "[StartUp] SaveStartUpInfoFault",
  ResetSaveStartUpInfoError = "ResetSaveStartUpInfoError",

  // founders
  GetFounders = "[StartUp] GetFounders",
  GetFoundersSuccess = "[StartUp] GetFoundersSuccess",
  GetFoundersFault = "[StartUp] GetFoundersFault",


  //Advisory board
  GetAdvisoryBoard = "[StartUp] GetAdvisoryBoard",
  GetAdvisoryBoardSuccess = "[StartUp] GetAdvisoryBoardSuccess",
  GetAdvisoryBoardFault = "[StartUp] GetAdvisoryBoardFault",


  // Commitments
  GetCommitments = "[StartUp] GetCommitments",
  GetCommitmentsSuccess = "[StartUp] GetCommitmentsSuccess",
  GetCommitmentsFault = "[StartUp] GetCommitmentsFault"
}

export class SaveStartUpInfo implements Action {
  readonly type = StartUpActionTypes.SaveStartUpInfo;

  constructor(public payload: IStartUpInfoBase) { }
}

export class SaveStartUpInfoSuccess implements Action {
  readonly type = StartUpActionTypes.SaveStartUpInfoSuccess;

  constructor(public payload: IStartUpInfoFetch) { }
}

export class SaveStartUpInfoFault implements Action {
  readonly type = StartUpActionTypes.SaveStartUpInfoFault;

  constructor(public payload: string) { }
}

export class GetStartUpInfo implements Action {
  readonly type = StartUpActionTypes.GetStartUpInfo;
}

export class GetStartUpInfoSuccess implements Action {
  readonly type = StartUpActionTypes.GetStartUpInfoSuccess;

  constructor(public payload: IStartUpInfoFetch) { }
}

export class GetStartUpInfoFault implements Action {
  readonly type = StartUpActionTypes.GetStartUpInfoFault;

  constructor(public payload: string) { }
}

export class ResetSaveStartUpInfoError implements Action {
  readonly type = StartUpActionTypes.ResetSaveStartUpInfoError;

  constructor() { }
}


export class GetFounders implements Action {
  readonly type = StartUpActionTypes.GetFounders;
}

export class GetFoundersSuccess implements Action {
  readonly type = StartUpActionTypes.GetFoundersSuccess;

  constructor(public payload: IFoundersInfo[]) { }
}

export class GetFoundersFault implements Action {
  readonly type = StartUpActionTypes.GetFoundersFault;

  constructor(public payload: string) { }
}


export class GetAdvisoryBoard implements Action {
  readonly type = StartUpActionTypes.GetAdvisoryBoard;
}

export class GetAdvisoryBoardSuccess implements Action {
  readonly type = StartUpActionTypes.GetAdvisoryBoardSuccess;

  constructor(public payload: IFoundersInfo[]) { }
}

export class GetAdvisoryBoardFault implements Action {
  readonly type = StartUpActionTypes.GetAdvisoryBoardFault;

  constructor(public payload: string) { }
}


export class GetCommitments implements Action {
  readonly type = StartUpActionTypes.GetCommitments;
}

export class GetCommitmentsSuccess implements Action {
  readonly type = StartUpActionTypes.GetCommitmentsSuccess;
  constructor(public payload: IOnGoingCommitments[]) { }
}

export class GetCommitmentsFault implements Action {
  readonly type = StartUpActionTypes.GetCommitmentsFault;
  constructor(public payload: string) { }
}


export type StartUpActions =
  | SaveStartUpInfo
  | SaveStartUpInfoSuccess
  | SaveStartUpInfoFault
  | ResetSaveStartUpInfoError
  | GetStartUpInfo
  | GetStartUpInfoFault
  | GetStartUpInfoSuccess
  | GetFounders
  | GetFoundersFault
  | GetFoundersSuccess
  | GetCommitments
  | GetCommitmentsSuccess
  | GetCommitmentsFault
  | GetAdvisoryBoard
  | GetAdvisoryBoardSuccess
  | GetAdvisoryBoardFault;
