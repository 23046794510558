import { InvididualProfileResponseModel } from "../../domain";
import {
  IndividualProfileAction,
  IndividualProfileInfoActionTypes
} from "./individual-profile-info.action";

export interface IndividualProfileInfoState {
  invididualProfile: InvididualProfileResponseModel,
  error: string | null
}

export const initialState: IndividualProfileInfoState = {
  invididualProfile: null,
  error: ""

};

function individualProfileInfoSuccess(state: IndividualProfileInfoState = initialState, data: InvididualProfileResponseModel): IndividualProfileInfoState {
  return {
    ...state,
    invididualProfile: data,
    error: ""
  };
}

function individualProfileInfoFault(state: IndividualProfileInfoState = initialState, data: string): IndividualProfileInfoState {
  return {
    ...state,
    invididualProfile: null,
    error: data
  };
}

export function individualProfileReducer(state: IndividualProfileInfoState = initialState, action: IndividualProfileAction): IndividualProfileInfoState {
  switch (action.type) {
    case IndividualProfileInfoActionTypes.GetIndividualProfileInfoSuccess:
      return individualProfileInfoSuccess(state, action.payload);
    case IndividualProfileInfoActionTypes.IndividualProfileInfoFault:
      return individualProfileInfoFault(state, action.payload);
    default:
      return state;
  }
}


