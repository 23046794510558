import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { select, Store } from "@ngrx/store";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    distinctUntilChanged,
    map,
    takeUntil,
    tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import {
  InvestorTypes,
    OrganizationInformationModel, CorporateInformationResponseModel
} from "../domain";
import { InvestmentInfoModel } from "../domain/investment-types.model";
import { OrgRepresentativeModel } from "../domain/org-representative.model";
import { ApiEndpointService } from "./api-endpoint.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import * as InvestorDashboardAction from 'src/app/core/state/investor-dashboard/investor.dashboard.action';
import { getProfileData } from "../state/profile";
import { IProfileDetails } from "../domain/profile.model";
import { GlobalService } from "./global.service";
@Injectable({
    providedIn: "root"
})
export class InvestorService {
    profileData: IProfileDetails;
    /**
     * Constructor.
     */
    constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store, private globalService: GlobalService) {
      this.store.pipe(select(getProfileData))
      .pipe(distinctUntilChanged()).subscribe((res) => {
        if (res) {
          this.profileData = res;
        }
      })
    }

    getProfileCompleteness() {
      console.log('getting completeness', this.profileData.investorType);
      if(this.profileData.investorType === InvestorTypes.INDIVIDUAL) {
        return this.store.dispatch(new InvestorDashboardAction.GetIndividualProfileCompleteness());
      }
      return this.store.dispatch(new InvestorDashboardAction.GetProfileCompleteness());
    }

    public patchInvestorOrganizationInfo(organizationInfo: OrganizationInformationModel): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_ORGANIZATION_INFO);
        return this.http.patch(url, organizationInfo).pipe(
                tap(() => {
                  this.getProfileCompleteness()
                }),
                catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault?.error?.message} )`);
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving organizzation info!'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public patchInvestorOrgRepresentativeInfo(organizationInfo: OrgRepresentativeModel): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_REPRESENTATIVE_INFO);
        return this.http.patch(url, organizationInfo).pipe(
                tap(() => {
                  this.getProfileCompleteness()
                }),
                catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving organizzation info!'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public patchInvestorOrgInvestmentInfo(organizationInfo: InvestmentInfoModel): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_INVESTMENT_INFO);
        return this.http.patch(url, organizationInfo).pipe(
                tap(() => {
                  this.getProfileCompleteness()
                }),
                catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving organizzation info!'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public getInvestorOrganizationInfo(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_ORGANIZATION_INFO);
        return this.http.get(url).pipe(
            map((response: any): CorporateInformationResponseModel => {
                return response
            }),
            catchError((fault: HttpErrorResponse) => {
                return throwError(() => fault);
            })
        );
    }

    public uploadLogo(logo: File, refetchProfile = false): Observable<any> {
      const payload = new FormData();
      payload.append('file', logo);
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_LOGO_UPLOAD);
      console.info(`saving inverstor up logo`, payload);
      return this.http.post(url, payload).pipe(
        tap(() => {
          if(refetchProfile) {
            this.store.dispatch(new ProfileActions.GetProfile());
          }
          this.getProfileCompleteness()
        }),
        catchError((fault: HttpErrorResponse) => {
          this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading logo!'), 'error');
          console.warn(`save inverstor Uplogo( ${fault?.error?.message} )`);
          return throwError(() => fault);
        })
      );
    }


    public uploadConnectionDocument(file: File): Observable<{ message: string, data: any }> {
      const payload = new FormData();
      payload.append('file', file);
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INVESTOR_CONNECTION_DOC_UPLOAD);
      return this.http.post(url, payload).pipe(
        map((response: any): any => {
          return response;
        }),
        catchError((fault: HttpErrorResponse) => {
          this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving PitchFile!'), 'error');
          return throwError(() => fault);
        })
      );
    }

    
}
