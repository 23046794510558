import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StartupSearchCardComponent } from './startup-search-card.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { AvatarModule } from 'ngx-avatars';

@NgModule({
  declarations: [StartupSearchCardComponent],
  imports: [CommonModule, NgbTooltipModule, AvatarModule, NgbModule],
  exports: [StartupSearchCardComponent]
})
export class StartupSearchCardModule { }
