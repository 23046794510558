import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { Approval, InvestorProfileCompleteness, InvestorProfileCompletenessResponse, StartupInvestorProfileCompletenessResponse } from "../../domain";
import { InvestorDashboardService } from "../../service";
import * as InvestorDashBoardActions from "./investor.dashboard.action";
import { InvestorDashboardActionTypes } from './investor.dashboard.action';

@Injectable()
export class InvestorDashboardEffect {

    getInvestorUpDashboard$ = createEffect(() => this.actions$.pipe(
        ofType<InvestorDashBoardActions.GetInvestorDashboard>(InvestorDashboardActionTypes.GetInvestorDashboard),
        exhaustMap(() => this.investorDashboardService.getInvestorDashboard().pipe(
            map((data: any) => new InvestorDashBoardActions.GetInvestorDashboardSuccess(data)),
            catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.GetInvestorDashboardFault(err.error.message)))
        ))
    ))


    getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
      ofType<InvestorDashBoardActions.GetProfileCompleteness>(InvestorDashboardActionTypes.GetProfileCompleteness),
      map((action: InvestorDashBoardActions.GetProfileCompleteness) => action),
      exhaustMap(() => this.investorDashboardService.getInvestorCompleteness().pipe(
          mergeMap((data: InvestorProfileCompleteness) => [
              new InvestorDashBoardActions.GetProfileCompletenessSuccess(data),
          ]),
          catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.GetProfileCompletenessFault(err.error.message)))
      ))
    ));

    getIndividualProfileCompleteness$ = createEffect(() => this.actions$.pipe(
      ofType<InvestorDashBoardActions.GetIndividualProfileCompleteness>(InvestorDashboardActionTypes.GetIndividualProfileCompleteness),
      map((action: InvestorDashBoardActions.GetIndividualProfileCompleteness) => action),
      exhaustMap(() => this.investorDashboardService.getIndividualInvestorCompleteness().pipe(
          mergeMap((data: InvestorProfileCompleteness) => [
              new InvestorDashBoardActions.GetIndividualProfileCompletenessSuccess(data),
          ]),
          catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.GetIndividualProfileCompletenessFault(err.error.message)))
      ))
    ));

    sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
        ofType<InvestorDashBoardActions.SendRequestApproval>(InvestorDashboardActionTypes.SendRequestApproval),
        map((action: InvestorDashBoardActions.SendRequestApproval) => action),
        exhaustMap(() => this.investorDashboardService.sendApprovalRequest().pipe(
            mergeMap((data: string) => [
                new InvestorDashBoardActions.SendRequestApprovalSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.SendRequestApprovalFault(err.error.message)))
        )),
    ));

    providingFunds$ = createEffect(() => this.actions$.pipe(
        ofType<InvestorDashBoardActions.ProvidingFundRequest>(InvestorDashboardActionTypes.ProvidingFundRequest),
        map((action: InvestorDashBoardActions.ProvidingFundRequest) => action),
        exhaustMap(() => this.investorDashboardService.providingFunds().pipe(
            mergeMap((data: string) => [
                new InvestorDashBoardActions.ProvidingFundRequestSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new InvestorDashBoardActions.ProvidingFundRequestFault(err.error.message)))
        )),
    ));

    constructor(
        private actions$: Actions,
        private investorDashboardService: InvestorDashboardService
    ) { }
}
