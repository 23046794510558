import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import { StorageService } from "src/app/shared/utils/storage.service";
import { IProfileDetails, IProfileType } from "../../domain/profile.model";
import { ProfileService } from "../../service/profile.service";
import * as ProfileActions from "./profile.action";
import { ProfileActionTypes } from "./profile.action";

@Injectable()
export class ProfileEffect {

  getProfileData$ = createEffect(() => this.actions$.pipe(
    ofType<ProfileActions.GetProfile>(ProfileActionTypes.GetProfile),
    exhaustMap((payload) =>
      this.profileService.getProfileDetails().pipe(
        map((data: IProfileDetails) => {
          this.storageService.setObject('profile', data);

          if(payload.reload) {
            window.location.href = '/'
          }

          return new ProfileActions.ProfileSuccess(data)
        }),
        catchError((err: HttpErrorResponse) => of(new ProfileActions.ProfileFault(err.message)))
      )
    )
  ));

  getProfileTypes$ = createEffect(() => this.actions$.pipe(
    ofType<ProfileActions.GetProfileTypes>(ProfileActionTypes.GetProfileTypes),
    exhaustMap(() =>
      this.profileService.getProfileTypes().pipe(
        map((data: IProfileType[]) => {
          return new ProfileActions.GetProfileTypesSuccess(data)
        }),
      )
    )
  ));

  constructor(private actions$: Actions, private store$: Store<any>, private profileService: ProfileService, private storageService: StorageService) {
  }
}
