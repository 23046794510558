import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum CorporateDashboardActionTypes {
  GetProfileCompleteness = "[Corporate Dashboard] GetCorporateProfileCompleteness",
  GetProfileCompletenessSuccess = "[Corporate Dashboard] GetCorporateProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[Corporate Dashboard] GetCorporateProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[Corporate Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[Corporate Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[Corporate Dashboard] SendRequestApprovalFault",

  GetCorporateDashboard = "[Corporate Dashboard] GetCorporateDashboard",
  GetCorporateDashboardSuccess = "[Corporate Dashboard] GetCorporateDashboardSuccess",
  GetCorporateDashboardFault = "[Corporate Dashboard] GetCorporateDashboardFault",

}


export class GetCorporateDashboard implements Action {
  readonly type = CorporateDashboardActionTypes.GetCorporateDashboard;
}

export class GetCorporateDashboardSuccess implements Action {
  readonly type = CorporateDashboardActionTypes.GetCorporateDashboardSuccess;
  constructor(public payload: any) { }
}

export class GetCorporateDashboardFault implements Action {
  readonly type = CorporateDashboardActionTypes.GetCorporateDashboardFault;
  constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = CorporateDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = CorporateDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = CorporateDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = CorporateDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = CorporateDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = CorporateDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type CorporateDashBoardActions =
  GetCorporateDashboard
  | GetCorporateDashboardSuccess
  | GetCorporateDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
