export enum JobTypes {
    full_time = 'full_time',
    part_time = 'part_time',
    work_from_home = 'work_from_home',
    hybrid = 'hybrid',
}
export const JobTypeLabels = {
    [JobTypes.full_time] : 'Full Time',
    [JobTypes.part_time] : 'Part Time',
    [JobTypes.work_from_home] : 'Work From Home',
    [JobTypes.hybrid] : 'Hybrid',
}
export enum WorkPlaceTypes {
  ON_SITE = 'on_site',
  HYBRID = 'hybrid',
  REMOTE = 'remote',
  NOT_SPECIFIED = 'not_specified',
}
export const WorkPlaceLabels = {
  [WorkPlaceTypes.ON_SITE] : 'On-site',
  [WorkPlaceTypes.REMOTE] : 'Remote',
  [WorkPlaceTypes.HYBRID] : 'Hybrid',
  [WorkPlaceTypes.NOT_SPECIFIED] : 'Not specified',
}

export enum IntellectualPropertyStatus {
  YES = 'yes',
  NO = 'no',
}

export enum IntellectualPropertyStatusOptions {
  APPLIED = 'applied',
  GRANTED = 'granted',
  YETTOAPPLY = 'yet_to_apply',
}

export enum SocketNotificationType {
  CONNECTION_REQUESTED = 'connection_requested',
  CONNECTION_ACCEPTED = 'connection_accepted',
  MESSAGE_NOTIFICATION_RECEIVED = 'message_notification_received',
}

export enum SocketChatNotification {
  MESSAGE_RECEIVED = 'message_received',
  REPLY_MESSAGE_RECEIVED = 'reply_message_received',
  MESSAGE_DELETED = 'message_deleted',
}
