import { IChallenges } from "../../domain/challenges.model";
import { IJobs } from "../../domain/jobs.model";
import { PartnersInformation } from "../../domain/partners.model";
import { ChallengesAction, ChallengesActionTypes } from "./challenges.action";


export interface ChallengesState {
    challengesList: IChallenges[];
    challengesLoading: boolean;
    challengesError: string | null | string[];
}

export const initialState: ChallengesState = {
    challengesList: null,
    challengesLoading: false,
    challengesError: ""
}

function initChallengesList(state: ChallengesState = initialState,  payload): ChallengesState {
    return {
        ...state,
        challengesLoading: true,
        challengesError: ""
    };
}

function ChallengeListSuccess(state: ChallengesState = initialState, data: any): ChallengesState {
    return {
        ...state,
        challengesList: data,
        challengesLoading: false,
        challengesError: ""
    }
}

function ChallengeListFailed(state: ChallengesState = initialState, data: string): ChallengesState {
    return {
        ...state,
        challengesList: null,
        challengesLoading: false,
        challengesError: data
    }
}


export function challengesReducer(state: ChallengesState = initialState, action: ChallengesAction): ChallengesState {

    switch (action.type) {
        case ChallengesActionTypes.GetChallengesList:
            return initChallengesList(state,  action.filters);

        case ChallengesActionTypes.GetChallengesListSuccess:
            return ChallengeListSuccess(state, action.payload);

        case ChallengesActionTypes.GetChallengesListFault:
            return ChallengeListFailed(state, action.payload);


        default:
            return state;
    }
}
