import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { VerifyEmailComponent } from './modules/auth/pages/verify-email/verify-email.component';
import { PageNotFoundComponent } from './shared/common-components/page-not-found/page-not-found.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { NonAuthGuard } from './shared/guards/non-auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/auth/login',
    pathMatch: 'full'
  },
  {
    path: 'account',
    loadChildren: () => import('./modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: 'investors',
    loadChildren: () => import('./modules/investors/investors.module').then(m => m.InvestorsModule),
  },
  // {
  //   path: 'investors-preview',
  //   loadChildren: () => import('./modules/investors/investors.module').then(m => m.InvestorsModule),
  // },
  {
    path: 'startups',
    loadChildren: () => import('./modules/startups/startups.module').then(m => m.StartupsModule),
  },
  // {
  //   path: 'startups-preview',
  //   loadChildren: () => import('./modules/startups/startups.module').then(m => m.StartupsModule),
  // },
  {
    path: 'corporates',
    loadChildren: () => import('./modules/corporate/corporate.module').then(m => m.CorporateModule),
  },
  {
    path: 'mentors',
    loadChildren: () => import('./modules/mentors/mentors.module').then(m => m.MentorsModule),
  },

  {
    path: 'program-office-members',
    loadChildren: () => import('./modules/program-office/program-office.module').then(m => m.ProgramOfficeModule),
  },

  {
    path: 'service-provider',
    loadChildren: () => import('./modules/service-provider/service-provider.module').then(m => m.ServiceProviderModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'calender',
    loadChildren: () => import('./modules/calender/calender.module').then(m => m.CalenderModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'chat',
    loadChildren: () => import('./modules/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'startup-booster-kit',
    loadChildren: () => import('./modules/startup-kit/startup-kit.module').then(m => m.StartupKitModule)
  },
  {
    path: 'pitch-deck',
    loadChildren: () => import('./modules/pitch-deck-recorder/pitch-deck-recorder.module').then(m => m.PitchDeckRecorderModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
    canActivate: [NonAuthGuard]
  },
  {
    path: 'errors/404',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
    // component: PageNotFoundComponent
  },
  {
    path: 'meetings',
    loadChildren: () => import('./modules/meetings/meetings.module').then(m => m.MeetingsModule),
  },
  {
    path: 'notifications',
    loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'connections',
    loadChildren: () => import('./modules/connection-v4/connection-v4.module').then(m => m.ConnectionV4Module),
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'connections-v3',
  //   loadChildren: () => import('./modules/connections-v3/connections-v3.module').then(m => m.ConnectionsV3Module),
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'jobs',
    loadChildren: () => import('./modules/hire/hire.module').then(m => m.HireModule)
  },
  {
    path: 'mentorship-hours',
    loadChildren: () => import('./modules/tracker/tracker.module').then(m => m.TrackerModule)
  },
  {
    path: 'jobs/:id/details',
    loadChildren: () => import('./modules/job-details/job-details.module').then(m => m.JobDetailsModule)
  },
  {
    path: 'job-interview/:id',
    loadChildren: () => import('./modules/job-interview/job-interview.module').then(m => m.JobInterviewModule)
  },
  {
    path: 'connection-request',
    loadChildren: () => import('./modules/connection-approve-reject-page/connection-approve-reject-page.module').then(m => m.ConnectionApproveRejectPageModule),
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners/partners.module').then(m => m.PartnersModule)
  },
  {
    path: 'partners',
    loadChildren: () => import('./modules/partners-dashboard/partners-dashboard.module').then(m => m.PartnersDashboardModule)
  },

  {
    path: 'partners/:partnerId/:name',
    loadChildren: () => import('./modules/partners-details/partners-details.module').then(m => m.PartnersDetailsModule)
  },

  {
    path: 'individuals',
    loadChildren: () => import('./modules/individual-profile/individual-profile.module').then(m => m.IndividualProfileModule)
  },


  {
    path: 'backdoor-login',
    loadChildren: () => import('./modules/admin-actions/admin-actions.module').then(m => m.AdminActionsModule)
  },
  {
    path: 'request/authenticate',
    loadChildren: () => import('./modules/connection-request-action-email/connection-request-action-email.module').then(m => m.ConnectionRequestActionEmailModule)
  },
  { path: 'verify/email/:verificationUid/:emailAddress', component: VerifyEmailComponent },
  {
    path: 'challenges',
    loadChildren: () => import('./modules/challenges/challenges.module').then(m => m.ChallengesModule)
  },
  {
    path: 'challenges/:id/details',
    loadChildren: () => import('./modules/challenge-details/challenge-details.module').then(m => m.ChallengeDetailsModule)
  },
  {
    path: 'forms',
    loadChildren: () => import('./modules/dynamic-forms/dynamic-forms.module').then(m => m.DynamicFormsModule),
    canActivate: [],
  },
  {
    path: 'events/register',
    loadChildren: () => import('./modules/public-events/public-events.module').then(m => m.PublicEventsModule),
    canActivate: [],
  },
  {
    path: 'search/challenges',
    loadChildren: () => import('./modules/challenge-search/challenge-search.module').then(m => m.ChallengeSearchModule)
  },
  {
    path: 'search/challenges',
    loadChildren: () => import('./modules/challenge-public-details/challenge-public-details.module').then(m => m.ChallengePublicDetailsModule)
  },

  {
    path: 'search/jobs',
    loadChildren: () => import('./modules/job-search/job-search.module').then(m => m.JobSearchModule)
  },
  {
    path: 'search/ips',
    loadChildren: () => import('./modules/ip-search/ip-search.module').then(m => m.IpSearchModule)
  },
  {
    path: 'facilities-management',
    loadChildren: () => import('./modules/facilities-management/facilities-management.module').then(m => m.FacilitiesManagementModule)
  },
  {
    path: 'certi',
    loadChildren: () => import('./modules/sc-certificate-renderer/sc-certificate-renderer.module').then(m => m.ScCertificateRendererModule)
  },
  {
    path: 'global-search',
    loadChildren: () => import('./modules/global-search-page/global-search-page.module').then(m => m.GlobalSearchPageModule)
  },

  {
    path: 'market-insights',
    loadChildren: () => import('./modules/market-insights/market-insights.module').then(m => m.MarketInsightsModule)
  },
  {
    path: 'applied/jobs',
    loadChildren: () => import('./modules/applied-jobs/applied-jobs.module').then(m => m.AppliedJobsModule)
  },
  {
    path: 'search/jobs',
    loadChildren: () => import('./modules/job-public-details/job-public-details.module').then(m => m.JobPublicDetailsModule)
  },
  {
    path: 'glossary',
    loadChildren: () => import('./modules/glossary/glossary.module').then(m => m.GlossaryModule)
  },
  {
    path: 'resources',
    loadChildren: () => import('./modules/resources/resources.module').then(m => m.ResourcesModule)
  },
  {
    path: 'community-feed',
    loadChildren: () => import('./modules/community-feed/community-feed.module').then(m => m.CommunityFeedModule)
  },
  {
    path: 'program-office-team',
    loadChildren: () => import('./modules/program-office-team/program-office-team.module').then(m => m.ProgramOfficeTeamModule)
  },

  {
    path: 'milestones',
    loadChildren: () => import('./modules/milestones/milestones.module').then(m => m.MilestonesModule)
  },
  {
    path: 'tickets',
    loadChildren: () => import('./modules/tickets/tickets.module').then(m => m.TicketsModule)
  },

  {
    path: 'ip-request',
    loadChildren: () => import('./modules/ip-request/ip-request.module').then(m => m.IpRequestModule)
  },
  {
    path: 'certificate/verify',
    loadChildren: () => import('./modules/cerificates/cerificates.module').then(m => m.CerificatesModule)
  },

  {
    path: 'videos',
    loadChildren: () => import('./modules/webinars/webinars.module').then(m => m.WebinarsModule)
  },

  {
    path: 'reports',
    loadChildren: () => import('./modules/resource-reports/resource-reports.module').then(m => m.ResourceReportsModule)
  },


  {
    path: 'programs',
    loadChildren: () => import('./modules/programs/programs.module').then(m => m.ProgramsModule)
  },

  {
    path: 'vs-programs',
    loadChildren: () => import('./modules/vs-programs/vs-programs.module').then(m => m.VsProgramsModule)
  },

  {
    path: 'platform-updates',
    loadChildren: () => import('./modules/product-updates/product-updates.module').then(m => m.ProductUpdatesModule)
  },

  {
    path: 'news',
    loadChildren: () => import('./modules/deeptech-news/deeptech-news.module').then(m => m.DeeptechNewsModule)
  },

  {
    path: 'growth-metrics',
    loadChildren: () => import('./modules/growth-matrics/growth-matrics.module').then(m => m.GrowthMatricsModule)
  },

  {
    path: 'growth-metrics-print',
    loadChildren: () => import('./modules/growth-matrics-print/growth-matrics-print.module').then(m => m.GrowthMatricsPrintModule)
  },
  {
    path: '**',
    redirectTo: '/errors/404'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
