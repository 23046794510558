import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map,
    tap,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import {
    Auth,
    InvestorTypes,
    LoginCredentials,
    SignupCredentials,
    SingUpSendOtpResponse,
    SingUpVerifyOtpResponse,
    VerifyOtpCredentials
} from "../domain";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as AuthActions from "src/app/core/state/auth/auth.action";
import { USER_TYPES, USER_TYPE_INVESTOR } from "src/app/shared/constants/startup-invetor-constants";
import md5 from 'md5';

@Injectable({
    providedIn: "root"
})
export class AuthService {
    constructor(
        private http: HttpClient,
        private toastService: ToastAlertService,
        private globalService: GlobalService,
        private store: Store
    ) { }

    /**
     * Attempt authentication.
     */
    public login(loginCredentials: LoginCredentials): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MOBILE_LOGIN);
        const params = {
            countryCode: loginCredentials.countryCode,
            email: loginCredentials.email
          };
        if(loginCredentials.mobileNumber) {
          params['mobileNumber']= +loginCredentials.mobileNumber ? +loginCredentials.mobileNumber : '';
        }
        if(loginCredentials.email){
          params['email']= loginCredentials.email;
        }
        // console.info(`login( Logging into API "${url}" with creds: ${params.countryCode} / ${params.mobileNumber} )`);
        return this.http.post(url, params).pipe(
            // map((res: any) => res.data),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                return throwError(() => fault);
            })
        );
    }


    /**
     * Attempt authentication.
     */
    public verifyOtp(loginCredentials: VerifyOtpCredentials): Observable<Auth> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MOBILE_LOGIN_VERIFY_OTP);
        const params = {
            countryCode: loginCredentials.countryCode,
            // mobileNumber:loginCredentials.mobileNumber?  loginCredentials.mobileNumber: '',
            code: md5(loginCredentials.code),
            // email: loginCredentials.email
        };
        if(loginCredentials.mobileNumber) {
          params['mobileNumber']= +loginCredentials.mobileNumber ? +loginCredentials.mobileNumber : '';
        }
        if(loginCredentials.email){
          params['email']= loginCredentials.email;
        }
        return this.http.post(url, params).pipe(
            map((response: any): Auth => {
                // console.info(`loginSuccess( Received access token: ${response.accessToken} )`);
                return {
                    ...response.data.user,
                    accessToken: response.data.accessToken,
                    // userId:response.data.user.userId,
                    // name:response.data.user.name,
                    // userGroup:response.data.user.userGroup,
                };
            }),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error verifying the OTP!'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public logout = (ignoreRedirect?) => {
        this.store.dispatch(new AuthActions.LogOut({ignoreRedirect}));
    }

    public verifyMobile(mobileNumber: string, userType :USER_TYPES= USER_TYPES.INVESTOR, investorType: InvestorTypes = InvestorTypes.INDIVIDUAL): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.VERIFY_MOBILE + mobileNumber);
        return this.http.get(url,  {
          params : {
            userType,
            investorType
          }
        }).pipe(
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(fault?.error?.message || 'Error while verifying mobile number', 'error');
                return throwError(() => fault);
            })
        );
    }

    public verifyEmail(email: string, userType :USER_TYPES= USER_TYPES.INVESTOR, investorType: InvestorTypes = InvestorTypes.INDIVIDUAL): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.VERIFY_EMAIL + email.toLowerCase());
        return this.http.get(url ,  {
          params: {
            userType,
            investorType
          }
        }).pipe(
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(fault?.error?.message || 'Error while verifying email address', 'error');
                return throwError(() => fault);
            })
        );
    }


    public markEmailAsVerified(emailAddress, verificationId) {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.VERIFY_EMAIL );
      return this.http.post(url ,  {
        emailAddress, verificationId
      }).pipe(
          map((response: any): any => {
              return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(fault?.error?.message || 'Error while verifying email address', 'error');
              return throwError(() => fault);
          })
      );
    }

    public deleteAccount() {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.DELETE_ACCOUNT );
      return this.http.delete(url).pipe(
          // tap(()=>{
          //   this.logout()
          // }),
          map((response: any): any => {
              return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(fault?.error?.message || 'Error while deleting account', 'error');
              return throwError(() => fault);
          })
      );
    }

    public deactivateAccount(currentProfileActivateStatus) {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.DEACTIVATE_ACCOUNT );
      return this.http.patch(url, {}).pipe(
          map((response: any): any => {
            this.toastService.showToast(currentProfileActivateStatus ? 'Account Deactivated Successfully.' : 'Account Re-activated Successfully.');
            return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(fault?.error?.message || 'Error while deactivate account', 'error');
              return throwError(() => fault);
          })
      );
    }




    public sendWhatsappOTP(signupCredentials: SignupCredentials): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.WHATSAPP_SEND_OTP);
      const params = {
        type: 'whatsapp',
        countryCode: signupCredentials.countryCode,
        mobileNumber: signupCredentials.mobileNumber,
        emailAddress: signupCredentials.emailAddress,
        name: signupCredentials.name
      };
      return this.http.post(url, params).pipe(
        map((response: SingUpSendOtpResponse) => {

          console.trace('============== sendOTP() response =============');

          return response.data
        }),
        catchError((fault: HttpErrorResponse) => {
          console.warn(`sendOTPFault( ${fault.error.message} )`);
          return throwError(() => fault);
        })
      );
    }

    public verifyWhatsappOTP(signupCredentials: SignupCredentials): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.WHATSAPP_VERIFY_OTP);
      const params = {
        type: 'whatsapp',
        countryCode: signupCredentials.countryCode,
        mobileNumber: signupCredentials.mobileNumber,
        emailAddress: signupCredentials.emailAddress,
        code: md5(signupCredentials.code.toString()),
        name: signupCredentials.name
      };
      return this.http.post(url, params).pipe(
        map((response: SingUpVerifyOtpResponse) => {
          return response.data
        }),
        catchError((fault: HttpErrorResponse) => {
          console.warn(`verifyOTPFault( ${fault.error.message} )`);
          this.toastService.showToast(fault?.error?.message || 'Error while verifying OTP', 'error');
          return throwError(() => fault);
        })
      );
    }

    public logoutFromApi() {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.USER ) + 'logout';
      return this.http.get(url, {}).pipe(
          map((response: any): any => {
            // this.toastService.showToast(currentProfileActivateStatus ? 'Account Deactivated Successfully.' : 'Account Re-activated Successfully.');
            return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              // this.toastService.showToast(fault?.error?.message || 'Error while deactivate account', 'error');
              return throwError(() => fault);
          })
      );
    }


    public importProfileOtpRequest(payload): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.AUTH_EXTERNAL);
      return this.http.post(url + '/login', payload).pipe(
          catchError((fault: HttpErrorResponse) => {
              console.warn(`loginFault( ${fault.error.message} )`);
              return throwError(() => fault);
          })
      );
  }

  public verifyImportProfileOtpRequest(payload): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.AUTH_EXTERNAL);
    payload['code'] =  md5(payload.code.toString());
    return this.http.post(url+'/login/verify/import', payload).pipe(
      map((res: any) => {
        this.toastService.showToast(res.message|| 'Profile imported successfully');
        return res.data
      }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`loginFault( ${fault.error.message} )`);
            return throwError(() => fault);
        })
    );
}
}
