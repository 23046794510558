import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import { IGlobalSettings } from "../../domain/settings.model";
import { PublicApiService } from "../../service/public-api.service";
import * as GlobalActions from "./global.action";
import { GlobalActionTypes } from "./global.action";

@Injectable()
export class GlobalEffect {

  getGlobalSettings$ = createEffect(() => this.actions$.pipe(
    ofType<GlobalActions.GetGlobalSettings>(GlobalActionTypes.GetGlobalSettings),
    exhaustMap(() =>
      this.publicApiService.fetchGlobalSettings().pipe(
        map((data: IGlobalSettings) => new GlobalActions.GetGlobalSettingsSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new GlobalActions.GetGlobalSettingsFault(err.message)))
      )
    )
  ));

  constructor(private actions$: Actions, private publicApiService: PublicApiService) {
  }
}
