import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { PartnersInformation } from "../../domain/partners.model";
import { PartnersService } from "../../service";
import * as PartnersActions from "./partners.action";
import { PartnersActionTypes } from "./partners.action";

@Injectable()
export class PartnersEffect {
    constructor(private actions$: Actions, private partnersService: PartnersService) { }

    getPartnersInformationList$ = createEffect(() => this.actions$.pipe(
        ofType<PartnersActions.GetPartnersInformationList>(PartnersActionTypes.GetPartnersInformationList),
        map((action: PartnersActions.GetPartnersInformationList) => action),
        exhaustMap(() => this.partnersService.getPartnersInformationList().pipe(
            mergeMap((data: PartnersInformation) => [
                new PartnersActions.GetPartnersInformationListSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new PartnersActions.GetPartnersInformationListFault(err.error.message)))
        ))
    ))

    getPartnersInformation$ = createEffect(() => this.actions$.pipe(
        ofType<PartnersActions.GetPartnersInformation>(PartnersActionTypes.GetPartnersInformation),
        map((action: any) => action),
        exhaustMap((action) => this.partnersService.getPartnersInformation(action.payload).pipe(
            mergeMap((data: PartnersInformation) => [
                new PartnersActions.GetPartnersInformationSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new PartnersActions.GetPartnersInformationFault(err.error.message)))
        ))
    ))
}