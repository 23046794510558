import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum ProgramOfficeDashboardActionTypes {
  GetProfileCompleteness = "[ProgramOffice Dashboard] GetProgramOfficeProfileCompleteness",
  GetProfileCompletenessSuccess = "[ProgramOffice Dashboard] GetProgramOfficeProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[ProgramOffice Dashboard] GetProgramOfficeProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[ProgramOffice Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[ProgramOffice Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[ProgramOffice Dashboard] SendRequestApprovalFault",

  GetProgramOfficeDashboard = "[ProgramOffice Dashboard] GetProgramOfficeDashboard",
  GetProgramOfficeDashboardSuccess = "[ProgramOffice Dashboard] GetProgramOfficeDashboardSuccess",
  GetProgramOfficeDashboardFault = "[ProgramOffice Dashboard] GetProgramOfficeDashboardFault",
}


export class GetProgramOfficeDashboard implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboard;
}

export class GetProgramOfficeDashboardSuccess implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboardSuccess;
  constructor(public payload: any) { }
}

export class GetProgramOfficeDashboardFault implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProgramOfficeDashboardFault;
  constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = ProgramOfficeDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type ProgramOfficeDashBoardActions =
  GetProgramOfficeDashboard
  | GetProgramOfficeDashboardSuccess
  | GetProgramOfficeDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
