import { CorporateProfileCompleteness } from "../../domain";
import { CorporateDashBoardActions, CorporateDashboardActionTypes } from "./corporate.dashboard.action";

export interface CorporateDashboardState {
  corporateDashboard: any;
  corporateDashboardLoading: boolean;
  corporateDashboardError: string | null | string[];

  corporateProfileCompleteness: CorporateProfileCompleteness;
  corporateProfileCompletenessLoading: boolean;
  corporateProfileCompletenessError: string | null | string[];


  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;

}

export const initialState: CorporateDashboardState = {
  corporateDashboard: null,
  corporateDashboardLoading: false,
  corporateDashboardError: "",


  corporateProfileCompleteness: null,
  corporateProfileCompletenessLoading: false,
  corporateProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initInvestorDashboard(state: CorporateDashboardState = initialState): CorporateDashboardState {
  return {
    ...state,
    corporateDashboardLoading: true,
    corporateDashboardError: ""
  };
}

function investorDashboardSuccess(state: CorporateDashboardState = initialState, data: any): CorporateDashboardState {
  return {
    ...state,
    corporateDashboard: data,
    corporateDashboardLoading: false,
    corporateDashboardError: ""
  }
}

function investorDashboardFailed(state: CorporateDashboardState = initialState, data: string): CorporateDashboardState {
  return {
    ...state,
    corporateDashboard: null,
    corporateDashboardLoading: false,
    corporateDashboardError: data
  }
}

function initCorporateProfileCompleteness(state: CorporateDashboardState = initialState): CorporateDashboardState {
  return {
    ...state,
    corporateProfileCompletenessLoading: true,
    corporateProfileCompletenessError: ""
  };
}

function corporateProfileCompletenessSuccess(state: CorporateDashboardState = initialState, data: CorporateProfileCompleteness): CorporateDashboardState {
  return {
    ...state,
    corporateProfileCompleteness: data,
    corporateProfileCompletenessLoading: false,
    corporateProfileCompletenessError: ""
  }
}

function corporateProfileCompletenessFailed(state: CorporateDashboardState = initialState, data: string): CorporateDashboardState {
  return {
    ...state,
    corporateProfileCompleteness: null,
    corporateProfileCompletenessLoading: false,
    corporateProfileCompletenessError: data
  }
}


function initRequestApproval(state: CorporateDashboardState = initialState): CorporateDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: CorporateDashboardState = initialState, data: string): CorporateDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: CorporateDashboardState = initialState, data: string): CorporateDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function corporateDashboardReducer(state: CorporateDashboardState = initialState, action: CorporateDashBoardActions): CorporateDashboardState {
  switch (action.type) {

    case CorporateDashboardActionTypes.GetCorporateDashboard:
      return initInvestorDashboard(state);

    case CorporateDashboardActionTypes.GetCorporateDashboardSuccess:
      return investorDashboardSuccess(state, action.payload);

    case CorporateDashboardActionTypes.GetCorporateDashboardFault:
      return investorDashboardFailed(state, action.payload);


    case CorporateDashboardActionTypes.GetProfileCompleteness:
      return initCorporateProfileCompleteness(state);

    case CorporateDashboardActionTypes.GetProfileCompletenessSuccess:
      return corporateProfileCompletenessSuccess(state, action.payload);

    case CorporateDashboardActionTypes.GetProfileCompletenessFault:
      return corporateProfileCompletenessFailed(state, action.payload);


    case CorporateDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case CorporateDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case CorporateDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
