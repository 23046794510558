import { createFeatureSelector, createSelector } from "@ngrx/store";
import { InvestorsOrgInfoState } from "./investors-organization-info.reducer";

export const selectInvestorsOrgState = createFeatureSelector<InvestorsOrgInfoState>("investorsOrg");


export const getInvestorsOrg = createSelector(
    selectInvestorsOrgState,
    (state: InvestorsOrgInfoState) => state.investorsOrg
);

