import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { StartupDashboardService } from "../../service";
import { StartupDashboardActionTypes } from "./startup.dashboard.action";
import * as StartupDashBoardActions from "./startup.dashboard.action";
import { Approval, StartupProfileCompleteness, StartupInvestorDashboardResponse, StartupInvestorProfileCompletenessResponse } from "../../domain";


@Injectable()
export class StartupDashboardEffect {

    getStartUpDashboard$ = createEffect(() => this.actions$.pipe(
        ofType<StartupDashBoardActions.GetStartupDashboard>(StartupDashboardActionTypes.GetStartupDashboard),
        map((action: StartupDashBoardActions.GetStartupDashboard) => action),
        exhaustMap(() => this.startupDashboardService.getStartUpDashboard().pipe(
            mergeMap((data: StartupInvestorDashboardResponse) => [
                new StartupDashBoardActions.GetStartupDashboardSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new StartupDashBoardActions.GetStartupDashboardFault(err.error.message)))
        ))
    ));


    getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
      ofType<StartupDashBoardActions.GetProfileCompleteness>(StartupDashboardActionTypes.GetProfileCompleteness),
      map((action: StartupDashBoardActions.GetProfileCompleteness) => action),
      exhaustMap(() => this.startupDashboardService.getStartUpCompleteness().pipe(
          mergeMap((data: StartupProfileCompleteness) => [
              new StartupDashBoardActions.GetProfileCompletenessSuccess(data),
          ]),
          catchError((err: HttpErrorResponse) => of(new StartupDashBoardActions.GetProfileCompletenessFault(err.error.message)))
      ))
    ));

    sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
        ofType<StartupDashBoardActions.SendRequestApproval>(StartupDashboardActionTypes.SendRequestApproval),
        map((action: StartupDashBoardActions.SendRequestApproval) => action.payload),
        exhaustMap((approvalRequest: Approval) => this.startupDashboardService.sendApprovalRequest(approvalRequest).pipe(
            mergeMap((data: string) => [
                new StartupDashBoardActions.SendRequestApprovalSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new StartupDashBoardActions.SendRequestApprovalFault(err.error.message)))
        )),
    ));

    raisingFunds$ = createEffect(() => this.actions$.pipe(
        ofType<StartupDashBoardActions.RasingFundRequest>(StartupDashboardActionTypes.RasingFundRequest),
        map((action: StartupDashBoardActions.RasingFundRequest) => action),
        exhaustMap(() => this.startupDashboardService.raisingFunds().pipe(
            mergeMap((data: string) => [
                new StartupDashBoardActions.RasingFundRequestSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new StartupDashBoardActions.RasingFundRequestFault(err.error.message)))
        )),
    ));

    constructor(
        private actions$: Actions,
        private startupDashboardService: StartupDashboardService
    ) { }
}
