import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import { IJobs } from "../domain/jobs.model";
import * as JobsAction from 'src/app/core/state/jobs';
import * as ChallengesAction from 'src/app/core/state/challenges';
import { IChallenges, IParticipants } from "../domain/challenges.model";
import { Router } from "@angular/router";

@Injectable({
    providedIn: "root"
})
export class ChallengeService {

    constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store,
      private router: Router) { }


    public getChallengesList(action): Observable<any> {
        const filters = action.filters || {}
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE) + 'corporate';
        return this.http.get(url, { params: filters }).pipe(
            map((response: any): IJobs[] => {
                return response?.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`Jobs( ${fault.error.message} )`);
                this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
                return throwError(() => fault);
            })
        )
    }

    public completeHiringProfile(payload: {
        companySize?: string,
        fundingStatus?: string
    }): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMPLETE_HIRING_PROFILE);
        return this.http.patch(url, payload).pipe(
            tap(() => {
                this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
                this.store.dispatch(new StartupActions.GetStartUpInfo);
            }),
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating completing hiring profile'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public createChallenge(payload): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE);
        return this.http.post(url, payload).pipe(
            tap(() => {

            }),
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while creating challenge'), 'error');
                return throwError(() => fault);
            })
        );
    }


    public updateChallenge(id, payload): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE + `${id}`);
        return this.http.patch(url, payload).pipe(
            tap(() => {
                this.store.dispatch(new ChallengesAction.GetChallengesList());
            }),
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating challenge'), 'error');
                return throwError(() => fault);
            })
        );
    }




    public getPublicChallenges(action, loggedIn= false): Observable<any> {
      const filters = action.filters || {}
      let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_CHALLENGES);
      if(loggedIn) {
        url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_PRIVATE_CHALLENGES);
      }
      return this.http.get(url, { params: filters }).pipe(
          map((response: any): IJobs[] => {
              return response?.data;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(fault?.error?.message || 'Error while fetching challenges', 'error');
              return throwError(() => fault);
          })
      )
    }

    public getPublicChallengeDetails(uuid, isLoggedIn = false): Observable<any> {
      let url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGES_PUBLIC_DETAILS) + `/${uuid}`;
      if(isLoggedIn) {
        url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE) + `${uuid}/necessary-info`;
      }
      return this.http.get(url).pipe(
          map((response: any): IJobs[] => {
              return response?.data;
          }),
          catchError((fault: HttpErrorResponse) => {
              console.warn(`Jobs( ${fault.error.message} )`);
              this.toastService.showToast(fault?.error?.message || 'Error while fetching challenges', 'error');
              if(fault.status === 404) {
                this.router.navigate(['/search/challenges'])
              }
              return throwError(() => fault);
          })
      )
    }


    public applyForChallenge(id, payload): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE_PARTICIPANTS + `${id}/apply`);
      return this.http.post(url, payload).pipe(
          map((response: any): any => {
              return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for challenge'), 'error');
              return throwError(() => fault);
          })
      );
  }


  public fetchChellengeDetails(jobUUID: string): Observable<IChallenges> {
    const url =
      ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE) +
      jobUUID + '/info';

    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(
          fault?.error?.message || 'Error while fetching Chellenge details',
          'error'
        );
        return throwError(() => fault);
      })
    );
  }

  public fetchChallengeParticipants(jobUUID: string): Observable<IParticipants[]> {
    const url =
      ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.CHALLENGE) +
      jobUUID + '/participants/list';

    return this.http.get(url).pipe(
      map((response: any) => {
        return response?.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`Jobs( ${fault.error.message} )`);
        this.toastService.showToast(
          fault?.error?.message || 'Error while fetching Chellenge details',
          'error'
        );
        return throwError(() => fault);
      })
    );
  }

}
