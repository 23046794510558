<div id="mySidenav" class="sidenav p-lg-0 drawer drawer-start drawer-on">

  <div
    class="menu menu-column flex-nowrap menu-rounded menu-lg-row menu-title-gray-500 menu-state-title-primary nav nav-flush fs-5 "
    id="kt_landing_menu">
  <app-public-layout-sidebar></app-public-layout-sidebar>
    <!-- <div class="menu-item" *ngFor="let menu of menuList">
      <ng-template [ngIf]="menu.haveExternalLink" [ngIfElse]="linkDiv">
        <a class="menu-link nav-link py-3 px-4 px-xxl-6" [href]="menu.link" data-kt-scroll-toggle="true"
          data-kt-drawer-dismiss="true">{{menu.menu}}</a>
      </ng-template>

      <ng-template #linkDiv>
        <ng-template [ngIf]="!menu.haveSubmenu" [ngIfElse]="subMenuDiv">
          <a class="menu-link nav-link py-3 px-4 px-xxl-6" [routerLink]="'/'+menu.link"
            [routerLinkActive]=" 'active'"
            data-kt-scroll-toggle="true" data-kt-drawer-dismiss="true"
            (click)="closeNav()">{{menu.menu}}</a>
        </ng-template>
        <ng-template #subMenuDiv>

          <div ngbDropdown class="menu-item">

            <a ngbDropdownToggle class="menu-link nav-link py-3 px-4 px-xxl-6" data-kt-scroll-toggle="true"
              data-kt-drawer-dismiss="true">{{menu.menu}}</a>



            <div ngbDropdownMenu
              class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-bold w-250px py-3">

              <div class="menu-item px-3" *ngFor="let subMenu of menu.subMenuList">
                <ng-template [ngIf]="subMenu.haveExternalLink" [ngIfElse]="subMenuLinkDiv">
                  <div class="separator mt-3 opacity-75"></div>
                  <a class="menu-link px-3" [href]="subMenu.link" data-kt-scroll-toggle="true"
                    data-kt-drawer-dismiss="true">{{subMenu.menu}}</a>
                </ng-template>

                <ng-template #subMenuLinkDiv>
                  <a [routerLink]="subMenu.link" routerLinkActive="active" ngbDropdownItem class="menu-link px-3"
                    (click)="closeNav()">
                    {{subMenu.menu}}
                  </a>
                </ng-template>
              </div>

            </div>
          </div>

        </ng-template>
      </ng-template>



    </div> -->

  </div>

</div>
