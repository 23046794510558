import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  of,
  throwError
} from "rxjs";
import {
  catchError, map, tap,
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IFormattedResult, IPitchDeck } from "../domain/pitch-deck-recorder.model";
import { ApiEndpointService } from "./api-endpoint.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import { Store } from "@ngrx/store";
import * as PitchActions from "src/app/core/state/pitch-deck";
import { PitchDocumentTypes } from "../domain/pitch-deck.model";


@Injectable({
  providedIn: "root"
})
export class PitchDeckService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, private store: Store) { }

  public getPitchInfo(): Observable<IPitchDeck> {

    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.LOAD_PITCH_DECK);
    return this.http.get(url).pipe(
        map((response: any) => {
            return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
            this.toastService.showToast(this.getErrorMessage(fault, 'Error while fetching PitchFile!'), 'error');
            console.warn(`loginFault( ${fault.error.message} )`);
            return throwError(() => fault);
        })
    );
}

  public uploadPitchFile(file: File,categoryId:number, topicId :number, videoTime:number): Observable<any> {
    const payload = new FormData();
    payload.append('file', file);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.UPLOAD_PITCH_DECK)+"/"+categoryId+"/"+topicId+"/"+videoTime;
    console.info(`upload PitchFile info`, payload);
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`update PitchFile Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while saving PitchFile!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public updatePitchScript(categoryId:number, topicId :number, script:string): Observable<any> {
    let payload={
      script:script
    }
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.UPDATE_PITCH_DECK_SCRIPT)+"/"+categoryId+"/"+topicId;
    return this.http.post(url, payload).pipe(
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while saving script!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  public compile(categoryId:number, documentType: PitchDocumentTypes = PitchDocumentTypes.FUNDRAISING_PITCH): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMPILE_PITCH_DECK)+"/"+categoryId + `/${documentType}`;
    console.info(`compile PitchFile`);
    return this.http.post(url,{}).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`compile PitchFile Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while compiling Pitch!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteAll(categoryId:number): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.BULK_DELETE_PITCH_DECK)+"/"+categoryId;
    console.info(`delete all PitchFile`);
    return this.http.delete(url).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
        this.store.dispatch(new PitchActions.GetPitchDeck);
      }),
      map((response: any): any => {

        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`delete PitchFile Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while deleting Pitch!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public delete(categoryId:number,topicId:number): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.DELETE_PITCH_DECK)+"/"+categoryId+"/"+topicId;
    console.info(`delete single PitchFile`);
    return this.http.delete(url).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
        this.store.dispatch(new PitchActions.GetPitchDeck);
      }),
      map((response: any): any => {
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`delete PitchFile Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while deleting Pitch!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public getStartupPitchByUUID(uuid:string): Observable<IFormattedResult[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.LOAD_PITCH_DECK_STARTUP)+"/"+uuid;
    return this.http.get(url).pipe(
        map((response: any) => {
            return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
            this.toastService.showToast(this.getErrorMessage(fault, 'Error while fetching PitchFile!'), 'error');
            console.warn(`loginFault( ${fault.error.message} )`);
            return throwError(() => fault);
        })
    );
}


  getErrorMessage(fault, defaultMsg: string = 'Something went wrong') {
    let msg;
    if (fault?.error?.error) {
      msg = Array.isArray(fault?.error?.error) ? fault?.error?.error?.[0] : fault?.error?.message;
    }
    return msg || defaultMsg;
  }
}
