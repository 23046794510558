import { IInvestorsCompareResponse } from "../../domain/startup-investor-compare.model";
import { InvestorCompareActions, InvestorCompareActionTypes } from "./investor.compare.action";

export interface InvestorCompareState {
    payload: IInvestorsCompareResponse;
    investorCompareLoading: boolean;
    investorCompareError: string;
}

export const initialState: InvestorCompareState = {
    payload: null,
    investorCompareLoading: false,
    investorCompareError: ""
}

function initInvestorCompare(state: InvestorCompareState = initialState): InvestorCompareState {
    return {
        ...state,
        investorCompareLoading: true,
        investorCompareError: ""
    };
}

function investorCompareSuccess(state: InvestorCompareState = initialState, data: IInvestorsCompareResponse): InvestorCompareState {
    return {
        ...state,
        payload: data,
        investorCompareLoading: false,
        investorCompareError: ""
    }
}

function investorCompareFailed(state: InvestorCompareState = initialState, data: string): InvestorCompareState {
    return {
        ...state,
        payload: null,
        investorCompareLoading: false,
        investorCompareError: data
    }
}

export function investorCompareReducer(state: InvestorCompareState = initialState, action: InvestorCompareActions): InvestorCompareState {
    switch (action.type) {

        case InvestorCompareActionTypes.GetInvestorCompare:
            return initInvestorCompare(state);

        case InvestorCompareActionTypes.GetInvestorCompareSuccess:
            return investorCompareSuccess(state, action.payload);

        case InvestorCompareActionTypes.GetInvestorCompareFault:
            return investorCompareFailed(state, action.payload);

        default:
            return state;
    }
}
