import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import '@angular/compiler';


if (environment.production) {
  enableProdMode();
  if (window) {
    window['log'] = console.log;
    window['error'] = console.error;
    window['debug'] = console.debug;
    window['info'] = console.debug;
  }

  console.log = console.debug = console.error = console.info = () => { };
}




// platformBrowserDynamic().bootstrapModule(AppModule)
//   .catch(err => console.error(err));


// const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
// // CometChat.CallSettings()
// CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {

//   console.log('app is ready to work');

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
// }, (error) => {
//   console.log('Error In Init', error);
// });


