import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError, map, tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IOnGoingCommitments } from "../domain/financial-details.model";
import { IFoundersInfo } from "../domain/startup.model";
import { ApiEndpointService } from "./api-endpoint.service";

@Injectable({
  providedIn: "root"
})
export class CommitmentsService {

  constructor(private http: HttpClient, private toastService: ToastAlertService) { }


  public getOnGoingCommitments(): Observable<IOnGoingCommitments[]> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ONGOING_COMMITMENTS);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`OnGoingCommitments GET ( Received startupinfo `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while fetching commitments!'), 'error');
        console.warn(`OnGoingCommitments Fault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public saveCommitment(payload: IOnGoingCommitments): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ONGOING_COMMITMENTS);
    console.info(`saving Commitments info`, payload);
    return this.http.post(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`save Commitments( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while saving commitments!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public updateCommitment(id: string, payload: IOnGoingCommitments): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ONGOING_COMMITMENTS) + `/${id}`;
    console.info(`update Commitments info`, payload);
    return this.http.patch(url, payload).pipe(
      catchError((fault: HttpErrorResponse) => {
        console.warn(`update Commitments Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while saving commitments!'), 'error');
        return throwError(() => fault);
      })
    );
  }

  public deleteCommitments(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ONGOING_COMMITMENTS) + `/${id}`;
    console.info(`delete Commitments info with id: `, id);
    return this.http.delete(url).pipe(
      // tap(() => { }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`delete Commitments Info( ${fault?.error?.message} )`);
        this.toastService.showToast(this.getErrorMessage(fault, 'Error while deleting commitments!'), 'error');
        return throwError(() => fault);
      })
    );
  }


  getErrorMessage(fault, defaultMsg: string = 'Something went wrong') {
    let msg;
    if (fault?.error?.error) {
      msg = Array.isArray(fault?.error?.error) ? fault?.error?.error?.[0] : fault?.error?.message;
    }
    return msg || defaultMsg;
  }
}
