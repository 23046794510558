import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IOnGoingCommitments } from "../../domain/financial-details.model";
import { IFoundersInfo, IStartUpInfoBase, IStartUpInfoFetch } from "../../domain/startup.model";
import { AdvisoryBoardService } from "../../service/advisoryBoard.service";
import { CommitmentsService } from "../../service/commitments.service";
import { FoundersService } from "../../service/founders.service";
import { StartUpService } from "../../service/startup.service";
import * as StartUpActions from "./startup.action";
import { StartUpActionTypes } from "./startup.action";

@Injectable()
export class StartUpEffect {
  /**
   * Attempt to save startup.
   */
  saveStartUpInfo$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpActions.SaveStartUpInfo>(StartUpActionTypes.SaveStartUpInfo),
    map((action: StartUpActions.SaveStartUpInfo) => action.payload),
    exhaustMap((payload: IStartUpInfoBase) =>
      this.startUpService.saveStartUpInfo(payload).pipe(
        map((data) => data.data),
        mergeMap((data: IStartUpInfoFetch) => [
          new StartUpActions.SaveStartUpInfoSuccess(data),
        ]),
        tap(() => this.toastAlertService.showToast("Start Up Information Updated")),
        catchError((err: HttpErrorResponse) => of(new StartUpActions.SaveStartUpInfoFault(err.error.message)))
      )
    )
  ));

  getStartUpInfo$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpActions.GetStartUpInfo>(StartUpActionTypes.GetStartUpInfo),
    exhaustMap(() =>
      this.startUpService.getStartUpInfo().pipe(
        map((data: IStartUpInfoFetch) => new StartUpActions.GetStartUpInfoSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new StartUpActions.GetStartUpInfoFault(err.error.message)))
      )
    )
  ));

  getFounders$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpActions.GetFounders>(StartUpActionTypes.GetFounders),
    exhaustMap(() =>
      this.foundersService.getFounders().pipe(
        map((data: IFoundersInfo[]) => new StartUpActions.GetFoundersSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new StartUpActions.GetFoundersFault(err.error.message)))
      )
    )
  ));


  getAdvisoryBoard$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpActions.GetAdvisoryBoard>(StartUpActionTypes.GetAdvisoryBoard),
    exhaustMap(() =>
      this.advisoryBoardService.getAdvisoryBoard().pipe(
        map((data: IFoundersInfo[]) => new StartUpActions.GetAdvisoryBoardSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new StartUpActions.GetAdvisoryBoardFault(err.error.message)))
      )
    )
  ));

  getCommitments$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpActions.GetCommitments>(StartUpActionTypes.GetCommitments),
    exhaustMap(() =>
      this.commitmentsService.getOnGoingCommitments().pipe(
        map((data: IOnGoingCommitments[]) => new StartUpActions.GetCommitmentsSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new StartUpActions.GetCommitmentsFault(err.error.message)))
      )
    )
  ));

  constructor(
    private actions$: Actions,
    private startUpService: StartUpService,
    private toastAlertService: ToastAlertService,
    private router: Router,
    private readonly foundersService: FoundersService,
    private readonly advisoryBoardService: AdvisoryBoardService,
    private readonly commitmentsService: CommitmentsService,
  ) { }
}
