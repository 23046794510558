import {  GlossaryModel } from "../../domain";
import {
    GlossaryActions,
    GlossaryActionTypes
} from "./glossary.action";

export interface GlossaryState {
    glossary: GlossaryModel,
    error: string | null
 }

export const initialState: GlossaryState = {
  glossary: null,
    error:""

};

function glossarySuccess(state: GlossaryState = initialState, data: GlossaryModel): GlossaryState {
    return {
        ...state,
        glossary: data,
        error:""
    };
}

function glossaryFault(state: GlossaryState = initialState, data: string): GlossaryState {
    return {
        ...state,
        glossary:null,
        error:data

    };
}

export function glossaryReducer(state: GlossaryState = initialState, action: GlossaryActions): GlossaryState {
    switch (action.type) {
            case GlossaryActionTypes.GlossarySuccess:
              return glossarySuccess(state, action.payload);
            case GlossaryActionTypes.GlossaryFault:
              return glossaryFault(state, action.payload);
        default:
            return state;
    }
}


