import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { catchError, exhaustMap, map, mergeMap, of } from "rxjs";
import { RegisterModel, RegisterResponsePayload, SignupCredentials, SingUpSendOtpResponse, SingUpVerifyOtpResponse } from "../../domain";
import { SignUpService } from "../../service";
import * as SignUpActions from "./sign-up.action";
import { SignUpActionTypes } from "./sign-up.action";

@Injectable()
export class SignUpEffect {


    sendOTP$ = createEffect(() => this.actions$.pipe(
        ofType<SignUpActions.SendOtp>(SignUpActionTypes.SendOtp),
        map((action: SignUpActions.SendOtp) => action.payload),
        exhaustMap((signUpCred: SignupCredentials) =>
            this.signUpService.sendOTP(signUpCred).pipe(
                mergeMap((data: SingUpSendOtpResponse) => [
                    new SignUpActions.SendOtpSuccess(data),
                ]),
                catchError((err: HttpErrorResponse) => of(new SignUpActions.SendOtpFault(err.error.message)))
            )
        )
    ))

    verifyOTP$ = createEffect(() => this.actions$.pipe(
        ofType<SignUpActions.VerifyOtp>(SignUpActionTypes.VerifyOtp),
        map((action: SignUpActions.VerifyOtp) => action.payload),
        exhaustMap((signUpCred: SignupCredentials) =>
            this.signUpService.verifyOTP(signUpCred).pipe(
                mergeMap((data: SingUpVerifyOtpResponse) => [
                    new SignUpActions.VerifyOtpSuccess(data),
                ]),
                catchError((err: HttpErrorResponse) => of(new SignUpActions.VerifyOtpFault(err.error.message)))
            )
        )
    ))

    register$ = createEffect(() => this.actions$.pipe(
        ofType<SignUpActions.Register>(SignUpActionTypes.Register),
        map((action: SignUpActions.Register) => action.payload),
        exhaustMap((userData: RegisterModel) =>
            this.signUpService.registerUser(userData).pipe(
                mergeMap((data: RegisterResponsePayload) => [
                    new SignUpActions.RegisterSuccess(data),
                ]),
                catchError((err: HttpErrorResponse) => of(new SignUpActions.RegisterFault(err.error?.error?.[0] || err.error.message)))
            )
        )
    ))


    constructor(private actions$: Actions, private store$: Store<any>, private signUpService: SignUpService) {
    }
}
