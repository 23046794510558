import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MentorInfoState } from "./mentor-info.reducer";

export const selectMentorState = createFeatureSelector<MentorInfoState>("mentor");

export const getMentorInfo = createSelector(
  selectMentorState,
  (state: MentorInfoState) => state.mentor
);

