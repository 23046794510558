import { IStartupCompareResponse } from "../../domain/startup-investor-compare.model";
import { StartupCompareActions, StartupCompareActionTypes } from "./startup.compare.action";

export interface StartupCompareState {
    payload: IStartupCompareResponse;
    startupCompareLoading: boolean;
    startupCompareError: string;
}

export const initialState: StartupCompareState = {
    payload: null,
    startupCompareLoading: false,
    startupCompareError: ""
}

function initStartupCompare(state: StartupCompareState = initialState): StartupCompareState {
    return {
        ...state,
        startupCompareLoading: true,
        startupCompareError: ""
    };
}

function startupCompareSuccess(state: StartupCompareState = initialState, data: IStartupCompareResponse): StartupCompareState {
    return {
        ...state,
        payload: data,
        startupCompareLoading: false,
        startupCompareError: ""
    }
}

function startupCompareFailed(state: StartupCompareState = initialState, data: string): StartupCompareState {
    return {
        ...state,
        payload: null,
        startupCompareLoading: false,
        startupCompareError: data
    }
}

export function startUpCompareReducer(state: StartupCompareState = initialState, action: StartupCompareActions): StartupCompareState {
    switch (action.type) {

        case StartupCompareActionTypes.GetStartupCompare:
            return initStartupCompare(state);

        case StartupCompareActionTypes.GetStartupCompareSuccess:
            return startupCompareSuccess(state, action.payload);

        case StartupCompareActionTypes.GetStartupCompareFault:
            return startupCompareFailed(state, action.payload);

        default:
            return state;
    }
}
