import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum InvestorDashboardActionTypes {
    // Investor Dashboard
    GetInvestorDashboard = "[Investor Dashboard] GetInvestorDashboard",
    GetInvestorDashboardSuccess = "[Investor Dashboard] GetInvestorDashboardSuccess",
    GetInvestorDashboardFault = "[Investor Dashboard] GetInvestorDashboardFault",

    // Request Approval
    SendRequestApproval = '[Investor Dashboard] SendRequestApproval',
    SendRequestApprovalSuccess = "[Investor Dashboard] SendRequestApprovalSuccess",
    SendRequestApprovalFault = "[Investor Dashboard] SendRequestApprovalFault",

    // Rasing Fund
    ProvidingFundRequest = "[Investor Dashboard] ProvidingFundRequest",
    ProvidingFundRequestSuccess = "[Investor Dashboard] ProvidingFundRequestSuccess",
    ProvidingFundRequestFault = "[Investor Dashboard] ProvidingFundRequestFault",

    GetProfileCompleteness = "[Investor Dashboard] GetProfileCompleteness",
    GetProfileCompletenessSuccess = "[Investor Dashboard] GetProfileCompletenessSuccess",
    GetProfileCompletenessFault = "[Investor Dashboard] GetProfileCompletenessFault",

    GetIndividualProfileCompleteness = "[Investor Dashboard] GetIndividualInvestorProfileCompleteness",
    GetIndividualProfileCompletenessSuccess = "[Investor Dashboard] GetIndividualProfileCompletenessSuccess",
    GetIndividualProfileCompletenessFault = "[Investor Dashboard] GetIndividualProfileCompletenessFault",

}

export class GetInvestorDashboard implements Action {
    readonly type = InvestorDashboardActionTypes.GetInvestorDashboard;
}

export class GetInvestorDashboardSuccess implements Action {
    readonly type = InvestorDashboardActionTypes.GetInvestorDashboardSuccess;
    constructor(public payload: any) { }
}

export class GetInvestorDashboardFault implements Action {
    readonly type = InvestorDashboardActionTypes.GetInvestorDashboardFault;
    constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
    readonly type = InvestorDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
    readonly type = InvestorDashboardActionTypes.SendRequestApprovalSuccess;
    constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
    readonly type = InvestorDashboardActionTypes.SendRequestApprovalFault;
    constructor(public payload: string) { }
}

export class ProvidingFundRequest implements Action {
    readonly type = InvestorDashboardActionTypes.ProvidingFundRequest;
}

export class ProvidingFundRequestSuccess implements Action {
    readonly type = InvestorDashboardActionTypes.ProvidingFundRequestSuccess;
    constructor(public payload: string) { }
}

export class ProvidingFundRequestFault implements Action {
    readonly type = InvestorDashboardActionTypes.ProvidingFundRequestFault;
    constructor(public payload: string) { }
}




export class GetProfileCompleteness implements Action {
  readonly type = InvestorDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = InvestorDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = InvestorDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}


export class GetIndividualProfileCompleteness implements Action {
  readonly type = InvestorDashboardActionTypes.GetIndividualProfileCompleteness;
}

export class GetIndividualProfileCompletenessSuccess implements Action {
  readonly type = InvestorDashboardActionTypes.GetIndividualProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetIndividualProfileCompletenessFault implements Action {
  readonly type = InvestorDashboardActionTypes.GetIndividualProfileCompletenessFault;
  constructor(public payload: string) { }
}


export type InvestorDashBoardActions =
    | GetInvestorDashboard
    | GetInvestorDashboardSuccess
    | GetInvestorDashboardFault
    | SendRequestApproval
    | SendRequestApprovalSuccess
    | SendRequestApprovalFault
    | ProvidingFundRequest
    | ProvidingFundRequestSuccess
    | ProvidingFundRequestFault
    | GetProfileCompletenessFault
    | GetProfileCompletenessSuccess
    | GetProfileCompleteness
    | GetIndividualProfileCompletenessFault
    | GetIndividualProfileCompletenessSuccess
    | GetIndividualProfileCompleteness;
