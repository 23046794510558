import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  Auth,
  FaqModel,
  VerifyOtpCredentials
} from "../../domain";
import { FaqService } from "../../service/faq.service";
import * as FaqActions from "./faq.action";
import { FaqActionTypes } from "./faq.action";

@Injectable()
export class FaqEffect {

  getFaq$ = createEffect(() => this.actions$.pipe(
    ofType<FaqActions.GetFaq>(FaqActionTypes.GetFaq),
    exhaustMap(() =>
      this.faqService.getAllFaq().pipe(
        map((data: FaqModel[]) => new FaqActions.FaqSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new FaqActions.FaqFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private faqService: FaqService) {
  }
}
