import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum ServiceProviderDashboardActionTypes {
  GetProfileCompleteness = "[ServiceProvider Dashboard] GetServiceProviderProfileCompleteness",
  GetProfileCompletenessSuccess = "[ServiceProvider Dashboard] GetServiceProviderProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[ServiceProvider Dashboard] GetServiceProviderProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[ServiceProvider Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[ServiceProvider Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[ServiceProvider Dashboard] SendRequestApprovalFault",

  GetServiceProviderDashboard = "[ServiceProvider Dashboard] GetServiceProviderDashboard",
  GetServiceProviderDashboardSuccess = "[ServiceProvider Dashboard] GetServiceProviderDashboardSuccess",
  GetServiceProviderDashboardFault = "[ServiceProvider Dashboard] GetServiceProviderDashboardFault",

}


export class GetServiceProviderDashboard implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetServiceProviderDashboard;
}

export class GetServiceProviderDashboardSuccess implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetServiceProviderDashboardSuccess;
  constructor(public payload: any) { }
}

export class GetServiceProviderDashboardFault implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetServiceProviderDashboardFault;
  constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = ServiceProviderDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = ServiceProviderDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = ServiceProviderDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = ServiceProviderDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type ServiceProviderDashBoardActions =
  GetServiceProviderDashboard
  | GetServiceProviderDashboardSuccess
  | GetServiceProviderDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
