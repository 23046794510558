import { PartnerProfileCompleteness } from "../../domain";
import { PartnerDashBoardActions, PartnerDashboardActionTypes } from "./partner.dashboard.action";

export interface PartnerDashboardState {
  partnerDashboard: any;
  partnerDashboardLoading: boolean;
  partnerDashboardError: string | null | string[];

  partnerProfileCompleteness: PartnerProfileCompleteness;
  partnerProfileCompletenessLoading: boolean;
  partnerProfileCompletenessError: string | null | string[];

  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;
}

export const initialState: PartnerDashboardState = {
  partnerDashboard: null,
  partnerDashboardLoading: false,
  partnerDashboardError: "",


  partnerProfileCompleteness: null,
  partnerProfileCompletenessLoading: false,
  partnerProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initPartnerDashboard(state: PartnerDashboardState = initialState): PartnerDashboardState {
  return {
    ...state,
    partnerDashboardLoading: true,
    partnerDashboardError: ""
  };
}

function serviceProviderDashboardSuccess(state: PartnerDashboardState = initialState, data: any): PartnerDashboardState {
  return {
    ...state,
    partnerDashboard: data,
    partnerDashboardLoading: false,
    partnerDashboardError: ""
  }
}

function partnerDashboardFailed(state: PartnerDashboardState = initialState, data: string): PartnerDashboardState {
  return {
    ...state,
    partnerDashboard: null,
    partnerDashboardLoading: false,
    partnerDashboardError: data
  }
}

function initPartnerProfileCompleteness(state: PartnerDashboardState = initialState): PartnerDashboardState {
  return {
    ...state,
    partnerProfileCompletenessLoading: true,
    partnerProfileCompletenessError: ""
  };
}

function partnerProfileCompletenessSuccess(state: PartnerDashboardState = initialState, data: PartnerProfileCompleteness): PartnerDashboardState {
  return {
    ...state,
    partnerProfileCompleteness: data,
    partnerProfileCompletenessLoading: false,
    partnerProfileCompletenessError: ""
  }
}

function partnerProfileCompletenessFailed(state: PartnerDashboardState = initialState, data: string): PartnerDashboardState {
  return {
    ...state,
    partnerProfileCompleteness: null,
    partnerProfileCompletenessLoading: false,
    partnerProfileCompletenessError: data
  }
}


function initRequestApproval(state: PartnerDashboardState = initialState): PartnerDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: PartnerDashboardState = initialState, data: string): PartnerDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: PartnerDashboardState = initialState, data: string): PartnerDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function partnerDashboardReducer(state: PartnerDashboardState = initialState, action: PartnerDashBoardActions): PartnerDashboardState {
  switch (action.type) {

    case PartnerDashboardActionTypes.GetPartnerDashboard:
      return initPartnerDashboard(state);

    case PartnerDashboardActionTypes.GetPartnerDashboardSuccess:
      return serviceProviderDashboardSuccess(state, action.payload);

    case PartnerDashboardActionTypes.GetPartnerDashboardFault:
      return partnerDashboardFailed(state, action.payload);


    case PartnerDashboardActionTypes.GetProfileCompleteness:
      return initPartnerProfileCompleteness(state);

    case PartnerDashboardActionTypes.GetProfileCompletenessSuccess:
      return partnerProfileCompletenessSuccess(state, action.payload);

    case PartnerDashboardActionTypes.GetProfileCompletenessFault:
      return partnerProfileCompletenessFailed(state, action.payload);


    case PartnerDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case PartnerDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case PartnerDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
