import { StartupInvestorDashboardResponse } from "../../domain/startup-investor-dashboard.model";
import { IOnGoingCommitments } from "../../domain/financial-details.model";
import { IFoundersInfo, IStartUpInfoFetch } from "../../domain/startup.model";
import {
  StartUpActions,
  StartUpActionTypes
} from "./startup.action";

export interface StartUpState {
  startupInfo: IStartUpInfoFetch | null;
  pending: boolean;
  error: string | null;

  founders: IFoundersInfo[];
  founderLoading: boolean;
  founderError: string | null | string[];

  advisoryBoard: IFoundersInfo[];
  advisoryBoardLoading: boolean;
  advisoryBoardError: string | null | string[];

  commitments: IOnGoingCommitments[];
  commitmentsLoading: boolean;
  commitmentsError: string | null | string[];
}

export const initialState: StartUpState = {
  startupInfo: null,
  pending: false,
  error: "",

  founders: [],
  founderLoading: false,
  founderError: '',

  advisoryBoard: [],
  advisoryBoardLoading: false,
  advisoryBoardError: '',

  commitments: [],
  commitmentsLoading: false,
  commitmentsError: '',
};

function initStartupInfoSave(state: StartUpState = initialState): StartUpState {
  return {
    ...state,
    pending: true,
    error: ""
  };
}

function startupInfoSaveSuccess(state: StartUpState = initialState, data: IStartUpInfoFetch): StartUpState {
  return {
    ...state,
    startupInfo: { ...state.startupInfo, ...data },
    pending: false,
    error: ""
  };
}

function startupInfoSaveFailed(state: StartUpState = initialState, data: string): StartUpState {
  return {
    ...state,
    startupInfo: null,
    pending: false,
    error: data
  };
}


function startupInfoGetSuccess(state: StartUpState = initialState, data: IStartUpInfoFetch): StartUpState {
  return {
    ...state,
    startupInfo: data,
    pending: false,
    error: ""
  };
}

function startupInfoGetFailed(state: StartUpState = initialState, data: string): StartUpState {
  return {
    ...state,
    startupInfo: null,
    pending: false,
    error: data
  };
}


function resetSaveStartUpInfoError(state: StartUpState = initialState): StartUpState {
  return {
    ...state,
    error: ""
  };
}


function initFounderInfoGet(state: StartUpState = initialState): StartUpState {
  return {
    ...state,
    founderLoading: true,
    founderError: ""
  };
}

function founderInfoGetSuccess(state: StartUpState = initialState, data: IFoundersInfo[]): StartUpState {
  console.log('founder =========>', data)
  return {
    ...state,
    founders: data,
    founderLoading: false,
    founderError: ""
  };
}

function founderInfoGetFailed(state: StartUpState = initialState, data: string): StartUpState {
  return {
    ...state,
    founders: [],
    founderLoading: false,
    founderError: data
  };
}




function initAdvisoryBoardInfoGet(state: StartUpState = initialState): StartUpState {
  return {
    ...state,
    advisoryBoardLoading: true,
    advisoryBoardError: ""
  };
}

function advisoryBoardInfoGetSuccess(state: StartUpState = initialState, data: IFoundersInfo[]): StartUpState {
  console.log('board =========>', data)
  return {
    ...state,
    advisoryBoard: data,
    advisoryBoardLoading: false,
    advisoryBoardError: ""
  };
}

function advisoryBoardInfoGetFailed(state: StartUpState = initialState, data: string): StartUpState {
  return {
    ...state,
    advisoryBoard: [],
    advisoryBoardLoading: false,
    advisoryBoardError: data
  };
}


function initCommitmentsGet(state: StartUpState = initialState): StartUpState {
  return {
    ...state,
    commitmentsLoading: true,
    commitmentsError: ""
  };
}

function commitmentsGetSuccess(state: StartUpState = initialState, data: IOnGoingCommitments[]): StartUpState {
  return {
    ...state,
    commitments: data,
    commitmentsLoading: false,
    commitmentsError: ""
  };
}

function commitmentsGetFailed(state: StartUpState = initialState, data: string): StartUpState {
  return {
    ...state,
    commitments: [],
    commitmentsLoading: false,
    commitmentsError: data
  };
}

export function startUpReducer(state: StartUpState = initialState, action: StartUpActions): StartUpState {
  switch (action.type) {

    case StartUpActionTypes.SaveStartUpInfo:
      return initStartupInfoSave(state);

    case StartUpActionTypes.SaveStartUpInfoSuccess:
      return startupInfoSaveSuccess(state, action.payload);

    case StartUpActionTypes.SaveStartUpInfoFault:
      return startupInfoSaveFailed(state, action.payload);


    case StartUpActionTypes.GetStartUpInfo:
      return initStartupInfoSave(state);

    case StartUpActionTypes.GetStartUpInfoSuccess:
      return startupInfoGetSuccess(state, action.payload);

    case StartUpActionTypes.GetStartUpInfoFault:
      return startupInfoGetFailed(state, action.payload);

    case StartUpActionTypes.ResetSaveStartUpInfoError:
      return resetSaveStartUpInfoError(state);


    case StartUpActionTypes.GetFounders:
      return initFounderInfoGet(state);

    case StartUpActionTypes.GetFoundersSuccess:
      return founderInfoGetSuccess(state, action.payload);

    case StartUpActionTypes.GetFoundersFault:
      return founderInfoGetFailed(state, action.payload);



    case StartUpActionTypes.GetAdvisoryBoard:
      return initAdvisoryBoardInfoGet(state);

    case StartUpActionTypes.GetAdvisoryBoardSuccess:
      return advisoryBoardInfoGetSuccess(state, action.payload);

    case StartUpActionTypes.GetAdvisoryBoardFault:
      return advisoryBoardInfoGetFailed(state, action.payload);



    case StartUpActionTypes.GetCommitments:
      return initCommitmentsGet(state);

    case StartUpActionTypes.GetCommitmentsSuccess:
      return commitmentsGetSuccess(state, action.payload);

    case StartUpActionTypes.GetCommitmentsFault:
      return commitmentsGetFailed(state, action.payload);

    default:
      return state;
  }
}


