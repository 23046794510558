import {
  CompareActions,
  CompareActionTypes
} from "./compare.action";

export interface CompareState {
  startupsToCompare: Array<string>,
  investorsToCompare: Array<string>,
  corporatesToCompare: Array<string>
}

export const initialState: CompareState = {
  startupsToCompare: [],
  investorsToCompare: [],
  corporatesToCompare: [],
};

function addStartUpToCompare(state: CompareState = initialState, id: string): CompareState {
  return {
    ...state,
    startupsToCompare: [...state.startupsToCompare, id],
  };
}

function removeStartUpFromCompareList(state: CompareState = initialState, id: string): CompareState {
  const startupsToCompare = state.startupsToCompare.filter((startupIds) => startupIds !== id);
  return {
    ...state,
    startupsToCompare: [...startupsToCompare],
  };
}

function resetStartUpFromCompareList(state: CompareState = initialState): CompareState {
  return {
    ...state,
    startupsToCompare: [...[]],
  };
}

function addInvestorsToCompare(state: CompareState = initialState, id: string): CompareState {
  return {
    ...state,
    investorsToCompare: [...state.investorsToCompare, id],
  };
}

function removeInvestorsFromCompareList(state: CompareState = initialState, id: string): CompareState {
  const investorsToCompare = state.investorsToCompare.filter((startupIds) => startupIds !== id);
  return {
    ...state,
    investorsToCompare: [...investorsToCompare],
  };
}

function resetInvestorsFromCompareList(state: CompareState = initialState): CompareState {
  return {
    ...state,
    investorsToCompare: [...[]],
  };
}



function addCorporatesToCompare(state: CompareState = initialState, id: string): CompareState {
  return {
    ...state,
    corporatesToCompare: [...state.corporatesToCompare, id],
  };
}

function removeCorporatesFromCompareList(state: CompareState = initialState, id: string): CompareState {
  const corporatesToCompare = state.corporatesToCompare.filter((startupIds) => startupIds !== id);
  return {
    ...state,
    corporatesToCompare: [...corporatesToCompare],
  };
}

function resetCorporatesFromCompareList(state: CompareState = initialState): CompareState {
  return {
    ...state,
    corporatesToCompare: [...[]],
  };
}

export function compareReducer(state: CompareState = initialState, action: CompareActions): CompareState {
  switch (action.type) {
    case CompareActionTypes.AddStartUpToCompare:
      return addStartUpToCompare(state, action.payload);
    case CompareActionTypes.RemoveStartUpFromCompareList:
      return removeStartUpFromCompareList(state, action.payload);
    case CompareActionTypes.ResetStartUpsToCompare:
      return resetStartUpFromCompareList(state);
    case CompareActionTypes.AddInvestorToCompare:
      return addInvestorsToCompare(state, action.payload);
    case CompareActionTypes.RemoveInvestorFromCompareList:
      return removeInvestorsFromCompareList(state, action.payload);
    case CompareActionTypes.ResetInvestorToCompare:
      return resetInvestorsFromCompareList(state);

    case CompareActionTypes.AddCorporateToCompare:
      return addCorporatesToCompare(state, action.payload);
    case CompareActionTypes.RemoveCorporateFromCompareList:
      return removeCorporatesFromCompareList(state, action.payload);
    case CompareActionTypes.ResetCorporateToCompare:
      return resetCorporatesFromCompareList(state);
    default:
      return state;
  }
}


