import { Action } from "@ngrx/store";
import { PartnersInformation } from "../../domain/partners.model";


export enum JobsActionTypes {
    // Partners Information List
    GetJobsList = "[Jobs] GetJobsList",
    GetJobsListSuccess = "[Jobs] GetJobsListSuccess",
    GetJobsListFault = "[Jobs] GetJobsListFault",
}

export class GetJobsList implements Action {
    readonly type = JobsActionTypes.GetJobsList;
    constructor(public filters?: any) { }
}

export class GetJobsListSuccess implements Action {
    readonly type = JobsActionTypes.GetJobsListSuccess;
    constructor(public payload: PartnersInformation) { }
}

export class GetJobsListFault implements Action {
    readonly type = JobsActionTypes.GetJobsListFault;
    constructor(public payload: string) { }
}


export type JobsAction =
    | GetJobsList
    | GetJobsListSuccess
    | GetJobsListFault;