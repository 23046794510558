import { Action } from "@ngrx/store";
import {
    OrganizationInformationResponseModel,
} from "../../domain";

export enum InvestorsOrgInfoActionTypes {
    GetInvestorsOrgInfo = "[InvestorsOrgInfo] GetInvestorsOrgInfo",
    InvestorsOrgInfoSuccess = "[InvestorsOrgInfo] InvestorsOrgInfoSuccess",
    InvestorsOrgInfoFault = "[InvestorsOrgInfo] InvestorsOrgInfoFaqFault",
  }

export class GetInvestorsOrgInfo implements Action {
    readonly type = InvestorsOrgInfoActionTypes.GetInvestorsOrgInfo;

    constructor() {
    }
}

export class InvestorsOrgInfoSuccess implements Action {
    readonly type = InvestorsOrgInfoActionTypes.InvestorsOrgInfoSuccess;

    constructor(public payload: OrganizationInformationResponseModel) {
    }
}

export class InvestorsOrgInfoFault implements Action {
    readonly type = InvestorsOrgInfoActionTypes.InvestorsOrgInfoFault;

    constructor(public payload: string) {
    }
}



export type InvestorsOrgInfoActionActions =
    | GetInvestorsOrgInfo
    | InvestorsOrgInfoSuccess
    | InvestorsOrgInfoFault
    ;
