import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  Auth,
  FaqModel,
  VerifyOtpCredentials
} from "../../domain";
import { FaqService } from "../../service/faq.service";
import * as FeedActions from "./feed.action";
import { FeedActionTypes } from "./feed.action";
import { CommunityFeedService } from "../../service/community-feed.service";

@Injectable()
export class FeedEffect {

  getFeedStats$ = createEffect(() => this.actions$.pipe(
    ofType<FeedActions.GetStats>(FeedActionTypes.GetStats),
    exhaustMap(() =>
      this.feedService.getStats().pipe(
        map((data) => new FeedActions.FeedStatsSuccess(data)),
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private feedService: CommunityFeedService) {
  }
}
