import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ServiceProviderInfoState } from "./service-provider-info.reducer";

export const selectMentorState = createFeatureSelector<ServiceProviderInfoState>("serviceProvider");

export const getServiceProviderInfo = createSelector(
  selectMentorState,
  (state: ServiceProviderInfoState) => state.serviceProvider
);

