import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
  mergeMap,
  tap
} from "rxjs/operators";
import {
  ACCOUNT_TYPE,
  Auth,
  VerifyOtpCredentials
} from "../../domain";
import { AuthService } from "../../service/auth.service";
import * as AuthActions from "./auth.action";
import { AuthActionTypes } from "./auth.action";
import { Route, Router } from '@angular/router';
import { StorageService } from "src/app/shared/utils/storage.service";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import * as ProfileActions from "src/app/core/state/profile/profile.action";

@Injectable()
export class AuthEffect {
  /**
   * Attempt to login.
   */
  login$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.VerifyOtp>(AuthActionTypes.VerifyOtp),
    map((action: AuthActions.VerifyOtp) => action.payload),
    exhaustMap((creds: VerifyOtpCredentials) => {

      const credCopy = { ...creds }

      console.log({ creds })
      const loginFrom = credCopy.loginFrom
      delete credCopy.loginFrom

      console.log('loginFromloginFromloginFromloginFrom', loginFrom)
      return this.authService.verifyOtp(credCopy).pipe(
        mergeMap((data: Auth) => [
          new AuthActions.VerifyOtpSuccess(data),
        ]),
        tap((res) => {
          this.toastService.showToast('Logged in successfully');
          this.storageService.setObject('user', res.payload);

          setTimeout(() => {
            this.store$.dispatch(new ProfileActions.GetProfile());

            if (loginFrom === 'modal') {

            } else {
              let redirectUrl = res.payload.accountType === ACCOUNT_TYPE.STARTUP ? '/startups/dashboard' : '/investors/dashboard'
              const urlBeforeLoginIn = localStorage.getItem('urlBeforeLoginIn')
              if (urlBeforeLoginIn) {
                redirectUrl = urlBeforeLoginIn;
              }
              this.router.navigateByUrl(redirectUrl)
              localStorage.removeItem('urlBeforeLoginIn');
            }

          }, 500);
        }),
        catchError((err: HttpErrorResponse) => of(new AuthActions.VerifyOtpFault(err.error.message)))
      )
    }

    )
  )
  );


  logout$ = createEffect(() => this.actions$.pipe(
    ofType<AuthActions.LogOut>(AuthActionTypes.LogOut),
    map((action: AuthActions.LogOut) => action.payload),
    exhaustMap((a) => {
      this.storageService.clear();
      localStorage.removeItem('urlBeforeLoginIn');
      localStorage.removeItem('redirect-after-register-to');
      this.authService.logoutFromApi().subscribe()
      this.store$.dispatch(new ProfileActions.ClearProfile());
      if(!a?.ignoreRedirect) {
        setTimeout(() => {
          // Timeout so we redirect after state is empty
          // this.router.navigate(['/']);
          window.location.href = '/'
          // setTimeout(()=>{
          //   window.location.reload()
          // }, )
        }, 200);
      }
      return of(new AuthActions.LogOutSuccess());
    }))
  );


  /**
   * Attempt to register a user here.
   */
  // register$ = createEffect(() => this.actions$.pipe(

  // ));

  constructor(private actions$: Actions, private store$: Store<any>, private authService: AuthService, private router: Router, private storageService: StorageService, private toastService: ToastAlertService) {
  }
}
