import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as MentorDashboardActions from "./mentors.dashboard.action";
import { MentorDashboardActionTypes } from './mentors.dashboard.action';
import { MentorService } from "../../service/mentors.service";

@Injectable()
export class MentorDashboardEffect {

  getMentorDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<MentorDashboardActions.GetMentorDashboard>(MentorDashboardActionTypes.GetMentorDashboard),
    exhaustMap(() => this.mentorService.getMentorDashboard().pipe(
      map((data: any) => new MentorDashboardActions.GetMentorDashboardSuccess(data)),
      catchError((err: HttpErrorResponse) => of(new MentorDashboardActions.GetMentorDashboardFault(err.error.message)))
    ))
  ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<MentorDashboardActions.GetProfileCompleteness>(MentorDashboardActionTypes.GetProfileCompleteness),
    map((action: MentorDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.mentorService.getMentorProfileCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new MentorDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new MentorDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<MentorDashboardActions.SendRequestApproval>(MentorDashboardActionTypes.SendRequestApproval),
    map((action: MentorDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.mentorService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new MentorDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new MentorDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  constructor(
    private actions$: Actions,
    private mentorService: MentorService
  ) { }
}
