import { Action } from "@ngrx/store";

export enum CompareActionTypes {
  AddStartUpToCompare = '[Compare] AddStartUpToCompare',
  RemoveStartUpFromCompareList = '[Compare] RemoveStartUpFromCompareList',
  ResetStartUpsToCompare = '[Compare] ResetStartUpsToCompare',

  AddInvestorToCompare = '[Compare] AddInvestorToCompare',
  RemoveInvestorFromCompareList = '[Compare] RemoveInvestorFromCompareList',
  ResetInvestorToCompare = '[Compare] ResetInvestorToCompare',


  AddCorporateToCompare = '[Compare] AddCorporateToCompare',
  RemoveCorporateFromCompareList = '[Compare] RemoveCorporateFromCompareList',
  ResetCorporateToCompare = '[Compare] ResetCorporateToCompare',
}

export class AddStartUpToCompare implements Action {
  readonly type = CompareActionTypes.AddStartUpToCompare;
  constructor(public payload: string) { }
}

export class RemoveStartUpFromCompareList implements Action {
  readonly type = CompareActionTypes.RemoveStartUpFromCompareList;
  constructor(public payload: string) { }
}

export class ResetStartUpsToCompare implements Action {
  readonly type = CompareActionTypes.ResetStartUpsToCompare;
}

export class AddInvestorToCompare implements Action {
  readonly type = CompareActionTypes.AddInvestorToCompare;
  constructor(public payload: string) { }
}

export class RemoveInvestorFromCompareList implements Action {
  readonly type = CompareActionTypes.RemoveInvestorFromCompareList;
  constructor(public payload: string) { }
}

export class ResetInvestorToCompare implements Action {
  readonly type = CompareActionTypes.ResetInvestorToCompare;
}


export class AddCorporateToCompare implements Action {
  readonly type = CompareActionTypes.AddCorporateToCompare;
  constructor(public payload: string) { }
}

export class RemoveCorporateFromCompareList implements Action {
  readonly type = CompareActionTypes.RemoveCorporateFromCompareList;
  constructor(public payload: string) { }
}

export class ResetCorporateToCompare implements Action {
  readonly type = CompareActionTypes.ResetCorporateToCompare;
}


export type CompareActions =
  | AddStartUpToCompare
  | RemoveStartUpFromCompareList
  | AddInvestorToCompare
  | RemoveInvestorFromCompareList
  | ResetStartUpsToCompare
  | ResetInvestorToCompare
  | AddCorporateToCompare
  | RemoveCorporateFromCompareList
  | ResetCorporateToCompare;
