import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import {
  select,
  Store
} from "@ngrx/store";
import {
  Observable,
  of,
  throwError
} from "rxjs";
import {
  catchError,
  first,
  mergeMap,
} from "rxjs/operators";
import { StorageService } from "src/app/shared/utils/storage.service";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "../service/api-endpoint.service";
import * as fromState from "../state/auth";
import { AuthService } from "../service";

@Injectable()
export class AddTokenHeaderHttpRequestInterceptor implements HttpInterceptor {
  /**
   * Constructor.
   */
  constructor(private store$: Store<any>, private storageService: StorageService, private router: Router, private toastService: ToastAlertService, private authService: AuthService) {
  }

  /**
   * Intercepts all HTTP requests and adds the JWT token to the request's header if the URL
   * is a REST endpoint and not login or logout.
   */
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      if (!request.url.includes('tenants')) {
          request = request.clone({
              withCredentials: true,
          })
      }
      return this.addToken(request).pipe(
          first(),
          mergeMap((requestWithToken: HttpRequest<any>) => next.handle(requestWithToken).pipe((
              catchError((error: HttpErrorResponse) => {
                  if (error.status === 401) {
                      // this.storageService.remove('user');
                      this.storageService.clear();
                      this.toastService.showToast(error.error.message || 'Session expired', 'error')
                      this.authService.logout();
                      this.router.navigate(['/auth/login']);
                  }
                  if (error.status === 403) {
                      this.toastService.showToast(error.error.message || 'Forbidden', 'error')
                      this.router.navigate(['/']);
                  }
                  return throwError(() => error);
              })
          )))
      );
  }

  /**
   * Adds the JWT token to the request's header.
   */
  private addToken(request: HttpRequest<any>): Observable<HttpRequest<any>> {
      return of(request);
  }
}
