import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileType } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getProfileTypes } from 'src/app/core/state/profile';
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import { ACCOUNT_TYPE, InvestorTypes } from 'src/app/core/domain';
import * as AuthActions from "src/app/core/state/auth/auth.action";
import { StorageService } from '../../../utils/storage.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';
import { createLookingForOptionsFromBrandDetails } from 'src/app/shared/utils/common-methods';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-investor-profile-type',
  templateUrl: './create-investor-profile-type.component.html',
  styleUrls: ['./create-investor-profile-type.component.scss']
})
export class CreateInvestorProfileTypeComponent implements OnInit, OnDestroy {

  private destroyed$: Subject<void> = new Subject();
  brandDetails: IBrandDetails;
  accountInformationForm = new FormGroup({});

  @Output() onApply = new EventEmitter()
  @Output() onClose = new EventEmitter()

  InvestorTypes = InvestorTypes
  constructor(
    private readonly store: Store,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private storageService: StorageService,
    private router: Router,
    private uiLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res as IBrandDetails
      }
    })

    this.initForm()
  }

  initForm() {
    this.accountInformationForm = new FormGroup({
      investorType: new FormControl(null, [Validators.required]),
      organizationName: new FormControl(''),
      website: new FormControl('', [Validators.pattern('(http(?:s)?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?')]),
    })


    this.accountInformationForm.get('investorType').valueChanges.subscribe((res) => {
      this.setValidators();
    })

    this.accountInformationForm.get('investorType').patchValue(InvestorTypes.ORGANIZATION)
  }

  setValidators() {
    if (this.investorType === InvestorTypes.INDIVIDUAL) {
      this.removeValidatorsToFormControl('organizationName')
      // this.removeValidatorsToFormControl('website')
    } else if (this.investorType === InvestorTypes.SYNDICATE) {
      // this.setValidatorsToFormControl('website')
      this.setValidatorsToFormControl('organizationName')
    } else {
      this.setValidatorsToFormControl('organizationName')
      // this.removeValidatorsToFormControl('website')
    }
  }


  setValidatorsToFormControl(fromControlName: string) {
    this.accountInformationForm.get(fromControlName)?.setValidators(Validators.required);
    this.accountInformationForm.get(fromControlName)?.updateValueAndValidity()
  }

  removeValidatorsToFormControl(fromControlName: string) {
    this.accountInformationForm.get(fromControlName)?.clearValidators();
    this.accountInformationForm.get(fromControlName)?.updateValueAndValidity()
  }

  onChangeLookingFor = () => {
    // this.revalidate(this.formValues);
  }

  get investorType() {
    return this.accountInformationForm.get('investorType').value
  }

  closeModal(): void {
    this.activeModal.close();
  }

  handleCancle( ) {
    this.onClose.emit();
  }

  onApplyBtnClick() {
    const payload = this.accountInformationForm.value;

    payload["applyingForSpecificEvent"] = false
    payload["userType"] = ACCOUNT_TYPE.INVESTOR

    this.onApply.emit(payload)
  }

  get isDisabledApply() {
    return !this.accountInformationForm.valid
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
