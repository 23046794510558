import { Action } from "@ngrx/store";
import { IGlobalSettings } from "../../domain/settings.model";
import { IBrandDetails } from "../../domain/brand.model";

export enum GlobalActionTypes {
  GetGlobalSettings = "[Global] GetGlobalSettings",
  GetGlobalSettingsSuccess = "[Global] GetGlobalSettingsSuccess",
  GetGlobalSettingsFault = "[Global] GetGlobalSettingsFault",

  SetBrandDetails = "[Global] SetBrandDetails",
  SetFormsList = "[Global] SetFormsList"
}

export class GetGlobalSettings implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettings;
}

export class GetGlobalSettingsSuccess implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsSuccess;

  constructor(public payload: IGlobalSettings) { }
}

export class GetGlobalSettingsFault implements Action {
  readonly type = GlobalActionTypes.GetGlobalSettingsFault;
  constructor(public payload: string) { }
}


export class SetBrandDetails implements Action {
  readonly type = GlobalActionTypes.SetBrandDetails;

  constructor(public payload: IBrandDetails) { }
}
export class SetFormsList implements Action {
  readonly type = GlobalActionTypes.SetFormsList;

  constructor(public payload: IBrandDetails) { }
}

export type GlobalActions =
  | GetGlobalSettings
  | GetGlobalSettingsSuccess
  | GetGlobalSettingsFault
  | SetBrandDetails
  | SetFormsList;
