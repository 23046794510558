import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { NgbAccordionModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from 'src/app/shared/shared.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';
import { SliderModule } from '../slider/slider.module';
import { ShareLinksComponent } from '../share-links/share-links.component';
import { WindowComponent } from '../share-links/window.component';
import { PortalModule } from '@angular/cdk/portal';

@NgModule({
  declarations: [
    ShareLinksComponent,
    WindowComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbAccordionModule,
    NgbModule,
    LazyLoadImageModule,
    PipesModule,
    SliderModule,
    PortalModule
  ],
  exports: [
    PipesModule,
    LazyLoadImageModule,
    SliderModule,
    ShareLinksComponent,
    WindowComponent
  ]
})
export class PublicSharedModule { }
