import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { PartnersInformation } from "../../domain/partners.model";
import { PartnersService } from "../../service";
import { JobsService } from "../../service/jobs.service";
import * as JobsAction from "./jobs.action";
import { JobsActionTypes } from "./jobs.action";

@Injectable()
export class JobsEffect {
    constructor(private actions$: Actions, private jobsService: JobsService) { }

    getJobsList$ = createEffect(() => this.actions$.pipe(
        ofType<JobsAction.GetJobsList>(JobsActionTypes.GetJobsList),
        map((action: JobsAction.GetJobsList) => action),
        exhaustMap((action) => this.jobsService.getJobsList(action).pipe(
            mergeMap((data: PartnersInformation) => [
                new JobsAction.GetJobsListSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new JobsAction.GetJobsListFault(err.error.message)))
        ))
    ))

}