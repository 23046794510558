import {  OrganizationInformationResponseModel } from "../../domain";
import {
    InvestorsOrgInfoActionActions,
    InvestorsOrgInfoActionTypes
} from "./investors-organization-info.action";

export interface InvestorsOrgInfoState {
    investorsOrg: OrganizationInformationResponseModel,
    error: string | null
 }

export const initialState: InvestorsOrgInfoState = {
    investorsOrg: null,
    error:""

};

function investorsOrgSuccess(state: InvestorsOrgInfoState = initialState, data: OrganizationInformationResponseModel): InvestorsOrgInfoState {
    return {
        ...state,
        investorsOrg:data,
        error:""

    };
}

function investorsOrgFault(state: InvestorsOrgInfoState = initialState, data: string): InvestorsOrgInfoState {
    return {
        ...state,
        investorsOrg:null,
        error:data

    };
}

export function investorsOrgReducer(state: InvestorsOrgInfoState = initialState, action: InvestorsOrgInfoActionActions): InvestorsOrgInfoState {
    switch (action.type) {
            case InvestorsOrgInfoActionTypes.InvestorsOrgInfoSuccess:
            return investorsOrgSuccess(state, action.payload);
            case InvestorsOrgInfoActionTypes.InvestorsOrgInfoFault:
                return investorsOrgFault(state, action.payload);
        default:
            return state;
    }
}


