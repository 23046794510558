import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileType } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getProfileTypes } from 'src/app/core/state/profile';
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import * as AuthActions from "src/app/core/state/auth/auth.action";
import { StorageService } from '../../../utils/storage.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';
import { createLookingForOptionsFromBrandDetails } from 'src/app/shared/utils/common-methods';
import { FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-create-corporate-profile-type',
  templateUrl: './create-corporate-profile-type.component.html',
  styleUrls: ['./create-corporate-profile-type.component.scss']
})
export class CreateCorporateProfileTypeComponent implements OnInit, OnDestroy {


  profileTypes: IProfileType[] = []
  private destroyed$: Subject<void> = new Subject();
  pendingTypes = [];

  view = 'default';
  brandDetails: IBrandDetails;
  accountInformationForm = new FormGroup({});

  @Output() onApply = new EventEmitter()
  @Output() onClose = new EventEmitter()

  constructor(
    private readonly store: Store,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private storageService: StorageService,
    private router: Router,
    private uiLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res as IBrandDetails
      }
    })

    this.initForm()
  }

  initForm() {
    this.accountInformationForm = new FormGroup({
      companyName: new FormControl('', [Validators.required]),
      designation: new FormControl('', [Validators.required]),
    })
  }


  closeModal(): void {
    this.activeModal.close();
  }

  onApplyBtnClick() {
    const payload = this.accountInformationForm.value;
    payload["applyingForSpecificEvent"] = false
    payload["userType"] = ACCOUNT_TYPE.CORPORATE

    this.onApply.emit(payload)
  }

  get isDisabledApply() {
    return !this.accountInformationForm.valid
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  handleCancle( ) {
    this.onClose.emit();
  }

}
