import { FaqModel } from "../../domain";
import {
  FeedActions,
  FeedActionTypes
} from "./feed.action";

export interface FeedState {
  feedStats: {
    totalComment: number,
    totalPost: number,
    totalPostReaction: number
  },
}

export const initialState: FeedState = {
  feedStats: null,
};

function feedStatsSuccess(state: FeedState = initialState, data: any): FeedState {
  return {
    ...state,
    feedStats: data,
  };
}


export function feedReducer(state: FeedState = initialState, action: FeedActions): FeedState {
  switch (action.type) {
    case FeedActionTypes.FeedStatsSuccess:
      return feedStatsSuccess(state, action.payload);
    default:
      return state;
  }
}


