import { MentorProfileCompleteness, ServiceProviderProfileCompleteness } from "../../domain";
import { MentorDashBoardActions, MentorDashboardActionTypes } from "./mentors.dashboard.action";

export interface MentorDashboardState {
  mentorDashboard: any;
  mentorDashboardLoading: boolean;
  mentorDashboardError: string | null | string[];

  mentorProfileCompleteness: MentorProfileCompleteness;
  mentorProfileCompletenessLoading: boolean;
  mentorProfileCompletenessError: string | null | string[];


  requestApproval: string;
  requestApprovalLoading: boolean;
  requestApprovalError: string;

}

export const initialState: MentorDashboardState = {
  mentorDashboard: null,
  mentorDashboardLoading: false,
  mentorDashboardError: "",


  mentorProfileCompleteness: null,
  mentorProfileCompletenessLoading: false,
  mentorProfileCompletenessError: "",


  requestApproval: null,
  requestApprovalLoading: false,
  requestApprovalError: "",

};



function initInvestorDashboard(state: MentorDashboardState = initialState): MentorDashboardState {
  return {
    ...state,
    mentorDashboardLoading: true,
    mentorDashboardError: ""
  };
}

function investorDashboardSuccess(state: MentorDashboardState = initialState, data: any): MentorDashboardState {
  return {
    ...state,
    mentorDashboard: data,
    mentorDashboardLoading: false,
    mentorDashboardError: ""
  }
}

function investorDashboardFailed(state: MentorDashboardState = initialState, data: string): MentorDashboardState {
  return {
    ...state,
    mentorDashboard: null,
    mentorDashboardLoading: false,
    mentorDashboardError: data
  }
}

function initMentorProfileCompleteness(state: MentorDashboardState = initialState): MentorDashboardState {
  return {
    ...state,
    mentorProfileCompletenessLoading: true,
    mentorProfileCompletenessError: ""
  };
}

function mentorProfileCompletenessSuccess(state: MentorDashboardState = initialState, data: MentorProfileCompleteness): MentorDashboardState {
  return {
    ...state,
    mentorProfileCompleteness: data,
    mentorProfileCompletenessLoading: false,
    mentorProfileCompletenessError: ""
  }
}

function mentorProfileCompletenessFailed(state: MentorDashboardState = initialState, data: string): MentorDashboardState {
  return {
    ...state,
    mentorProfileCompleteness: null,
    mentorProfileCompletenessLoading: false,
    mentorProfileCompletenessError: data
  }
}


function initRequestApproval(state: MentorDashboardState = initialState): MentorDashboardState {
  return {
    ...state,
    requestApprovalLoading: true,
    requestApprovalError: ""
  };
}

function requestApprovalSuccess(state: MentorDashboardState = initialState, data: string): MentorDashboardState {
  return {
    ...state,
    requestApproval: data,
    requestApprovalLoading: false,
    requestApprovalError: ""
  }
}

function requestApprovalFailed(state: MentorDashboardState = initialState, data: string): MentorDashboardState {
  return {
    ...state,
    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: data
  }
}

export function mentorDashboardReducer(state: MentorDashboardState = initialState, action: MentorDashBoardActions): MentorDashboardState {
  switch (action.type) {

    case MentorDashboardActionTypes.GetMentorDashboard:
      return initInvestorDashboard(state);

    case MentorDashboardActionTypes.GetMentorDashboardSuccess:
      return investorDashboardSuccess(state, action.payload);

    case MentorDashboardActionTypes.GetMentorDashboardFault:
      return investorDashboardFailed(state, action.payload);


    case MentorDashboardActionTypes.GetProfileCompleteness:
      return initMentorProfileCompleteness(state);

    case MentorDashboardActionTypes.GetProfileCompletenessSuccess:
      return mentorProfileCompletenessSuccess(state, action.payload);

    case MentorDashboardActionTypes.GetProfileCompletenessFault:
      return mentorProfileCompletenessFailed(state, action.payload);


    case MentorDashboardActionTypes.SendRequestApproval:
      return initRequestApproval(state);

    case MentorDashboardActionTypes.SendRequestApprovalSuccess:
      return requestApprovalSuccess(state, action.payload);

    case MentorDashboardActionTypes.SendRequestApprovalFault:
      return requestApprovalFailed(state, action.payload);

    default:
      return state;
  }
}
