import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError, map, tap
} from "rxjs/operators";
import { IFoundersInfo } from "../domain/startup.model";
import { ApiEndpointService } from "./api-endpoint.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";

@Injectable({
  providedIn: "root"
})
export class AdvisoryBoardService {

  constructor(private http: HttpClient, private store: Store) { }

  public getAdvisoryBoard(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ADVISORY_BOARDS) + '/list';
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`founders GET ( Received founders ) `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`foundersFault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public saveAdvisoryBoard(payload: IFoundersInfo): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ADVISORY_BOARDS);
    console.info(`saving founder info`, payload);
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`savefounderInfo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public updateAdvisoryBoard(id: string, payload: IFoundersInfo): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ADVISORY_BOARDS) + `/${id}`;
    console.info(`update Founder info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`updateFounderInfo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public deleteAdvisoryBoard(id: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.ADVISORY_BOARDS) + `/${id}`;
    console.info(`delete Founder info with id: `, id);
    return this.http.delete(url).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`deleteFounderInfo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }
}
