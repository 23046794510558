import {  IStartUpKitModel } from "../../domain";
import {
    StartUpKitActions,
    StartUpKitActionTypes
} from "./start-up-kit.action";

export interface StartUpKitState {
    startUpKit: IStartUpKitModel,
    error: string | null
 }

export const initialState: StartUpKitState = {
    startUpKit: null,
    error:""

};

function startUpKitSuccess(state: StartUpKitState = initialState, data: IStartUpKitModel): StartUpKitState {
    return {
        ...state,
        startUpKit: data,
        error:""
    };
}

function startUpKitFault(state: StartUpKitState = initialState, data: string): StartUpKitState {
    return {
        ...state,
        startUpKit:null,
        error:data

    };
}

export function startUpKitReducer(state: StartUpKitState = initialState, action: StartUpKitActions): StartUpKitState {
    switch (action.type) {
            case StartUpKitActionTypes.StartUpKitSuccess:
              return startUpKitSuccess(state, action.payload);
            case StartUpKitActionTypes.StartUpKitFault:
              return startUpKitFault(state, action.payload);
        default:
            return state;
    }
}


