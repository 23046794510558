import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PartnerDashboardState } from "./partner.dashboard.reducer";

export const selectPartnerDashboardState = createFeatureSelector<PartnerDashboardState>("partnersDashboard");

export const getPartnerProfileCompleteness = createSelector(
  selectPartnerDashboardState,
  (state: PartnerDashboardState) => state.partnerProfileCompleteness
)

export const getPartnerDashboard = createSelector(
  selectPartnerDashboardState,
  (state: PartnerDashboardState) => state.partnerDashboard
)
