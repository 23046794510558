import { RegisterModel, RegisterResponsePayload, SingUpSendOtpResponse, SingUpVerifyOtpResponse } from "../../domain";
import { SignUpActions, SignUpActionTypes } from "./sign-up.action";


export interface SignUpSendOtpState {
    payload: SingUpSendOtpResponse
    error: string | null;
    loading: boolean;
}

export const initialState: SignUpSendOtpState = {
    payload: null,
    error: "",
    loading: false
};

function initSendOTP(state: SignUpSendOtpState = initialState): SignUpSendOtpState {
    return {
        ...state,
        payload: null,
        error: "",
        loading: true
    };
}

function sendOtpSuccess(state: SignUpSendOtpState = initialState, data: SingUpSendOtpResponse): SignUpSendOtpState {
    return {
        ...state,
        payload: data,
        error: "",
        loading: false
    };
}

function sendOtpFailed(state: SignUpSendOtpState = initialState, data: string): SignUpSendOtpState {
    return {
        ...state,
        payload: null,
        error: data,
        loading: false
    };
}

function resetSendOtpError(state: SignUpSendOtpState = initialState): SignUpSendOtpState {
    return {
        ...state,
        error: "",
        loading: false
    };
}

export function signUpSendOtpReducer(state: SignUpSendOtpState = initialState, action: SignUpActions): SignUpSendOtpState {
    switch (action.type) {

        case SignUpActionTypes.SendOtp:
            return initSendOTP(state);

        case SignUpActionTypes.SendOtpSuccess:
            return sendOtpSuccess(state, action.payload);

        case SignUpActionTypes.SendOtpFault:
            return sendOtpFailed(state, action.payload);

        case SignUpActionTypes.ResetSignUpError:
            return resetSendOtpError(state);

        default:
            return state;
    }
}

export interface SignUpVerifyOtpState {
    payload: SingUpVerifyOtpResponse
    error: string | null;
    loader: boolean;
}

export const initialStateVerifyOtp: SignUpVerifyOtpState = {
    payload: null,
    error: "",
    loader: false
};

function initVerifyOTP(state: SignUpVerifyOtpState = initialStateVerifyOtp): SignUpVerifyOtpState {
    return {
        ...state,
        payload: null,
        error: "",
        loader: true
    };
}

function verifyOtpSuccess(state: SignUpVerifyOtpState = initialStateVerifyOtp, data: SingUpVerifyOtpResponse): SignUpVerifyOtpState {
    return {
        ...state,
        payload: data,
        error: "",
        loader: false
    };
}

function verifyOtpFailed(state: SignUpVerifyOtpState = initialStateVerifyOtp, data: string): SignUpVerifyOtpState {
    return {
        ...state,
        payload: null,
        error: data,
        loader: false
    };
}

function resetVerifyOtpError(state: SignUpVerifyOtpState = initialStateVerifyOtp): SignUpVerifyOtpState {
    return {
        ...state,
        error: "",
        loader: false
    };
}

export function signUpVerifyOtpReducer(state: SignUpVerifyOtpState = initialStateVerifyOtp, action: SignUpActions): SignUpVerifyOtpState {
    switch (action.type) {

        case SignUpActionTypes.VerifyOtp:
            return initVerifyOTP(state);

        case SignUpActionTypes.VerifyOtpSuccess:
            return verifyOtpSuccess(state, action.payload);

        case SignUpActionTypes.VerifyOtpFault:
            return verifyOtpFailed(state, action.payload);

        case SignUpActionTypes.ResetSignUpError:
            return resetVerifyOtpError(state);

        default:
            return state;
    }
}

export interface RegisterState {
    payload: RegisterResponsePayload
    error: string | null;
    loader: boolean;
}

export const initialStateRegister: RegisterState = {
    payload: null,
    error: "",
    loader: false
};

function initRegister(state: RegisterState = initialStateRegister): RegisterState {
    return {
        ...state,
        payload: null,
        error: "",
        loader: true
    };
}

function registerSuccess(state: RegisterState = initialStateRegister, data: RegisterResponsePayload): RegisterState {
    return {
        ...state,
        payload: data,
        error: "",
        loader: false
    };
}

function registerFailed(state: RegisterState = initialStateRegister, data: string): RegisterState {
    return {
        ...state,
        payload: null,
        error: data,
        loader: false
    };
}

function resetRegisterError(state: RegisterState = initialStateRegister): RegisterState {
    return {
        ...state,
        error: "",
        loader: false
    };
}

export function signUpRegisterReducer(state: RegisterState = initialStateRegister, action: SignUpActions): RegisterState {
    switch (action.type) {

        case SignUpActionTypes.Register:
            return initRegister(state);

        case SignUpActionTypes.RegisterSuccess:
            return registerSuccess(state, action.payload);

        case SignUpActionTypes.RegisterFault:
            return registerFailed(state, action.payload);

        case SignUpActionTypes.ResetSignUpError:
            return resetRegisterError(state);

        default:
            return state;
    }
}
