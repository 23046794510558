import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { select } from '@ngrx/store';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { getGlobalSettings } from 'src/app/core/state/global';

@Pipe({
  name: 'imagekitUrlAppender',
  // pure: false
})
export class ImagekitUrlAppenderPipe implements PipeTransform {
  public globalSettings$: Observable<IGlobalSettings>;
  globalSettings: IGlobalSettings;

  constructor(
    private readonly store: Store,
  ) {
    this.globalSettings$ = this.store.pipe(select(getGlobalSettings));
    this.globalSettings$.pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.globalSettings = res;
      }
    })
  }


  transform(value: unknown, ...args: unknown[]): string {
    return this.globalSettings?.imgKitUrl + value
  }

}
