import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IGlobalSettings } from 'src/app/core/domain/settings.model';
import { GlobalService } from 'src/app/core/service/global.service';
import { getGlobalSettings } from 'src/app/core/state/global';

export enum PlatformFeedbackEnum {
  BELOW_AVERAGE = 'below_average',
  AVERAGE = 'average',
  GOOD = 'good',
  EXCELLENT = 'excellent',
  LOVING_IT = 'loving_it',
}

@Component({
  selector: 'app-rate-expirence-modal',
  templateUrl: './rate-expirence-modal.component.html',
  styleUrls: ['./rate-expirence-modal.component.scss']
})
export class RateExpirenceModalComponent implements OnInit, OnDestroy {
  loading = false;
  globalSettings: IGlobalSettings;
  private destroyed$: Subject<void> = new Subject();
  selectedReaction = '';
  PlatformFeedbackEnum = PlatformFeedbackEnum;
  feedback = ''
  @Input() title = 'Rate your overall experience';

  constructor(
    private modalService: NgbModal,
    private readonly store: Store,
    private globalService: GlobalService
  ) { }

  ngOnInit(): void {

    this.store.pipe(select(getGlobalSettings)).pipe(distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((res) => {
      if (res) {
        this.globalSettings = res;

      }
    })
  }

  closeModal(): void {
    this.modalService.dismissAll();
  }
  selectReaction(reaction: PlatformFeedbackEnum) {
    this.selectedReaction = reaction

    if (PlatformFeedbackEnum.EXCELLENT === this.selectedReaction
      || PlatformFeedbackEnum.LOVING_IT === this.selectedReaction) {
      this.handleSubmit()
    }
  }


  handleSubmit() {
    this.loading = true;
    this.globalService.platformFeedback({
      ratings: this.selectedReaction,
      comments: this.feedback
    }).subscribe((res) => {
      if (res) {
        this.loading = false;
        this.closeModal()
      }
    }, err => {
      this.loading = false;
    })
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
}
