import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as PartnerDashboardActions from "./partner.dashboard.action";
import { PartnerDashboardActionTypes } from './partner.dashboard.action';
import { ServiceProviderService } from "../../service/service-provider.service";
import { PartnersService } from "../../service";

@Injectable()
export class PartnerDashboardEffect {

  // getPartnerDashboard$ = createEffect(() => this.actions$.pipe(
  //   ofType<PartnerDashboardActions.GetPartnerDashboard>(PartnerDashboardActionTypes.GetPartnerDashboard),
  //   exhaustMap(() => this.partnersService.getServiceProviderDashboard().pipe(
  //     map((data: any) => new PartnerDashboardActions.GetPartnerDashboardSuccess(data)),
  //     catchError((err: HttpErrorResponse) => of(new PartnerDashboardActions.GetPartnerDashboardFault(err.error.message)))
  //   ))
  // ))

  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<PartnerDashboardActions.GetProfileCompleteness>(PartnerDashboardActionTypes.GetProfileCompleteness),
    map((action: PartnerDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.partnersService.getPartnerCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new PartnerDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new PartnerDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<PartnerDashboardActions.SendRequestApproval>(PartnerDashboardActionTypes.SendRequestApproval),
    map((action: PartnerDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.partnersService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new PartnerDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new PartnerDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  constructor(
    private actions$: Actions,
    private partnersService: PartnersService
  ) { }
}
