import { ChallengesState } from "./challenges.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";


export const challengesState = createFeatureSelector<ChallengesState>("challenges");

export const getChallengesList = createSelector(
    challengesState,
    (state: ChallengesState) => state.challengesList
)


export const getChallengesLoader = createSelector(
    challengesState,
    (state: ChallengesState) => state.challengesLoading
)
