import { Action } from "@ngrx/store";
import {
    FaqModel,
} from "../../domain";

export enum FaqActionTypes {
    GetFaq = "[Faq] GetFaq",
    FaqSuccess = "[Faq] FaqSuccess",
    FaqFault = "[Faq] FaqFault",
  }

export class GetFaq implements Action {
    readonly type = FaqActionTypes.GetFaq;

    constructor() {
    }
}

export class FaqSuccess implements Action {
    readonly type = FaqActionTypes.FaqSuccess;

    constructor(public payload: FaqModel[]) {
    }
}

export class FaqFault implements Action {
    readonly type = FaqActionTypes.FaqFault;

    constructor(public payload: string) {
    }
}



export type FaqActions =
    | GetFaq
    | FaqSuccess
    | FaqFault
    ;
