import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ServiceProviderDashboardState } from "./service-provider.dashboard.reducer";

export const selectServiceProviderDashboardState = createFeatureSelector<ServiceProviderDashboardState>("serviceProviderDashboard");

export const getServiceProviderProfileCompleteness = createSelector(
  selectServiceProviderDashboardState,
  (state: ServiceProviderDashboardState) => state.serviceProviderProfileCompleteness
)

export const getServiceProviderDashboard = createSelector(
  selectServiceProviderDashboardState,
  (state: ServiceProviderDashboardState) => state.serviceProviderDashboard
)
