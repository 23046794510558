import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import decode from 'jwt-decode';
import { StorageService } from '../utils/storage.service';
import { Auth, IAccessTokenDetails } from 'src/app/core/domain';
import { ToastAlertService } from '../utils/toast.service';
import { ACCOUNT_TYPE_TO_DASHBOARD_UTL } from '../constants/navlinks';
import { AuthService } from 'src/app/core/service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private storageService: StorageService,
    private toastService: ToastAlertService,
    private router: Router,
    private authService: AuthService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let expectedType = route.data['expectedType'] as Array<string>;
    const user: Auth = this.storageService.getObject(StorageService.storageKeys.user);
    if(!user) {
      this.showErrorAndLogout(state);
    }
    // let tokenDetails: IAccessTokenDetails;
    // try {
    //   tokenDetails = decode(user.accessToken);
    // } catch (error) {
    //   this.showErrorAndLogout(state);
    // }

    // if (!tokenDetails || !tokenDetails.userId) {
    //   this.showErrorAndLogout(state);
    // }

    // if (Date.now() >= tokenDetails?.exp * 1000) {
    //   this.showErrorAndLogout(state, 'Session Timeout');
    // }

    if (expectedType && expectedType.length && !expectedType.includes(user.accountType)) {
      const redirectUrl = ACCOUNT_TYPE_TO_DASHBOARD_UTL[user.accountType];
      this.router.navigate([redirectUrl])
      return false;
    }

    return true;
  }

  showErrorAndLogout(state: RouterStateSnapshot, msg = 'Error logging in',) {
    this.toastService.showToast(msg, 'error');
    console.error('Error while decoding access token');
    this.authService.logout();

    localStorage.setItem('urlBeforeLoginIn', state.url);

    return false;
  }

}
