import { Action } from "@ngrx/store";
import {
  PartnerInformationResponseModel,
} from "../../domain";

export enum PartnerInfoActionTypes {
  GetPartnerInfo = "[partner] GetPartnerInfo",
  GetPartnerInfoSuccess = "[partner] GetPartnerInfoSuccess",
  PartnerInfoFault = "[partner] PartnerInfoFault",
}

export class GetPartnerInfo implements Action {
  readonly type = PartnerInfoActionTypes.GetPartnerInfo;
}

export class PartnerInfoSuccess implements Action {
  readonly type = PartnerInfoActionTypes.GetPartnerInfoSuccess;

  constructor(public payload: PartnerInformationResponseModel) {
  }
}

export class PartnerInfoFault implements Action {
  readonly type = PartnerInfoActionTypes.PartnerInfoFault;

  constructor(public payload: string) {
  }
}


export type PartnerInfoAction =
  | GetPartnerInfo
  | PartnerInfoSuccess
  | PartnerInfoFault
  ;
