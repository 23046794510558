import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as IndividualProfileDashboardActions from "./individual-profile.dashboard.action";
import { IndividualProfileDashboardActionTypes } from './individual-profile.dashboard.action';
import { MentorService } from "../../service/mentors.service";
import { IndividualProfileService } from "../../service/individual-profile.service";

@Injectable()
export class IndividualProfileDashboardEffect {

  // getMentorDashboard$ = createEffect(() => this.actions$.pipe(
  //   ofType<MentorDashboardActions.GetMentorDashboard>(InvestorProfileDashboardActionTypes.GetMentorDashboard),
  //   exhaustMap(() => this.mentorService.getMentorDashboard().pipe(
  //     map((data: any) => new MentorDashboardActions.GetMentorDashboardSuccess(data)),
  //     catchError((err: HttpErrorResponse) => of(new MentorDashboardActions.GetMentorDashboardFault(err.error.message)))
  //   ))
  // ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<IndividualProfileDashboardActions.GetProfileCompleteness>(IndividualProfileDashboardActionTypes.GetProfileCompleteness),
    map((action: IndividualProfileDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.individualProfileService.getInvididualProfileCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new IndividualProfileDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new IndividualProfileDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<IndividualProfileDashboardActions.SendRequestApproval>(IndividualProfileDashboardActionTypes.SendRequestApproval),
    map((action: IndividualProfileDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.individualProfileService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new IndividualProfileDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new IndividualProfileDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  constructor(
    private actions$: Actions,
    private individualProfileService: IndividualProfileService
  ) { }
}
