import { IJobs } from "../../domain/jobs.model";
import { PartnersInformation } from "../../domain/partners.model";
import { JobsAction, JobsActionTypes } from "./jobs.action";


export interface JobsState {
    jobsList: IJobs[];
    jobsLoading: boolean;
    jobsError: string | null | string[];
}

export const initialState: JobsState = {
    jobsList: null,
    jobsLoading: false,
    jobsError: ""
}

function initJobsList(state: JobsState = initialState,  payload): JobsState {
    return {
        ...state,
        jobsLoading: true,
        jobsError: ""
    };
}

function JobListSuccess(state: JobsState = initialState, data: any): JobsState {
    return {
        ...state,
        jobsList: data,
        jobsLoading: false,
        jobsError: ""
    }
}

function JobListFailed(state: JobsState = initialState, data: string): JobsState {
    return {
        ...state,
        jobsList: null,
        jobsLoading: false,
        jobsError: data
    }
}


export function jobsReducer(state: JobsState = initialState, action: JobsAction): JobsState {

    switch (action.type) {
        case JobsActionTypes.GetJobsList:
            return initJobsList(state,  action.filters);

        case JobsActionTypes.GetJobsListSuccess:
            return JobListSuccess(state, action.payload);

        case JobsActionTypes.GetJobsListFault:
            return JobListFailed(state, action.payload);


        default:
            return state;
    }
}