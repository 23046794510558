import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import {
  PartnerInformationResponseModel
} from "../../domain";
import { PartnersService } from "../../service";
import * as PartnerInfoAction from "./partner-info.action";
import { PartnerInfoActionTypes } from "./partner-info.action";

@Injectable()
export class PartnerInfoEffect {

  getPartnerInfo$ = createEffect(() => this.actions$.pipe(
    ofType<PartnerInfoAction.GetPartnerInfo>(PartnerInfoActionTypes.GetPartnerInfo),
    exhaustMap(() =>
      this.partnerService.getPartnerInformation().pipe(
        map((data: PartnerInformationResponseModel) => new PartnerInfoAction.PartnerInfoSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new PartnerInfoAction.PartnerInfoFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private partnerService: PartnersService) {
  }
}
