import { HttpClientModule } from "@angular/common/http";
import {
    NgModule,
} from "@angular/core";
import { HttpInterceptorModule } from "./http-interceptor/http-interceptor.module";
import { ServiceModule } from "./service/service.module";
import { StateModule } from "./state/state.module";

const MODULES = [
    HttpClientModule,
    HttpInterceptorModule,
    ServiceModule,
    StateModule
];

@NgModule({
    imports: MODULES,
    exports: MODULES,
})
export class CoreModule {

}
