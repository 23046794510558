import { Action } from "@ngrx/store";
import {
    IStartUpKitModel
} from "../../domain";

export enum StartUpKitActionTypes {
    GetStartUpKit = "[StartUpKit] GetStartUpKit",
    StartUpKitSuccess = "[StartUpKit] StartUpKitSuccess",
    StartUpKitFault = "[StartUpKit] StartUpKitFault",
  }

export class GetStartUpKit implements Action {
    readonly type = StartUpKitActionTypes.GetStartUpKit;

    constructor() {
    }
}

export class StartUpKitSuccess implements Action {
    readonly type = StartUpKitActionTypes.StartUpKitSuccess;

    constructor(public payload: IStartUpKitModel) {
    }
}

export class StartUpKitFault implements Action {
    readonly type = StartUpKitActionTypes.StartUpKitFault;

    constructor(public payload: string) {
    }
}



export type StartUpKitActions =
    | GetStartUpKit
    | StartUpKitSuccess
    | StartUpKitFault
    ;
