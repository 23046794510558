import { MentorInformationResponseModel } from "../../domain";
import {
  MentorInfoAction,
  MentorInfoActionTypes
} from "./mentor-info.action";

export interface MentorInfoState {
  mentor: MentorInformationResponseModel,
  error: string | null
}

export const initialState: MentorInfoState = {
  mentor: null,
  error: ""

};

function mentorInfoSuccess(state: MentorInfoState = initialState, data: MentorInformationResponseModel): MentorInfoState {
  return {
    ...state,
    mentor: data,
    error: ""
  };
}

function mentorInfoFault(state: MentorInfoState = initialState, data: string): MentorInfoState {
  return {
    ...state,
    mentor: null,
    error: data
  };
}

export function mentorReducer(state: MentorInfoState = initialState, action: MentorInfoAction): MentorInfoState {
  switch (action.type) {
    case MentorInfoActionTypes.GetMentorInfoSuccess:
      return mentorInfoSuccess(state, action.payload);
    case MentorInfoActionTypes.MentorInfoFault:
      return mentorInfoFault(state, action.payload);
    default:
      return state;
  }
}


