import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IndividualDashboardState } from "./individual-profile.dashboard.reducer";

export const selectIndividualDashboardState = createFeatureSelector<IndividualDashboardState>("individualDashboard");

export const getIndividualProfileCompleteness = createSelector(
  selectIndividualDashboardState,
  (state: IndividualDashboardState) => state.individualProfileCompleteness
)

export const getIndividualDashboard = createSelector(
  selectIndividualDashboardState,
  (state: IndividualDashboardState) => state.individualDashboard
)
