import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { Subject, distinctUntilChanged, takeUntil } from 'rxjs';
import { IProfileType } from 'src/app/core/domain/profile.model';
import { ProfileService } from 'src/app/core/service/profile.service';
import { getProfileTypes } from 'src/app/core/state/profile';
import * as ProfileActions from "src/app/core/state/profile/profile.action";
import { ACCOUNT_TYPE } from 'src/app/core/domain';
import * as AuthActions from "src/app/core/state/auth/auth.action";
import { StorageService } from '../../../utils/storage.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { IBrandDetails } from 'src/app/core/domain/brand.model';
import { getBrandDetails } from 'src/app/core/state/global';
import { createLookingForOptionsFromBrandDetails } from 'src/app/shared/utils/common-methods';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-startup-profile-type',
  templateUrl: './create-startup-profile-type.component.html',
  styleUrls: ['./create-startup-profile-type.component.scss']
})
export class CreateStartupProfileTypeComponent implements OnInit, OnDestroy {

  profileTypes: IProfileType[] = []
  private destroyed$: Subject<void> = new Subject();
  pendingTypes = [];

  allPossibleTypes = [
    ACCOUNT_TYPE.STARTUP,
    ACCOUNT_TYPE.INVESTOR,
    ACCOUNT_TYPE.CORPORATE,
    ACCOUNT_TYPE.MENTOR,
    ACCOUNT_TYPE.SERVICE_PROVIDER,
  ]

  labelMap = {
    [ACCOUNT_TYPE.SERVICE_PROVIDER]: 'Service Provider'
  }

  view = 'default';
  lookingForOptions = []
  brandDetails: IBrandDetails;
  accountInformationForm = new FormGroup({});

  @Output() onApply = new EventEmitter()
  @Output() onClose = new EventEmitter()

  constructor(
    private readonly store: Store,
    public activeModal: NgbActiveModal,
    private profileService: ProfileService,
    private storageService: StorageService,
    private router: Router,
    private uiLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.store.pipe(select(getBrandDetails)).pipe(distinctUntilChanged()).subscribe((res) => {
      if (res) {
        this.brandDetails = res as IBrandDetails
        this.lookingForOptions = createLookingForOptionsFromBrandDetails(this.brandDetails as IBrandDetails)
      }
    })

    this.initForm()
  }

  initForm() {
    this.accountInformationForm = new FormGroup({
      companyName: new FormControl('', [Validators.required]),
    })
  }

  onChangeLookingFor = () => {
    // this.revalidate(this.formValues);
  }


  closeModal(): void {
    this.activeModal.close();
  }

  async onApplyBtnClick() {
    const selectedLookingFor = this.lookingForOptions.filter((e) => e.selected).map(e => e.value);

    if (this.brandDetails.features.registration_enterprise_sales_popup && !selectedLookingFor.includes('customer_access')) {
      const result = await Swal.fire({
        title: 'No Market Access ??',
        text: '73% of SanchiConnect users have established at least one new business relationship utilising the “Market Access” module.  We call it INFINITY RoI module.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: 'var(--color-theme)',
        cancelButtonColor: '#7D8185',
        confirmButtonText: 'Select & Proceed',
        cancelButtonText: 'Skip'
      })

      if (result.isConfirmed) {
        selectedLookingFor.push('customer_access')
      }
    }



    const payload = this.accountInformationForm.value;
    payload['servicesLookingFor'] = selectedLookingFor;

    payload["applyingForSpecificEvent"] = false
    payload["userType"] = ACCOUNT_TYPE.STARTUP
    this.onApply.emit(payload)
  }

  get isDisabledApply() {
    const selectedLookingFor = this.lookingForOptions.filter((e) => e.selected).map(e => e.value);

    return !this.accountInformationForm.valid || !selectedLookingFor.length
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.destroyed$.complete();
  }
  handleCancle( ) {
    this.onClose.emit();
  }

}
