import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import { IGlobalSettings } from "../../domain/settings.model";
import * as FiltersActions from "./filters.action";
import { FiltersActionTypes } from "./filters.action";
import { SearchService } from "../../service/search.service";
import { IFilter } from "../../domain/filters.model";

@Injectable()
export class FiltersEffect {

  getFilters$ = createEffect(() => this.actions$.pipe(
    ofType<FiltersActions.GetFilters>(FiltersActionTypes.GetFilters),
    exhaustMap(() =>
      this.searchService.getAllSearchFilter().pipe(
        map((data: IFilter[]) => new FiltersActions.GetFiltersSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new FiltersActions.GetFiltersFault(err.message)))
      )
    )
  ));

  // setAppliedSavedFilter$ = createEffect(() => this.actions$.pipe(
  //   ofType<FiltersActions.SetAppliedSavedFilter>(FiltersActionTypes.SetAppliedSavedFilter),
  //   exhaustMap((payload) => {
  //     console.log('payload', payload.payload);
  //     localStorage.setItem(SearchService)
  //     return of(new FiltersActions.SetAppliedSavedFilterSuccess())
  //   }
  //   )
  // ));

  constructor(private actions$: Actions, private searchService: SearchService) {
  }
}
