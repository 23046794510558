import {  FaqModel } from "../../domain";
import {
    FaqActions,
    FaqActionTypes
} from "./faq.action";

export interface FaqState {
    faq: FaqModel[],
    error: string | null
 }

export const initialState: FaqState = {
    faq: null,
    error:""

};

function faqSuccess(state: FaqState = initialState, data: FaqModel[]): FaqState {
    return {
        ...state,
        faq:data,
        error:""

    };
}

function faqFault(state: FaqState = initialState, data: string): FaqState {
    return {
        ...state,
        faq:null,
        error:data

    };
}

export function faqReducer(state: FaqState = initialState, action: FaqActions): FaqState {
    switch (action.type) {
            case FaqActionTypes.FaqSuccess:
            return faqSuccess(state, action.payload);
            case FaqActionTypes.FaqFault:
                return faqFault(state, action.payload);
        default:
            return state;
    }
}


