import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { AuthService } from 'src/app/core/service';

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.scss']
})
export class VerifyEmailComponent implements OnInit {

  message = 'Email verification is in progress...';


  lottieOptions: AnimationOptions;

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private uiLoader: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe((res) => {
      console.log('parmas', res);
      if (res['emailAddress'] && res['verificationUid']) {
        this.markEmailAsVerified(res['emailAddress'], res['verificationUid']);
      }
    })
  }

  markEmailAsVerified(emailAddress, verificationId) {
    this.uiLoader.start();
    this.authService.markEmailAsVerified(
      emailAddress, verificationId
    ).subscribe((res) => {
      if (res) {
        this.lottieOptions = {
          path: '/assets/lottie/verify-your-email.json',
          loop: false
        };
        this.message = res.message || 'Your email has been verified';
        this.uiLoader.stop();
      }
    }, (err) => {
      this.lottieOptions = {
        path: '/assets/lottie/error-x.json',
        loop: false
      };
      this.message = Array.isArray(err.error.error) ? err.error.error?.[0] : err.error.error;
      if (!this.message) {
        this.message = 'Something went wrong while verifying the email'
      }
    })
  }

}
