import { IProfileDetails, IProfileType } from "../../domain/profile.model";
import {
  ProfileActions,
  ProfileActionTypes
} from "./profile.action";

export interface ProfileState {
  profileData: IProfileDetails,
  error: string | null,

  profileTypes: IProfileType[],
}

export const initialState: ProfileState = {
  profileData: JSON.parse(localStorage.getItem('profile')),
  error: "",

  profileTypes: [],
};

function profileSuccess(state: ProfileState = initialState, data: IProfileDetails): ProfileState {
  return {
    ...state,
    profileData: data,
    error: ""
  };
}

function profileFault(state: ProfileState = initialState, data: string): ProfileState {
  return {
    ...state,
    profileData: null,
    error: data
  };
}

function clearProfile(state: ProfileState = initialState): ProfileState {
  return {
    ...state,
    profileData: null,
    error: null
  };
}

function getProfileTypesSuccess(state: ProfileState = initialState, data: IProfileType[]): ProfileState {
  return {
    ...state,
    profileTypes: data,
  };
}


export function profileReducer(state: ProfileState = initialState, action: ProfileActions): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.ProfileSuccess:
      return profileSuccess(state, action.payload);
    case ProfileActionTypes.ProfileFault:
      return profileFault(state, action.payload);
    case ProfileActionTypes.ClearProfile:
      return clearProfile(state);
    case ProfileActionTypes.GetProfileTypesSuccess:
      return getProfileTypesSuccess(state, action.payload);
    default:
      return state;
  }
}


