import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ApiEndpointService } from './api-endpoint.service';

@Injectable({
  providedIn: 'root'
})
export class StartupCompareService {

  constructor(private http: HttpClient) { }

  public getStartupCompare(ids: string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_COMPARE);
    let queryParams = new HttpParams();
    queryParams = queryParams.append('ids', ids);
    return this.http.get(url, { params: queryParams }).pipe(
      map((response: any) => {
        console.info(`startupCompare GET ( Received startupCompare `);
        return response;
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`startupCompare( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }
}
