import { StartupProfileCompleteness, StartupInvestorDashboardResponse, StartupInvestorProfileCompletenessResponse } from "../../domain";
import { StartupDashBoardActions, StartupDashboardActionTypes } from "./startup.dashboard.action";

export interface StartupDashboardState {
    startupDashboard: StartupInvestorDashboardResponse;
    startupDashboardLoading: boolean;
    startupDashboardError: string | null | string[];

    requestApproval: string;
    requestApprovalLoading: boolean;
    requestApprovalError: string;

    raisingFundsRequest: string;
    raisingFundsRequestLoading: boolean;
    raisingFundsRequestError: string;

    startupProfileCompleteness: StartupProfileCompleteness;
    startupProfileCompletenessLoading: boolean;
    startupProfileCompletenessError: string | null | string[];
}

export const initialState: StartupDashboardState = {

    startupDashboard: null,
    startupDashboardLoading: false,
    startupDashboardError: "",

    requestApproval: null,
    requestApprovalLoading: false,
    requestApprovalError: "",

    raisingFundsRequest: null,
    raisingFundsRequestLoading: false,
    raisingFundsRequestError: "",


    startupProfileCompleteness: null,
    startupProfileCompletenessLoading: false,
    startupProfileCompletenessError: "",
};

function initStartupDashboard(state: StartupDashboardState = initialState): StartupDashboardState {
    return {
        ...state,
        startupDashboardLoading: true,
        startupDashboardError: ""
    };
}

function startupDashboardSuccess(state: StartupDashboardState = initialState, data: StartupInvestorDashboardResponse): StartupDashboardState {
    return {
        ...state,
        startupDashboard: data,
        startupDashboardLoading: false,
        startupDashboardError: ""
    }
}

function startupDashboardFailed(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
    return {
        ...state,
        startupDashboard: null,
        startupDashboardLoading: false,
        startupDashboardError: data
    }
}


function initStartupProfileCompleteness(state: StartupDashboardState = initialState): StartupDashboardState {
  return {
      ...state,
      startupProfileCompletenessLoading: true,
      startupProfileCompletenessError: ""
  };
}

function startupProfileCompletenessSuccess(state: StartupDashboardState = initialState, data: StartupProfileCompleteness): StartupDashboardState {
  return {
      ...state,
      startupProfileCompleteness: data,
      startupProfileCompletenessLoading: false,
      startupProfileCompletenessError: ""
  }
}

function startupProfileCompletenessFailed(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
  return {
      ...state,
      startupProfileCompleteness: null,
      startupProfileCompletenessLoading: false,
      startupProfileCompletenessError: data
  }
}

function initRequestApproval(state: StartupDashboardState = initialState): StartupDashboardState {
    return {
        ...state,
        requestApprovalLoading: true,
        requestApprovalError: ""
    };
}

function requestApprovalSuccess(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
    return {
        ...state,
        requestApproval: data,
        requestApprovalLoading: false,
        requestApprovalError: ""
    }
}

function requestApprovalFailed(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
    return {
        ...state,
        requestApproval: null,
        requestApprovalLoading: false,
        requestApprovalError: data
    }
}

function initRaisingFundsRequest(state: StartupDashboardState = initialState): StartupDashboardState {
    return {
        ...state,
        raisingFundsRequestLoading: true,
        raisingFundsRequestError: ""
    };
}

function raisingFundsRequestSuccess(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
    return {
        ...state,
        raisingFundsRequest: data,
        raisingFundsRequestLoading: false,
        raisingFundsRequestError: ""
    }
}

function raisingFundsRequestFailed(state: StartupDashboardState = initialState, data: string): StartupDashboardState {
    return {
        ...state,
        raisingFundsRequest: null,
        raisingFundsRequestLoading: false,
        raisingFundsRequestError: data
    }
}

export function startUpDashboardReducer(state: StartupDashboardState = initialState, action: StartupDashBoardActions): StartupDashboardState {
    switch (action.type) {

        case StartupDashboardActionTypes.GetStartupDashboard:
            return initStartupDashboard(state);

        case StartupDashboardActionTypes.GetStartupDashboardSuccess:
            return startupDashboardSuccess(state, action.payload);

        case StartupDashboardActionTypes.GetStartupDashboardFault:
            return startupDashboardFailed(state, action.payload);


        case StartupDashboardActionTypes.GetProfileCompleteness:
            return initStartupProfileCompleteness(state);

        case StartupDashboardActionTypes.GetProfileCompletenessSuccess:
            return startupProfileCompletenessSuccess(state, action.payload);

        case StartupDashboardActionTypes.GetProfileCompletenessFault:
            return startupProfileCompletenessFailed(state, action.payload);


        case StartupDashboardActionTypes.SendRequestApproval:
            return initRequestApproval(state);

        case StartupDashboardActionTypes.SendRequestApprovalSuccess:
            return requestApprovalSuccess(state, action.payload);

        case StartupDashboardActionTypes.SendRequestApprovalFault:
            return requestApprovalFailed(state, action.payload);

        case StartupDashboardActionTypes.RasingFundRequest:
            return initRaisingFundsRequest(state);

        case StartupDashboardActionTypes.RasingFundRequestSuccess:
            return raisingFundsRequestSuccess(state, action.payload);

        case StartupDashboardActionTypes.RasingFundRequestFault:
            return raisingFundsRequestFailed(state, action.payload);
        default:
            return state;
    }
}
