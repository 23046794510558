import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PartnerInfoState } from "./partner-info.reducer";

export const selectPartnerState = createFeatureSelector<PartnerInfoState>("partner");

export const getPartnerInfo = createSelector(
  selectPartnerState,
  (state: PartnerInfoState) => state.partner
);

