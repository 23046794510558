import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum MentorDashboardActionTypes {
  GetProfileCompleteness = "[Mentor Dashboard] GetMentorProfileCompleteness",
  GetProfileCompletenessSuccess = "[Mentor Dashboard] GetMentorProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[Mentor Dashboard] GetMentorProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[Mentor Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[Mentor Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[Mentor Dashboard] SendRequestApprovalFault",

  GetMentorDashboard = "[Mentor Dashboard] GetMentorDashboard",
  GetMentorDashboardSuccess = "[Mentor Dashboard] GetMentorDashboardSuccess",
  GetMentorDashboardFault = "[Mentor Dashboard] GetMentorDashboardFault",

}


export class GetMentorDashboard implements Action {
  readonly type = MentorDashboardActionTypes.GetMentorDashboard;
}

export class GetMentorDashboardSuccess implements Action {
  readonly type = MentorDashboardActionTypes.GetMentorDashboardSuccess;
  constructor(public payload: any) { }
}

export class GetMentorDashboardFault implements Action {
  readonly type = MentorDashboardActionTypes.GetMentorDashboardFault;
  constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = MentorDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = MentorDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = MentorDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = MentorDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = MentorDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = MentorDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type MentorDashBoardActions =
  GetMentorDashboard
  | GetMentorDashboardSuccess
  | GetMentorDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
