import { Action } from "@ngrx/store";
import { NotificationsCount } from "./notifications.model";

export enum NotificationsActionTypes {
  SetNotificationsCount = "[Notifications] SetNotificationsCount",
  SetNotificationsCountSuccess = "[Notifications] SetNotificationsCountSuccess",
  SetNotificationsCountFault = "[Notifications] SetNotificationsCountFault",
  MarkAllReadNotifications = "[Notifications] MarkAllReadNotifications",
  MarkAllReadNotificationsSuccess = "[Notifications] MarkAllReadNotificationsSuccess",
  MarkAllReadNotificationsFault = "[Notifications] MarkAllReadNotificationsFault"
}

export class SetNotificationsCount implements Action {
  readonly type = NotificationsActionTypes.SetNotificationsCount;
}

export class SetNotificationsCountSuccess implements Action {
  readonly type = NotificationsActionTypes.SetNotificationsCountSuccess;

  constructor(public payload: NotificationsCount) { }
}

export class SetNotificationsCountFault implements Action {
  readonly type = NotificationsActionTypes.SetNotificationsCountFault;
  constructor(public payload: string) { }
}

export class MarkAllReadNotifications implements Action {
  readonly type = NotificationsActionTypes.MarkAllReadNotifications;
}

export class MarkAllReadNotificationsSuccess implements Action {
  readonly type = NotificationsActionTypes.MarkAllReadNotificationsSuccess;

  constructor(public payload: NotificationsCount) { }
}

export class MarkAllReadNotificationsFault implements Action {
  readonly type = NotificationsActionTypes.MarkAllReadNotificationsFault;
  constructor(public payload: string) { }
}

export type NotificationsActions =
  | SetNotificationsCount
  | SetNotificationsCountSuccess
  | SetNotificationsCountFault
  | MarkAllReadNotifications
  | MarkAllReadNotificationsSuccess
  | MarkAllReadNotificationsFault;
