import { IAds } from "../../domain/ads.model";
import {
  AdsActions,
  AdsActionTypes
} from "./ads.action";

export interface AdsState {
  ads: IAds,
  error: string | null
}

export const initialState: AdsState = {
  ads: null,
  error: ""
};

function adsSuccess(state: AdsState = initialState, data: IAds): AdsState {
  return {
    ...state,
    ads: data,
    error: ""
  };
}

function adsFault(state: AdsState = initialState, data: string): AdsState {
  return {
    ...state,
    ads: null,
    error: data
  };
}

export function adsReducer(state: AdsState = initialState, action: AdsActions): AdsState {
  switch (action.type) {
    case AdsActionTypes.GetAdsSuccess:
      return adsSuccess(state, action.payload);
    case AdsActionTypes.GetAdsFault:
      return adsFault(state, action.payload);
    default:
      return state;
  }
}


