import { Action } from "@ngrx/store";
import {
		FaqModel,
} from "../../domain";

export enum FeedActionTypes {
		GetStats = "[Feed] GetFeed",
    FeedStatsSuccess = "[Feed] FeedStatsSuccess"
}

export class GetStats implements Action {
		readonly type = FeedActionTypes.GetStats;

		constructor() {
		}
}

export class FeedStatsSuccess implements Action {
		readonly type = FeedActionTypes.FeedStatsSuccess;

		constructor(public payload: any) {
		}
}



export type FeedActions =
		| GetStats
    | FeedStatsSuccess
		;
