export interface IBrandDetails {
  domain: string
  customDomain: string
  apiUrl: string
  active: boolean
  users: IFeatureUsers
  features: IFeatures
  maintenance_mode: IMaintenance
  subscription_active: boolean,
  subscription_active_message: string,
}

export interface IFeatureUsers {
  startups: boolean
  investors: boolean
  mentors: boolean
  corporates: boolean
  service_providers: boolean
  government: boolean
  partners: boolean
  program_offices: boolean
  program_offices_registration: boolean
  individuals: boolean
  individual_registration?:boolean

  // "individual_registration": false,
            "service_providers_registration"?:boolean,
            "startups_registration"?:boolean,
            "investors_registration"?:boolean,
            "corporates_registration"?:boolean,
}

export interface IFeatures {
  facility_enable_checkin_checkout?: boolean
  individuals_title:string
  certificates: boolean
  registration_invite_only: boolean
  registration_turnoff_programs: boolean
  online_meetings: boolean
  calendar: boolean
  profile_comparison: boolean
  chat: boolean
  startup_kit: boolean
  jobs: boolean
  business_challenges: boolean
  custom_forms: boolean
  mentor_hours: boolean
  community_feed: boolean
  startup_supporting_documents: boolean
  syndicate: boolean
  financials_form: boolean
  startup_onboarding_modal: boolean
  looking_for_section: boolean
  glossary: boolean
  resources_downloads: boolean
  connections: boolean
  registration_enterprise_sales_popup: boolean
  individual_investor: boolean
  post_revenue_fields: boolean
  email_otp_verification: boolean
  mobile_otp_verification: boolean
  whatsapp_otp_verification: boolean
  show_dashboard: boolean
  show_ecosystem: boolean
  video_pitch_mandatory: boolean
  profiles_locked: boolean
  new_search_layout: boolean
  main_website_url: string
  multiple_profiles: boolean
  logout_on_rejection: boolean;
  milestone_management: boolean;
  ticket_management: boolean;
  video_types: 'both' | 'upload_video' | 'power_pitch'
  menu_type: MenuTypes
  sidebar_color: SideBarColors
  direct_accept_connection_enabled: boolean;
  filing_email_address_enabled: boolean;
  can_deactivate_profile: boolean;
  can_delete_profile: boolean;
  reports_downloads: boolean;
  deeptech_news: boolean;
  webinar_videos: boolean;
  mentor_hours_title: string
  mentors_title:string
  community_feed_title: string
  milestone_management_title: string
  growth_metrics_title: string
  business_challenges_title: string
  events_title: string

  startup_kit_title: string
  resources_downloads_title: string
  glossary_title: string

  ticket_management_title: string
  reports_downloads_title: string
  growth_metrics_duration: string,
  // "quarterly"
  deeptech_news_title: string
  webinar_videos_title: string
  growth_metrics: boolean;
  events: boolean;
  program_offices_title:string
  primary_industry: boolean;
  elastic_search: boolean,
  search_type: "elastic" | 'normal',
  currency: string;
  chat_type: 'inhouse' | 'comet_chat';

  startup_to_mentor_connection_questions: string;
  ask_startup_to_mentor_connection_question: boolean

  partners_title: string;
  service_providers_title: string;
  disable_startup_profile_update: boolean
  startup_show_programs: boolean

  intellectual_property_section: boolean;
  investment_banking_section: boolean;
  company_identification_cin: boolean;
  company_identification_gst: boolean;
  company_identification_dpiit: boolean;
  limited_access: boolean;
  enable_sub_industries: boolean
  meeting_time_slot_difference_in_mins: number

  external_sign_in_enabled: boolean;
  external_sign_in_name: string;
  programs_public_view?: boolean
  vs_programs_public_view?: boolean
  industries_technologies_section: boolean
  mentorship_areas_new_layout: boolean
  growth_metrics_financial_year_start_month:number
  // individual_registration: boolean
  ip_management: boolean;

  corporates_enagement_form: boolean;
  new_dashboard_layout?: boolean;

  facility_management: boolean;
  new_dashboard_header_color?: string
  new_dashboard_community_post_view?: 'inline' | 'default'
}

export enum MenuTypes {
  TOP = 'top',
  SIDE = 'side',
}

export enum SideBarColors {
  LIGHT = 'light',
  DARK = 'dark',
}
export interface IMaintenance {
  id: number
  title: string
  message: string
  date: string
  startTime: string
  endTime: string
  active: boolean
  maintenance_on: boolean
  actual_start_time: any
  actual_end_time: any
  notes: string
}
