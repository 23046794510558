import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import {
  GlossaryModel,
} from "../../domain";
import { GlossaryService } from "../../service/glossary.service";
import * as GlossaryActions from "./glossary.action";
import { GlossaryActionTypes } from "./glossary.action";

@Injectable()
export class GlossaryEffect {

  getGlossary$ = createEffect(() => this.actions$.pipe(
    ofType<GlossaryActions.GetGlossary>(GlossaryActionTypes.GetGlossary),
    exhaustMap(() =>
      this.glossaryService.getGlossary().pipe(
        map((data: GlossaryModel) => new GlossaryActions.GlossarySuccess(data)),
        catchError((err: HttpErrorResponse) => of(new GlossaryActions.GlossaryFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private glossaryService: GlossaryService) {
  }
}
