import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CorporateDashboardState } from "./corporate.dashboard.reducer";

export const selectCorporateDashboardState = createFeatureSelector<CorporateDashboardState>("corporateDashboard");

export const getCorporateProfileCompleteness = createSelector(
  selectCorporateDashboardState,
  (state: CorporateDashboardState) => state.corporateProfileCompleteness
)

export const getCorporateDashboard = createSelector(
  selectCorporateDashboardState,
  (state: CorporateDashboardState) => state.corporateDashboard
)
