import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AuthState } from "./auth.reducer";

export const selectAuthState = createFeatureSelector<AuthState>("auth");


export const getToken = createSelector(
    selectAuthState,
    (state: AuthState) => state.user!=null ? state.user.accessToken : null
);

export const getIsLoggedIn = createSelector(
    selectAuthState,
    (state: AuthState) => !!state.user
);

export const getLoggedInUser = createSelector(
  selectAuthState,
  (state: AuthState) => state.user
);

export const getError = createSelector(
    selectAuthState,
    (state: AuthState) => state.error
);

export const getPending = createSelector(
    selectAuthState,
    (state: AuthState) => state.pending
);
