import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperSliderComponent } from './swiper-slider/swiper-slider.component';
import { BannerSwiperSliderComponent } from './banner-swiper-slider/banner-swiper-slider.component';
import { SwiperModule } from 'swiper/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PipesModule } from 'src/app/shared/pipes/pipes.module';



@NgModule({
  declarations: [SwiperSliderComponent, BannerSwiperSliderComponent],
  imports: [
    CommonModule,
    SwiperModule,
    NgbModule,
    PipesModule
  ],
  exports: [SwiperSliderComponent, BannerSwiperSliderComponent]
})
export class SliderModule { }
