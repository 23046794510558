export const environment = {
  production: false,
  apiEndpoints: {
    baseUrl: "https://api-dev.tenants.sanchiconnect.com/",
  },
  imageKitBaseUrl: 'https://ik.imagekit.io/3buq5bm7v/',
  mixPanelKey: 'b3a983a33841557e43c39221dd732b46',
  blogs: 'https://sanchiconnect.com/blog/wp-json/wp/v2/posts?_fields=author,id,title,link,excerpt,jetpack_featured_media_url',
  feture_supportingDocument: false
};
