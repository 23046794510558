import { Action } from "@ngrx/store";
import {
    FaqModel,
} from "../../domain";
import { IPitchDeck } from "../../domain/pitch-deck-recorder.model";

export enum PitchDeckActionTypes {
    GetPitchDeck = "[PitchDeck] GetPitchDeck",
    PitchDeckSuccess = "[PitchDeck] PitchDeckSucess",
    PitchDeckFault = "[PitchDeck] PitchDeckFault",

    DeletePitchDeck ="[PitchDeck] DeletePitchDeck",
    DeletePitchDeckSucess ="[PitchDeck] DeletePitchDeckSucess",

    UploadPitchDeck ="[PitchDeck] UploadPitchDeck",
    UploadPitchDeckSuccess ="[PitchDeck] UploadPitchDeckSuccess",

    UpdateScript="[PitchDeck] UpdateScript",
    UpdateScriptSuccess ="[PitchDeck] UpdateScriptSuccess",

  }

export class GetPitchDeck implements Action {
    readonly type = PitchDeckActionTypes.GetPitchDeck;

    constructor() {
    }
}

export class DeletePitchDeck implements Action {
    readonly type = PitchDeckActionTypes.DeletePitchDeck;

    constructor(public categoryId, public topicId ) {
    }
}

export class UploadPitchDeck implements Action {
    readonly type = PitchDeckActionTypes.UploadPitchDeck;

    constructor(public file,public categoryId, public topicId, public videoTime) {
    }
}


export class UploadPitchDeckSuccess implements Action {
    readonly type = PitchDeckActionTypes.UploadPitchDeckSuccess;

    constructor(public payload: IPitchDeck) {
    }
}

export class PitchDeckSuccess implements Action {
    readonly type = PitchDeckActionTypes.PitchDeckSuccess;

    constructor(public payload: IPitchDeck) {
    }
}

export class DeletePitchDeckSuccess implements Action {
    readonly type = PitchDeckActionTypes.DeletePitchDeckSucess;

    constructor(public payload: IPitchDeck) {
    }
}

export class UpdateScript implements Action {
    readonly type = PitchDeckActionTypes.UpdateScript;

    constructor(public categoryId, public topicId, public script) {
    }
}

export class UpdateScriptSuccess implements Action {
    readonly type = PitchDeckActionTypes.UpdateScriptSuccess;

    constructor(public payload: IPitchDeck) {
    }
}

export class PitchDeckFault implements Action {
    readonly type = PitchDeckActionTypes.PitchDeckFault;

    constructor(public payload: string) {
    }
}



export type PitchActions =
    | GetPitchDeck
    | PitchDeckSuccess
    | PitchDeckFault
    | DeletePitchDeck
    | DeletePitchDeckSuccess
    | UploadPitchDeck
    | UploadPitchDeckSuccess
    | UpdateScript
    | UpdateScriptSuccess
    ;
