import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError, map, tap,
} from "rxjs/operators";
import { ApiEndpointService } from "./api-endpoint.service";
import { AuthService } from "./auth.service";
import { GlobalService } from "./global.service";
import * as GlobalActions from "../state/global/global.action";

import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { StorageService } from "src/app/shared/utils/storage.service";

@Injectable({
  providedIn: "root"
})
export class TenantService {

  constructor(private http: HttpClient, private toastService: ToastAlertService,
    private globalService: GlobalService,
    private authService: AuthService,
    private storageService: StorageService,
    private store: Store) { }



  public getTenantDetails(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.VERIFY_TENANT) + location.hostname;
    return this.http.get(url).pipe(
      tap((res: any) => {
        console.log('getPartnerDetails==>', res)
        if(res?.data) {
          this.store.dispatch(new GlobalActions.SetBrandDetails(res?.data))
          this.storageService.setObject(StorageService.storageKeys.brandDetails, res?.data)
        }
      }),
      catchError((fault: HttpErrorResponse) => {
        // this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading logo!'), 'error');
        // console.warn(`save inverstor Uplogo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }


}
