import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FeedState } from "./feed.reducer";

export const selectFeedState = createFeatureSelector<FeedState>("feed");

export const getFeedStats = createSelector(
    selectFeedState,
    (state: FeedState) => state.feedStats
);

