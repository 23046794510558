import { ProgramOfficeInformationResponseModel } from "../../domain";
import {
  ProgramOfficeInfoAction,
  ProgramOfficeInfoActionTypes
} from "./program-office-members-info.action";

export interface ProgramOfficeInfoState {
  programOffice: ProgramOfficeInformationResponseModel,
  error: string | null
}

export const initialState: ProgramOfficeInfoState = {
  programOffice: null,
  error: ""

};

function programOfficeSuccess(state: ProgramOfficeInfoState = initialState, data: ProgramOfficeInformationResponseModel): ProgramOfficeInfoState {
  return {
    ...state,
    programOffice: data,
    error: ""
  };
}

function programOfficeFault(state: ProgramOfficeInfoState = initialState, data: string): ProgramOfficeInfoState {
  return {
    ...state,
    programOffice: null,
    error: data
  };
}

export function programOfficeReducer(state: ProgramOfficeInfoState = initialState, action: ProgramOfficeInfoAction): ProgramOfficeInfoState {
  switch (action.type) {
    case ProgramOfficeInfoActionTypes.ProgramOfficeInfoSuccess:
      return programOfficeSuccess(state, action.payload);
    case ProgramOfficeInfoActionTypes.ProgramOfficeInfoFault:
      return programOfficeFault(state, action.payload);
    default:
      return state;
  }
}


