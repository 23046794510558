import {
  HttpClient,
  HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import {
  Observable,
  throwError
} from "rxjs";
import {
  catchError, map, tap
} from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { IProductInfo } from "../domain/product-information.model";
import { IStartUpInfoBase } from "../domain/startup.model";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as ProfileActions from "src/app/core/state/profile/profile.action";

@Injectable({
  providedIn: "root"
})
export class StartUpService {

  constructor(private http: HttpClient, private toastService: ToastAlertService, public globalService: GlobalService, private store: Store) { }

  public saveStartUpInfo(payload: IStartUpInfoBase): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_INFO);
    console.info(`saving start up info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error saving!'), 'error');
        console.warn(`saveStartUpInfo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public uploadLogo(logo: File, refetchProfile = false): Observable<any> {
    const payload = new FormData();
    payload.append('file', logo);
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_LOGO_UPLOAD);
    console.info(`saving start up logo`, payload);
    return this.http.post(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
        this.store.dispatch(new ProfileActions.GetProfile());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.message || 'Error while uploading logo', 'error');
        console.warn(`saveStartUplogo( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public getStartUpInfo(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.STARTUP_INFO);
    return this.http.get(url).pipe(
      map((response: any) => {
        console.info(`startupinfo GET ( Received startupinfo `);
        return response.data;
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error getting startup info!'), 'error');
        console.warn(`startupinfoFault( ${fault.error.message} )`);
        return throwError(() => fault);
      })
    );
  }


  /**
   * @param payload array of ids of industries and technologies, to save for startup
   */
  public saveIndustriedAndTechs(payload: { industryDomainIds: number[], technologyDomainIds: number[], industryDomainPrimaryId: number,industrySubCategoryDomainIds: number[],otherIndustryDomains?:string[],otherTechnologyDomains?:string[] }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDUSTRY_TECHNOLOGY);
    console.info(`saving tech and industries info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`tech and industries ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public saveIndustries(payload: { industryDomainIds: number[],industryDomainPrimaryId?: number,industrySubCategoryDomainIds?: number[]  }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDUSTRY_TECHNOLOGY);
    console.info(`saving tech info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error saving!'), 'error');
        // console.warn(`tech and industries ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public saveBusinessModels(payload: { businessModelIds: number[] }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDUSTRY_TECHNOLOGY);
    console.info(`saving bm info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error saving!'), 'error');
        // console.warn(`bms ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }



  public saveMentorshipAreas(payload: { mentorshipAreas: number[] }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDUSTRY_TECHNOLOGY);
    console.info(`saving bm info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error saving!'), 'error');
        // console.warn(`bms ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public saveTechs(payload: { technologyDomainIds: number[] }): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.INDUSTRY_TECHNOLOGY);
    console.info(`saving industries info`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        console.warn(`tech and industries ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }


  public saveProductInfo(payload: Partial<IProductInfo>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.PRODUCT_INFO);
    console.info(`saving ProductInfo`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.error?.[0] || fault?.error?.message || 'Error while saving product info!', 'error');
        console.warn(`ProductInfo save error ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

  public saveFinancials(payload: Partial<IProductInfo>): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.FINANCIALS);
    console.info(`saving FINANCIALS`, payload);
    return this.http.patch(url, payload).pipe(
      tap(() => {
        this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
      }),
      map((e: any) => e.data),
      catchError((fault: HttpErrorResponse) => {
        this.toastService.showToast(fault?.error?.error?.[0] || fault?.error?.message || 'Error while saving financials info!', 'error');
        console.warn(`FINANCIALS save error ( ${fault?.error?.message} )`);
        return throwError(() => fault);
      })
    );
  }

}
