import { Action } from "@ngrx/store";
import {
  MentorInformationResponseModel,
} from "../../domain";

export enum MentorInfoActionTypes {
  GetMentorInfo = "[Mentor] GetMentorInfo",
  GetMentorInfoSuccess = "[Mentor] GetMentorInfoSuccess",
  MentorInfoFault = "[Mentor] MentorInfoFault",
}

export class GetMentorInfo implements Action {
  readonly type = MentorInfoActionTypes.GetMentorInfo;
}

export class MentorInfoSuccess implements Action {
  readonly type = MentorInfoActionTypes.GetMentorInfoSuccess;

  constructor(public payload: MentorInformationResponseModel) {
  }
}

export class MentorInfoFault implements Action {
  readonly type = MentorInfoActionTypes.MentorInfoFault;

  constructor(public payload: string) {
  }
}



export type MentorInfoAction =
  | GetMentorInfo
  | MentorInfoSuccess
  | MentorInfoFault
  ;
