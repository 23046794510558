import { Action } from "@ngrx/store";
import { IProductInfo } from "../../domain/product-information.model";
import { IProfileDetails, IProfileType } from "../../domain/profile.model";

export enum ProfileActionTypes {
  GetProfile = "[Profile] GetProfile",
  ProfileSuccess = "[Profile] ProfileSuccess",
  ProfileFault = "[Profile] ProfileFault",
  ClearProfile = '[Profile] ClearProfile',



  GetProfileTypes = "[Profile] GetProfileTypes",
  GetProfileTypesSuccess = "[Profile] GetProfileTypesSuccess",
}

export class GetProfile implements Action {
  readonly type = ProfileActionTypes.GetProfile;

  constructor(public reload: boolean  = false) { }

}

export class ProfileSuccess implements Action {
  readonly type = ProfileActionTypes.ProfileSuccess;

  constructor(public payload: IProfileDetails) { }
}

export class ProfileFault implements Action {
  readonly type = ProfileActionTypes.ProfileFault;

  constructor(public payload: string) { }
}


export class GetProfileTypes implements Action {
  readonly type = ProfileActionTypes.GetProfileTypes;
}

export class GetProfileTypesSuccess implements Action {
  readonly type = ProfileActionTypes.GetProfileTypesSuccess;

  constructor(public payload: IProfileType[]) { }
}


export class ClearProfile implements Action {
  readonly type = ProfileActionTypes.ClearProfile;
}

export type ProfileActions =
  | GetProfile
  | ProfileSuccess
  | ProfileFault
  | ClearProfile
  | GetProfileTypes
  | GetProfileTypesSuccess
  ;
