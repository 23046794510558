import {
    HttpClient,
    HttpErrorResponse
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
    Observable,
    throwError
} from "rxjs";
import {
    catchError,
    map
} from "rxjs/operators";
import {
    FaqModel
} from "../domain";
import { ApiEndpointService } from "./api-endpoint.service";

@Injectable({
    providedIn: "root"
})
export class GlossaryService {

    constructor(private http: HttpClient) {

    }

    public getGlossary(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GET_GLOSSARY);
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                return throwError(() => fault);
            })
        );
    }

    public getGlossaryByWord(word: string): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.GET_GLOSSARY + `/${word}`);
        return this.http.get(url).pipe(
            map((response: any) => {
                return response.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`loginFault( ${fault.error.message} )`);
                return throwError(() => fault);
            })
        );
    }


}
