<div class="modal-header">
  <h3 class="modal-title">Create Startup Profile</h3>
  <button type="button" class="btn-close" aria-label="Close" (click)="closeModal()"></button>
</div>
<div class="modal-body">
  <div>
    <div class="w-100">


      <div class="chooseInvestorType" *ngIf="brandDetails.features.looking_for_section">
        <div class="pb-5 pb-lg-5 ">
          <h2 class="fw-bolder d-flex align-items-center text-dark fs-5">
            looking for
          </h2>
        </div>

        <div class="fv-row fv-plugins-icon-container">
          <div class="row">
            <ng-container *ngFor="let option of lookingForOptions">
              <div class="col-lg-6 mb-5" *ngIf="option.show">
                <label class="btn btn-outline btn-outline-dashed d-flex flex-stack text-start p-5">
                  <div class="d-flex align-items-center me-2">
                    <div class="form-check form-check-custom form-check-solid form-check-primar me-2">
                      <input class="form-check-input" type="checkbox" name="servicesLookingFor"
                        value="{{ option.value }}" [(ngModel)]="option.selected" (ngModelChange)="onChangeLookingFor()"
                        [ngModelOptions]="{ standalone: true }" />
                    </div>
                    <div class="flex-grow-1">
                      <h2 class="d-flex align-items-center fs-6 fw-bolder flex-wrap pt-1">
                        {{ option.label }}
                      </h2>
                    </div>
                  </div>
                </label>

                <div class="fv-plugins-message-container invalid-feedback"></div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>


    </div>




    <div class="row mt-4" [formGroup]="accountInformationForm" *ngIf="accountInformationForm">
      <div class="col-12">
        <div class=" mb-7">
          <label for="companyName  ms-3" class="mb-2">Company name</label>
          <input type="text" class="form-control" id="companyName" placeholder="Company Name"
            formControlName="companyName" [ngClass]="{
              'is-invalid':
                !accountInformationForm.get('companyName').valid &&
                (accountInformationForm.get('companyName')?.touched ||
                  accountInformationForm.get('companyName')?.dirty),
              'is-valid':
                accountInformationForm.get('companyName').value &&
                accountInformationForm.get('companyName').valid &&
                (accountInformationForm.get('companyName')?.touched ||
                  accountInformationForm.get('companyName')?.dirty)
            }" />
          <span class="invalid-feedback d-block" *ngIf="
              accountInformationForm.get('companyName')?.hasError('required') &&
              (accountInformationForm.get('companyName')?.touched ||
                accountInformationForm.get('companyName')?.dirty)
            ">
            Company name required
          </span>
        </div>
      </div>


    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-sm btn-theme text-white ls-2 text-uppercase fw-boldest" [disabled]="isDisabledApply"
      (click)="onApplyBtnClick()">
      Submit
    </button>
    <button class="btn btn-sm btn-light ls-2 text-uppercase fw-boldest btn-active-light-primary" (click)="handleCancle()">
      Cancel
    </button>
  </div>
