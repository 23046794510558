import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, throwError } from "rxjs";
import { catchError, map, tap } from "rxjs/operators";
import { ToastAlertService } from "src/app/shared/utils/toast.service";
import { ApiEndpointService } from "./api-endpoint.service";
import { GlobalService } from "./global.service";
import * as StartupDashBoardActions from "src/app/core/state/startup-dashboard/startup.dashboard.action";
import * as StartupActions from "src/app/core/state/startup/startup.action";
import { IJobs } from "../domain/jobs.model";
import * as JobsAction from 'src/app/core/state/jobs';
import { Router } from "@angular/router";
import { ACCOUNT_TYPE } from "../domain";

@Injectable({
    providedIn: "root"
})
export class MetricsService {

    constructor(private http: HttpClient, private toastService: ToastAlertService, private globalService: GlobalService, private store: Store,
      private router: Router) { }


    public getMetricsTypes(): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS) + 'types/all';
        return this.http.get(url, {  }).pipe(
            map((response: any): IJobs[] => {
                return response?.data;
            }),
            catchError((fault: HttpErrorResponse) => {
                console.warn(`Jobs( ${fault.error.message} )`);
                this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
                return throwError(() => fault);
            })
        )
    }

    public getMetrics(): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS) + 'all';
      return this.http.get(url, {  }).pipe(
          map((response: any): IJobs[] => {
              return response?.data;
          }),
          catchError((fault: HttpErrorResponse) => {
              console.warn(`Jobs( ${fault.error.message} )`);
              this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
              return throwError(() => fault);
          })
      )
  }


  public getMetricsOfReviewee(startupId:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + `reviewer/metrics/${startupId}/details`;
    return this.http.get(url, {  }).pipe(
        map((response: any) => {
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
            return throwError(() => fault);
        })
    )
}



  public getReviews(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + 'reviewer/metrics-list';
    return this.http.get(url, {  }).pipe(
        map((response: any): IJobs[] => {
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
            return throwError(() => fault);
        })
    )
  }


  public getReviewers(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + 'reviewers';
    return this.http.get(url, {  }).pipe(
        map((response: any): IJobs[] => {
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
            return throwError(() => fault);
        })
    )
  }


  public getCharts(): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + 'charts';
    return this.http.get(url, {  }).pipe(
        map((response: any): IJobs[] => {
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
            return throwError(() => fault);
        })
    )
  }

  public getReviewsCharts(startupId:string): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + `reviewer/metrics/${startupId}/charts`;
    return this.http.get(url, {  }).pipe(
        map((response: any): IJobs[] => {
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
            return throwError(() => fault);
        })
    )
  }

  public updateReviewers({reviewersUUIDs}:{reviewersUUIDs:Array<string>}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + 'reviewers';
    return this.http.put(url, { reviewersUUIDs }).pipe(
        map((response: any): IJobs[] => {
          this.toastService.showToast('Reviewers updated successfully');
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while inviting reviewer', 'error');
            return throwError(() => fault);
        })
    )
  }


  public inviteReviewers(payload:{emails:string[], userType: ACCOUNT_TYPE}): Observable<any> {
    const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS,true) + 'reviewers/invite';
    return this.http.post(url, { ...payload }).pipe(
        map((response: any): IJobs[] => {
            this.toastService.showToast('Invited successfully');
            return response?.data;
        }),
        catchError((fault: HttpErrorResponse) => {
            console.warn(`Jobs( ${fault.error.message} )`);
            this.toastService.showToast(fault?.error?.message || 'Error while inviting reviewer', 'error');
            return throwError(() => fault);
        })
    )
  }

  //   public completeHiringProfile(payload: {
  //       companySize?: string,
  //       fundingStatus?: string
  //   }): Observable<any> {
  //       const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.COMPLETE_HIRING_PROFILE);
  //       return this.http.patch(url, payload).pipe(
  //           tap(() => {
  //               this.store.dispatch(new StartupDashBoardActions.GetProfileCompleteness());
  //               this.store.dispatch(new StartupActions.GetStartUpInfo);
  //           }),
  //           map((response: any): any => {
  //               return response;
  //           }),
  //           catchError((fault: HttpErrorResponse) => {
  //               this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating completing hiring profile'), 'error');
  //               return throwError(() => fault);
  //           })
  //       );
  //   }

    public saveMetrics(payload): Observable<any> {
        const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS);
        return this.http.post(url, {metrics: payload}).pipe(
            tap(() => {

            }),
            map((response: any): any => {
                return response;
            }),
            catchError((fault: HttpErrorResponse) => {
                this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving metrics'), 'error');
                return throwError(() => fault);
            })
        );
    }

    public requestUpdate(payload): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS) + 'request-update';
      return this.http.patch(url, payload).pipe(

          map((response: any): any => {
            this.toastService.showToast('Update request sent', 'success')
              return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving metrics'), 'error');
              return throwError(() => fault);
          })
      );
    }

    public patchMetrics(payload): Observable<any> {
      const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.MATRICS);
      return this.http.patch(url, {metrics: payload}).pipe(
          tap(() => {

          }),
          map((response: any): any => {
              return response;
          }),
          catchError((fault: HttpErrorResponse) => {
              this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while saving metrics'), 'error');
              return throwError(() => fault);
          })
      );
  }


  //   public updateJob(id, payload): Observable<any> {
  //       const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `${id}/details`);
  //       return this.http.patch(url, payload).pipe(
  //           tap(() => {
  //               this.store.dispatch(new JobsAction.GetJobsList());
  //           }),
  //           map((response: any): any => {
  //               return response;
  //           }),
  //           catchError((fault: HttpErrorResponse) => {
  //               this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while updating job'), 'error');
  //               return throwError(() => fault);
  //           })
  //       );
  //   }




  //   public getPublicJobs(action): Observable<any> {
  //     const filters = action.filters || {}
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_PUBLIC);
  //     return this.http.get(url, { params: filters }).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
  //             return throwError(() => fault);
  //         })
  //     )
  //   }

  //   public getPublicJobDetails(uuid): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS_PUBLIC_DETAILS) + `/${uuid}`;
  //     return this.http.get(url).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching jobs', 'error');
  //             if(fault.status === 404) {
  //               this.router.navigate(['/search/jobs'])
  //             }
  //             return throwError(() => fault);
  //         })
  //     )
  //   }


  //   public applyForJob(id, payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `${id}/apply`);
  //     return this.http.post(url, payload).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  // }

  //   public applyForJobResume(applicationUUID, payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `apply/${applicationUUID}/upload/resume`);

  //     const formData = new FormData()
  //     formData.append('file', payload.file)
  //     formData.append('applicationUUID', payload.applicationUUID)

  //     return this.http.post(url, formData).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  //   }




  //   public resumeSubmit(payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RESUME + `submit`);
  //     return this.http.post(url, payload).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while submitting details'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  // }

  //   public resumeUpload(payload): Observable<any> {
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.RESUME + `upload`);

  //     const formData = new FormData()
  //     formData.append('file', payload.file)

  //     return this.http.post(url, formData).pipe(
  //         map((response: any): any => {
  //             return response;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while uploading resume'), 'error');
  //             return throwError(() => fault);
  //         })
  //     );
  //   }

  //   public getAppliedJobsList(): Observable<any> {
  //     // const filters = action.filters || {}
  //     // console.log({ filters }, '==============')
  //     const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS) + 'applied/list';
  //     return this.http.get(url).pipe(
  //         map((response: any): IJobs[] => {
  //             return response?.data;
  //         }),
  //         catchError((fault: HttpErrorResponse) => {
  //             console.warn(`Jobs( ${fault.error.message} )`);
  //             this.toastService.showToast(fault?.error?.message || 'Error while fetching applied jobs', 'error');
  //             return throwError(() => fault);
  //         })
  //     )
  // }



  // public uploadAttachment(payload): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS + `upload/attachment`);

  //   const formData = new FormData()
  //   formData.append('file', payload.file)

  //   return this.http.post(url, formData).pipe(
  //       map((response: any): any => {
  //           return response;
  //       }),
  //       catchError((fault: HttpErrorResponse) => {
  //           this.toastService.showToast(this.globalService.getErrorMessage(fault, 'Error while applying for job'), 'error');
  //           return throwError(() => fault);
  //       })
  //   );
  // }

  // public getInterviews(userType:'job-seeker' | 'startup'): Observable<any> {
  //   const url = ApiEndpointService.getEndpoint(ApiEndpointService.ENDPOINT.JOBS) + 'interviews/' + userType;
  //   return this.http.get(url).pipe(
  //       map((response: any): IJobs[] => {
  //           return response?.data;
  //       }),
  //       catchError((fault: HttpErrorResponse) => {
  //           console.warn(`Jobs( ${fault.error.message} )`);
  //           this.toastService.showToast(fault?.error?.message || 'Error while fetching interviews', 'error');
  //           return throwError(() => fault);
  //       })
  //   )
  // }


}
