import { Action } from "@ngrx/store";
import {
  ServiceProviderInformationResponseModel,
} from "../../domain";

export enum ServiceProviderInfoActionTypes {
  GetServiceProviderInfo = "[ServiceProvider] GetServiceProviderInfo",
  GetServiceProviderInfoSuccess = "[ServiceProvider] etServiceProviderInfoSuccess",
  ServiceProviderInfoFault = "[ServiceProvider] ServiceProviderInfoFault",
}

export class GetServiceProviderInfo implements Action {
  readonly type = ServiceProviderInfoActionTypes.GetServiceProviderInfo;
}

export class ServiceProviderInfoSuccess implements Action {
  readonly type = ServiceProviderInfoActionTypes.GetServiceProviderInfoSuccess;

  constructor(public payload: ServiceProviderInformationResponseModel) {
  }
}

export class ServiceProviderInfoFault implements Action {
  readonly type = ServiceProviderInfoActionTypes.ServiceProviderInfoFault;

  constructor(public payload: string) {
  }
}



export type ServiceProviderInfoAction =
  | GetServiceProviderInfo
  | ServiceProviderInfoSuccess
  | ServiceProviderInfoFault
  ;
