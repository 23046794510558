export interface ISupportingDocuments {
  file: string;
  fileName: string;
  uuid: string;
  documentType?: PitchDocumentTypes;
}

export interface IElevatorPitch {
  elevatorPitch: string;
  fileName?: string;
  pitchDocument?: string;
}


export enum PitchDocumentTypes {
  FUNDRAISING_PITCH = 'fundraising-pitch',
  SALES_PITCH = 'sales-pitch',
  HIRING_PITCH = 'hiring-pitch',
}

export const PitchTypeToCategoryId = {
  [PitchDocumentTypes.FUNDRAISING_PITCH]: 1,
  [PitchDocumentTypes.SALES_PITCH]: 2,
  [PitchDocumentTypes.HIRING_PITCH]: 3,
}
