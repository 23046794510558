import { Action } from "@ngrx/store";
import { IInvestorsCompareResponse } from "../../domain/startup-investor-compare.model";

export enum InvestorCompareActionTypes {
    // Investor Compare
    GetInvestorCompare = "[Investor Compare] GetInvestorCompare",
    GetInvestorCompareSuccess = "[Investor Compare] GetInvestorCompareSuccess",
    GetInvestorCompareFault = "[Investor Compare] GetInvestorCompareFault",
}

export class GetInvestorCompare implements Action {
    readonly type = InvestorCompareActionTypes.GetInvestorCompare;
    constructor(public payload: string) { }
}

export class GetInvestorCompareSuccess implements Action {
    readonly type = InvestorCompareActionTypes.GetInvestorCompareSuccess;
    constructor(public payload: IInvestorsCompareResponse) { }
}

export class GetInvestorCompareFault implements Action {
    readonly type = InvestorCompareActionTypes.GetInvestorCompareFault;
    constructor(public payload: string) { }
}

export type InvestorCompareActions =
    | GetInvestorCompare
    | GetInvestorCompareSuccess
    | GetInvestorCompareFault;
