import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProgramOfficeInfoState } from "./program-office-members-info.reducer";

export const selectProgramOfficeState = createFeatureSelector<ProgramOfficeInfoState>("programOffice");

export const getProgramOfficeInfo = createSelector(
  selectProgramOfficeState,
  (state: ProgramOfficeInfoState) => state.programOffice
);

