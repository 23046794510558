import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipe.safehtml';
import { DecodeHtmlStringPipe } from './pipe.decodeHtmlString';
import { NumberFormatterPipe } from './number-formatter.pipe';
import { ImagekitUrlAppenderPipe } from './imagekit-url-appender.pipe';
import { CheckServicePipe } from './check-service.pipe';
import { FullWebsiteURLPipe } from './pipe.getFullWebsiteURL';
import { AssetsImagekitUrlAppenderPipe } from './asset-imagekit-url-appender.pipe';
import { NumberToWordsPipe } from './number-to-words.pipe';
import { FutureSlotsPipe } from './future-slots.pipe';
import { TwentyFourHoursToAmPmPipe } from './twenty-four-hours-to-am-pm.pipe';
import { TruncatePipe } from './truncate.pipe';
import { RemoveUnderscorePipe } from './pipe.custom.underscore';
import { AccountTypeToLabelPipe } from './account-type-to-label.pipe';

@NgModule({
    declarations: [
        SafeHtmlPipe,
        DecodeHtmlStringPipe,
        NumberFormatterPipe,
        ImagekitUrlAppenderPipe,
        CheckServicePipe,
        FullWebsiteURLPipe,
        AssetsImagekitUrlAppenderPipe,
        NumberToWordsPipe,
        FutureSlotsPipe,
        TwentyFourHoursToAmPmPipe,
        TruncatePipe,
        RemoveUnderscorePipe,
        AccountTypeToLabelPipe
    ],
    imports: [
        CommonModule,
    ],
    exports: [
      SafeHtmlPipe, DecodeHtmlStringPipe, NumberFormatterPipe,
      ImagekitUrlAppenderPipe, CheckServicePipe, FullWebsiteURLPipe,
      AssetsImagekitUrlAppenderPipe, NumberToWordsPipe, FutureSlotsPipe, TwentyFourHoursToAmPmPipe,
      TruncatePipe,
      RemoveUnderscorePipe, AccountTypeToLabelPipe
    ]
})
export class PipesModule { }
