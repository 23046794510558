import { Action } from "@ngrx/store";
import {
  ProgramOfficeInformationModel,
  ProgramOfficeInformationResponseModel,
} from "../../domain";

export enum ProgramOfficeInfoActionTypes {
  ProgramOfficeInfo = "[ProgramOffice] ProgramOfficeInfo",
  ProgramOfficeInfoSuccess = "[ProgramOffice] ProgramOfficeInfoSuccess",
  ProgramOfficeInfoFault = "[ProgramOffice] ProgramOfficeInfoFault",
}

export class ProgramOfficeInfo implements Action {
  readonly type = ProgramOfficeInfoActionTypes.ProgramOfficeInfo;
}

export class ProgramOfficeInfoSuccess implements Action {
  readonly type = ProgramOfficeInfoActionTypes.ProgramOfficeInfoSuccess;

  constructor(public payload: ProgramOfficeInformationResponseModel) {
  }
}

export class ProgramOfficeInfoFault implements Action {
  readonly type = ProgramOfficeInfoActionTypes.ProgramOfficeInfoFault;

  constructor(public payload: string) {
  }
}


export type ProgramOfficeInfoAction =
  | ProgramOfficeInfo
  | ProgramOfficeInfoSuccess
  | ProgramOfficeInfoFault
  ;
