import { StorageService } from "src/app/shared/utils/storage.service";
import { IBrandDetails } from "../../domain/brand.model";
import { IGlobalSettings } from "../../domain/settings.model";
import {
  FiltersActions,
  FiltersActionTypes
} from "./filters.action";
import { IFilter } from "../../domain/filters.model";

type RecordType = Array<any>
interface IStartupOptions {
  industries: RecordType,
  investment_stages: RecordType,
  technologies: RecordType,
  domain_areas: RecordType,
  business_models: RecordType,
  product_stages: RecordType,
  funding_stages: RecordType,
  investment_mechanisms: RecordType,
  revenue_stages: RecordType
}


interface IInvestorOptions {
  industries: RecordType,
  investment_mechanisms: RecordType,
  investment_preferences: RecordType,
  investment_stages: RecordType,
}


interface IMentorOptions {
  industries: RecordType,
  domain_areas: RecordType,
  technologies: RecordType,
}

interface ICorporateOptions {
  industries: RecordType,
}

interface IServiceProviderOptions {
  industries: RecordType,
  service_provider_types: RecordType,
  service_provider_categories: RecordType,
}

export interface FiltersState {
  filters: IFilter[],
  error: string | null,

  startupOptions: IStartupOptions
  investorOptions: IInvestorOptions

  mentorOptions: IMentorOptions
  corporateOptions: ICorporateOptions
  serviceProviderOptions: IServiceProviderOptions;


  appliedSavedFilter: IFilter
}

export const initialState: FiltersState = {
  filters: null,
  error: "",

  startupOptions: null,
  investorOptions: null,

  mentorOptions: null,
  corporateOptions: null,
  serviceProviderOptions: null,

  appliedSavedFilter: null
};

function filtersSuccess(state: FiltersState = initialState, data: IFilter[]): FiltersState {
  return {
    ...state,
    filters: data,
    error: ""
  };
}

function filtersFault(state: FiltersState = initialState, data: string): FiltersState {
  return {
    ...state,
    filters: null,
    error: data
  };
}

function setStartupOptions(state: FiltersState = initialState, data: IStartupOptions): FiltersState {
  return {
    ...state,
    startupOptions: data
  };
}


function setInvestorOptions(state: FiltersState = initialState, data: IInvestorOptions): FiltersState {
  return {
    ...state,
    investorOptions: data
  };
}


function setMentorOptions(state: FiltersState = initialState, data: IMentorOptions): FiltersState {
  return {
    ...state,
    mentorOptions: data
  };
}

function setCorporateOptions(state: FiltersState = initialState, data: ICorporateOptions): FiltersState {
  return {
    ...state,
    corporateOptions: data
  };
}

function setServiceProviderOptions(state: FiltersState = initialState, data: IServiceProviderOptions): FiltersState {
  return {
    ...state,
    serviceProviderOptions: data
  };
}


function setAppliedSavedFilter(state: FiltersState = initialState, data: IFilter): FiltersState {
  return {
    ...state,
    appliedSavedFilter: data
  };
}



export function filtersReducer(state: FiltersState = initialState, action: FiltersActions): FiltersState {
  switch (action.type) {
    case FiltersActionTypes.GetFiltersSuccess:
      return filtersSuccess(state, action.payload);
    case FiltersActionTypes.GetFiltersFault:
      return filtersFault(state, action.payload);

    case FiltersActionTypes.SetStartupFilterOptions:
      return setStartupOptions(state, action.payload);
    case FiltersActionTypes.SetInvestorFilterOptions:
      return setInvestorOptions(state, action.payload);
    case FiltersActionTypes.SetMentorFilterOptions:
      return setMentorOptions(state, action.payload);
    case FiltersActionTypes.SetCorporateFilterOptions:
      return setCorporateOptions(state, action.payload);
    case FiltersActionTypes.SetServiceProviderFilterOptions:
      return setServiceProviderOptions(state, action.payload);

    case FiltersActionTypes.SetAppliedSavedFilter:
      return setAppliedSavedFilter(state, action.payload);
    default:
      return state;
  }
}


