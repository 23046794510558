import { Component, OnInit, ChangeDetectionStrategy, AfterViewInit, Input } from '@angular/core';

export interface Submenu {
  id: number,
  menu: string,
  link: string | null,
  haveExternalLink: boolean
}

export interface OffCanvasMenu {
  id: number,
  menu: string,
  link: string | null,
  haveSubmenu: boolean,
  subMenuList: Submenu[],
  haveExternalLink: boolean
}

@Component({
  selector: 'app-offcanvas-sidebar',
  templateUrl: './offcanvas-sidebar.component.html',
  styleUrls: ['./offcanvas-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OffcanvasSidebarComponent implements OnInit, AfterViewInit {

  @Input() public menuList: Array<OffCanvasMenu>;

  constructor() { }

  ngAfterViewInit(): void {
    window.addEventListener('click', (e: any) => {
      if (document.getElementById('mySidenav') !== null) {
        if (!document.getElementById('mySidenav').contains(e.target)) {
          if (document.getElementById("mySidenav").clientWidth === 275) {
            this.closeNav();
          }
        }
      }
    });
  }

  ngOnInit(): void {
  }

  openNav() {
    document.getElementById("mySidenav").style.width = "275px";
    document.getElementById("mySidenav").style.padding = "1.25rem";
    // document.body.style.backgroundColor = "rgba(0,0,0,0.4)";
  }

  closeNav() {
    document.getElementById("mySidenav").style.width = "0";
    document.getElementById("mySidenav").style.padding = "0";
    // document.body.style.backgroundColor = "white";
  }


}
