import { Action } from "@ngrx/store";
import { Approval, StartupProfileCompleteness, StartupInvestorDashboardResponse, StartupInvestorProfileCompletenessResponse } from "../../domain";


export enum StartupDashboardActionTypes {
    // Startup Dashboard
    GetStartupDashboard = "[Startup Dashboard] GetStartupDashboard",
    GetStartupDashboardSuccess = "[Startup Dashboard] GetStartupDashboardSuccess",
    GetStartupDashboardFault = "[Startup Dashboard] GetStartupDashboardFault",

    // Request Approval
    SendRequestApproval = '[Startup Dashboard] SendRequestApproval',
    SendRequestApprovalSuccess = "[Startup Dashboard] SendRequestApprovalSuccess",
    SendRequestApprovalFault = "[Startup Dashboard] SendRequestApprovalFault",

    // Rasing Fund
    RasingFundRequest = "[Startup Dashboard] RasingFundRequest",
    RasingFundRequestSuccess = "[Startup Dashboard] RasingFundRequestSuccess",
    RasingFundRequestFault = "[Startup Dashboard] RasingFundRequestFault",


    GetProfileCompleteness = "[Startup Dashboard] GetProfileCompleteness",
    GetProfileCompletenessSuccess = "[Startup Dashboard] GetProfileCompletenessSuccess",
    GetProfileCompletenessFault = "[Startup Dashboard] GetProfileCompletenessFault",
}

export class GetStartupDashboard implements Action {
    readonly type = StartupDashboardActionTypes.GetStartupDashboard;
}

export class GetStartupDashboardSuccess implements Action {
    readonly type = StartupDashboardActionTypes.GetStartupDashboardSuccess;
    constructor(public payload: StartupInvestorDashboardResponse) { }
}

export class GetStartupDashboardFault implements Action {
    readonly type = StartupDashboardActionTypes.GetStartupDashboardFault;
    constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
    readonly type = StartupDashboardActionTypes.SendRequestApproval;
    constructor(public payload: Approval) { }
}

export class SendRequestApprovalSuccess implements Action {
    readonly type = StartupDashboardActionTypes.SendRequestApprovalSuccess;
    constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
    readonly type = StartupDashboardActionTypes.SendRequestApprovalFault;
    constructor(public payload: string) { }
}

export class RasingFundRequest implements Action {
    readonly type = StartupDashboardActionTypes.RasingFundRequest;
}

export class RasingFundRequestSuccess implements Action {
    readonly type = StartupDashboardActionTypes.RasingFundRequestSuccess;
    constructor(public payload: string) { }
}

export class RasingFundRequestFault implements Action {
    readonly type = StartupDashboardActionTypes.RasingFundRequestFault;
    constructor(public payload: string) { }
}


export class GetProfileCompleteness implements Action {
  readonly type = StartupDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = StartupDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: StartupProfileCompleteness) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = StartupDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export type StartupDashBoardActions =
    | SendRequestApproval
    | SendRequestApprovalSuccess
    | SendRequestApprovalFault
    | RasingFundRequest
    | RasingFundRequestSuccess
    | RasingFundRequestFault
    | GetStartupDashboard
    | GetStartupDashboardSuccess
    | GetStartupDashboardFault
    | GetProfileCompletenessFault
    | GetProfileCompletenessSuccess
    | GetProfileCompleteness;
