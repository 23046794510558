import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, map, mergeMap } from "rxjs/operators";
import { ServiceProviderProfileCompleteness } from "../../domain";
import * as ServiceProviderDashboardActions from "./service-provider.dashboard.action";
import { ServiceProviderDashboardActionTypes } from './service-provider.dashboard.action';
import { ServiceProviderService } from "../../service/service-provider.service";

@Injectable()
export class ServiceProviderDashboardEffect {

  getServiceProviderDashboard$ = createEffect(() => this.actions$.pipe(
    ofType<ServiceProviderDashboardActions.GetServiceProviderDashboard>(ServiceProviderDashboardActionTypes.GetServiceProviderDashboard),
    exhaustMap(() => this.serviceProviderService.getServiceProviderDashboard().pipe(
      map((data: any) => new ServiceProviderDashboardActions.GetServiceProviderDashboardSuccess(data)),
      catchError((err: HttpErrorResponse) => of(new ServiceProviderDashboardActions.GetServiceProviderDashboardFault(err.error.message)))
    ))
  ))


  getProfileCompleteness$ = createEffect(() => this.actions$.pipe(
    ofType<ServiceProviderDashboardActions.GetProfileCompleteness>(ServiceProviderDashboardActionTypes.GetProfileCompleteness),
    map((action: ServiceProviderDashboardActions.GetProfileCompleteness) => action),
    exhaustMap(() => this.serviceProviderService.getServiceProviderProfileCompleteness().pipe(
      mergeMap((data: ServiceProviderProfileCompleteness) => [
        new ServiceProviderDashboardActions.GetProfileCompletenessSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new ServiceProviderDashboardActions.GetProfileCompletenessFault(err.error.message)))
    ))
  ));

  sendApprovalRequest$ = createEffect(() => this.actions$.pipe(
    ofType<ServiceProviderDashboardActions.SendRequestApproval>(ServiceProviderDashboardActionTypes.SendRequestApproval),
    map((action: ServiceProviderDashboardActions.SendRequestApproval) => action),
    exhaustMap(() => this.serviceProviderService.sendApprovalRequest().pipe(
      mergeMap((data: string) => [
        new ServiceProviderDashboardActions.SendRequestApprovalSuccess(data),
      ]),
      catchError((err: HttpErrorResponse) => of(new ServiceProviderDashboardActions.SendRequestApprovalFault(err.error.message)))
    )),
  ));

  constructor(
    private actions$: Actions,
    private serviceProviderService: ServiceProviderService
  ) { }
}
