import { createFeatureSelector, createSelector } from "@ngrx/store";
import { StartupDashboardState } from "./startup.dashboard.reducer";

export const selectStartupDashboardState = createFeatureSelector<StartupDashboardState>("startupDashboard");

export const getStartupDashboard = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state.startupDashboard
)


export const getStartupProfileCompleteness = createSelector(
  selectStartupDashboardState,
  (state: StartupDashboardState) => state.startupProfileCompleteness
)

export const sendRequestApproval = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state
)

export const sendRequestApprovalSuccess = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state.requestApproval
)

export const sendRequestApprovalFail = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state.requestApprovalError
)

export const rasingFundRequest = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state
)

export const rasingFundRequestSuccess = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state.raisingFundsRequest
)

export const rasingFundRequestFault = createSelector(
    selectStartupDashboardState,
    (state: StartupDashboardState) => state.raisingFundsRequestError
)
