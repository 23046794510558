import { PartnersInformation } from "../../domain/partners.model";
import { PartnersActions, PartnersActionTypes } from "./partners.action";


export interface PartnersState {
    partnersInformationList: PartnersInformation[];
    partnersInformation: PartnersInformation;
    partnersInformationLoading: boolean;
    partnersInformationError: string | null | string[];
}

export const initialState: PartnersState = {
    partnersInformationList: null,
    partnersInformation: null,
    partnersInformationLoading: false,
    partnersInformationError: ""
}

function initPartnerInformationList(state: PartnersState = initialState): PartnersState {
    return {
        ...state,
        partnersInformationLoading: true,
        partnersInformationError: ""
    };
}

function PartnerInformationListSuccess(state: PartnersState = initialState, data: any): PartnersState {
    return {
        ...state,
        partnersInformationList: data,
        partnersInformationLoading: false,
        partnersInformationError: ""
    }
}

function PartnerInformationListFailed(state: PartnersState = initialState, data: string): PartnersState {
    return {
        ...state,
        partnersInformationList: null,
        partnersInformationLoading: false,
        partnersInformationError: data
    }
}

function initPartnerInformation(state: PartnersState = initialState): PartnersState {
    return {
        ...state,
        partnersInformationLoading: true,
        partnersInformationError: ""
    };
}

function PartnerInformationSuccess(state: PartnersState = initialState, data: PartnersInformation): PartnersState {
    return {
        ...state,
        partnersInformation: data,
        partnersInformationLoading: false,
        partnersInformationError: ""
    }
}

function PartnerInformationFailed(state: PartnersState = initialState, data: string): PartnersState {
    return {
        ...state,
        partnersInformation: null,
        partnersInformationLoading: false,
        partnersInformationError: data
    }
}

export function startUpDashboardReducer(state: PartnersState = initialState, action: PartnersActions): PartnersState {

    switch (action.type) {
        case PartnersActionTypes.GetPartnersInformationList:
            return initPartnerInformationList(state);

        case PartnersActionTypes.GetPartnersInformationListSuccess:
            return PartnerInformationListSuccess(state, action.payload);

        case PartnersActionTypes.GetPartnersInformationListFault:
            return PartnerInformationListFailed(state, action.payload);

        case PartnersActionTypes.GetPartnersInformation:
            return initPartnerInformation(state);

        case PartnersActionTypes.GetPartnersInformationSuccess:
            return PartnerInformationSuccess(state, action.payload);

        case PartnersActionTypes.GetPartnersInformationFault:
            return PartnerInformationFailed(state, action.payload);

        default:
            return state;
    }
}