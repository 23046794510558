import { StorageService } from "src/app/shared/utils/storage.service";
import { IBrandDetails } from "../../domain/brand.model";
import { IGlobalSettings } from "../../domain/settings.model";
import {
  GlobalActions,
  GlobalActionTypes
} from "./global.action";

export interface GlobalState {
  settings: IGlobalSettings,
  error: string | null,
  brandDetails: Partial<IBrandDetails>,
  formsList: any[]
}

export const initialState: GlobalState = {
  settings: null,
  error: "",
  brandDetails: JSON.parse(localStorage.getItem(StorageService.storageKeys.brandDetails)),
  formsList: []
};

function globalSettingsSuccess(state: GlobalState = initialState, data: IGlobalSettings): GlobalState {
  return {
    ...state,
    settings: data,
    error: ""
  };
}

function globalSettingsFault(state: GlobalState = initialState, data: string): GlobalState {
  return {
    ...state,
    settings: null,
    error: data
  };
}

function setBrandDetails(state: GlobalState = initialState, data: IBrandDetails): GlobalState {
  return {
    ...state,
    brandDetails: data
  };
}

function setFormsList(state: GlobalState = initialState, data: any): GlobalState {
  return {
    ...state,
    formsList: data
  };
}


export function globalReducer(state: GlobalState = initialState, action: GlobalActions): GlobalState {
  switch (action.type) {
    case GlobalActionTypes.GetGlobalSettingsSuccess:
      return globalSettingsSuccess(state, action.payload);
    case GlobalActionTypes.GetGlobalSettingsFault:
      return globalSettingsFault(state, action.payload);
    case GlobalActionTypes.SetBrandDetails:
      return setBrandDetails(state, action.payload);
    case GlobalActionTypes.SetFormsList:
      return setFormsList(state, action.payload);
    default:
      return state;
  }
}


