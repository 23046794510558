import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PitchState } from "./pitch-deck.reducer";

export const selectPitchState = createFeatureSelector<PitchState>("pitch");

export const getPitchData = createSelector(
    selectPitchState,
    (state: PitchState) => state.pitch
);

export const getSelectedCategory = createSelector(
    selectPitchState,
    (state: PitchState,props) => state?.pitch?.formattedResults[props]
);

export const getSelectedTopic = createSelector(
    selectPitchState,
    (state: PitchState, props) => state?.pitch?.formattedResults[props].categoryTopics[0]
);
