import { JobsState } from "./jobs.reducer";
import { createFeatureSelector, createSelector } from "@ngrx/store";


export const jobsState = createFeatureSelector<JobsState>("jobs");

export const getJobsList = createSelector(
    jobsState,
    (state: JobsState) => state.jobsList
)


export const getJobsLoader = createSelector(
    jobsState,
    (state: JobsState) => state.jobsLoading
)
