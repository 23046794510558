import { Action } from "@ngrx/store";
import { PartnersInformation } from "../../domain/partners.model";


export enum ChallengesActionTypes {
    GetChallengesList = "[Challenges] GetChallengesList",
    GetChallengesListSuccess = "[Challenges] GetChallengesListSuccess",
    GetChallengesListFault = "[Challenges] GetChallengesListFault",
}

export class GetChallengesList implements Action {
    readonly type = ChallengesActionTypes.GetChallengesList;
    constructor(public filters?: any) { }
}

export class GetChallengesListSuccess implements Action {
    readonly type = ChallengesActionTypes.GetChallengesListSuccess;
    constructor(public payload: PartnersInformation) { }
}

export class GetChallengesListFault implements Action {
    readonly type = ChallengesActionTypes.GetChallengesListFault;
    constructor(public payload: string) { }
}


export type ChallengesAction =
    | GetChallengesList
    | GetChallengesListSuccess
    | GetChallengesListFault;
