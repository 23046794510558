import { Action } from "@ngrx/store";
import { PartnersInformation } from "../../domain/partners.model";


export enum PartnersActionTypes {
    // Partners Information List
    GetPartnersInformationList = "[Partners Information] Get Partners Information List",
    GetPartnersInformationListSuccess = "[Partners Information] Get Partners Information List Success",
    GetPartnersInformationListFault = "[Partners Information] Get Partners Information List Fault",

    // Partners Information 
    GetPartnersInformation = "[Partners Information] Get Partners Information",
    GetPartnersInformationSuccess = "[Partners Information] Get Partners Information Success",
    GetPartnersInformationFault = "[Partners Information] Get Partners Information Fault",
}

export class GetPartnersInformationList implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformationList;
}

export class GetPartnersInformationListSuccess implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformationListSuccess;
    constructor(public payload: PartnersInformation) { }
}

export class GetPartnersInformationListFault implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformationListFault;
    constructor(public payload: string) { }
}

export class GetPartnersInformation implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformation;
    constructor(public payload: string) { }
}

export class GetPartnersInformationSuccess implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformationSuccess;
    constructor(public payload: PartnersInformation) { }
}

export class GetPartnersInformationFault implements Action {
    readonly type = PartnersActionTypes.GetPartnersInformationFault;
    constructor(public payload: string) { }
}

export type PartnersActions =
    | GetPartnersInformationList
    | GetPartnersInformationListSuccess
    | GetPartnersInformationListFault
    | GetPartnersInformation
    | GetPartnersInformationSuccess
    | GetPartnersInformationFault;