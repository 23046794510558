import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MentorDashboardState } from "./mentors.dashboard.reducer";

export const selectMentorDashboardState = createFeatureSelector<MentorDashboardState>("mentorDashboard");

export const getMentorProfileCompleteness = createSelector(
  selectMentorDashboardState,
  (state: MentorDashboardState) => state.mentorProfileCompleteness
)

export const getMentorDashboard = createSelector(
  selectMentorDashboardState,
  (state: MentorDashboardState) => state.mentorDashboard
)
