import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  Actions,
  createEffect,
  ofType
} from "@ngrx/effects";
import {
  Action,
  Store
} from "@ngrx/store";
import {
  of
} from "rxjs";
import {
  catchError,
  exhaustMap,
  map,
} from "rxjs/operators";
import {
    IStartUpKitModel,
} from "../../domain";
import { PublicApiService } from "../../service/public-api.service";
import * as StartUpKitActions from "./start-up-kit.action";
import { StartUpKitActionTypes } from "./start-up-kit.action";

@Injectable()
export class StartUpKitEffect {

  getStartUpKit$ = createEffect(() => this.actions$.pipe(
    ofType<StartUpKitActions.GetStartUpKit>(StartUpKitActionTypes.GetStartUpKit),
    exhaustMap(() =>
      this.publicApiService.fetchStartUpKit().pipe(
        map((data: IStartUpKitModel) => new StartUpKitActions.StartUpKitSuccess(data)),
        catchError((err: HttpErrorResponse) => of(new StartUpKitActions.StartUpKitFault(err.message)))
      )
    )
  )
  );

  constructor(private actions$: Actions, private store$: Store<any>, private publicApiService: PublicApiService) {
  }
}
