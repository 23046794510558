export const MAX_COMPARISION = 4;

export const LOGO_SIZE_LIMIT =  512 * 1024; // 512 kb
export const  meetingTypeLabel = {
  'job-interview': 'Job interview',
  'fundraising': 'Fundraising'
}


export const InterviewAfterStatusLabel  = {
  selected: 'Selected',
  next_round: 'Next round',
  rejected: 'Rejected',
  poor_network: 'Poor network',
  panel_did_not_join: 'Panel did not join',
  candidate_left: 'Candidate left',
  // SELECTED = 'selected',
  // NEXT_ROUND = 'next_round',
  // REJECTED = 'rejected',
  // POOR_NETWORK = 'poor_network',
  // PANEL_DID_NOT_JOIN = 'panel_did_not_join',
  // CANDIDATE_LEFT = 'candidate_left',
}


export const AutoApplyProgramOffSecret = 'aj3839ll'
