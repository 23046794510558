import { Action } from "@ngrx/store";
import { Approval } from "../../domain";

export enum IndividualProfileDashboardActionTypes {
  GetProfileCompleteness = "[Individual Dashboard] GetIndividualProfileProfileCompleteness",
  GetProfileCompletenessSuccess = "[Individual Dashboard] GetIndividualProfileProfileCompletenessSuccess",
  GetProfileCompletenessFault = "[Individual Dashboard] GetIndividualProfileProfileCompletenessFault",

  // Request Approval
  SendRequestApproval = '[Individual Dashboard] SendRequestApproval',
  SendRequestApprovalSuccess = "[Individual Dashboard] SendRequestApprovalSuccess",
  SendRequestApprovalFault = "[Individual Dashboard] SendRequestApprovalFault",

  // GetMentorDashboard = "[InvestorProfile Dashboard] GetMentorDashboard",
  // GetMentorDashboardSuccess = "[InvestorProfile Dashboard] GetMentorDashboardSuccess",
  // GetMentorDashboardFault = "[InvestorProfile Dashboard] GetMentorDashboardFault",

}


// export class GetMentorDashboard implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboard;
// }

// export class GetMentorDashboardSuccess implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboardSuccess;
//   constructor(public payload: any) { }
// }

// export class GetMentorDashboardFault implements Action {
//   readonly type = InvestorProfileDashboardActionTypes.GetMentorDashboardFault;
//   constructor(public payload: string) { }
// }


export class GetProfileCompleteness implements Action {
  readonly type = IndividualProfileDashboardActionTypes.GetProfileCompleteness;
}

export class GetProfileCompletenessSuccess implements Action {
  readonly type = IndividualProfileDashboardActionTypes.GetProfileCompletenessSuccess;
  constructor(public payload: any) { }
}

export class GetProfileCompletenessFault implements Action {
  readonly type = IndividualProfileDashboardActionTypes.GetProfileCompletenessFault;
  constructor(public payload: string) { }
}

export class SendRequestApproval implements Action {
  readonly type = IndividualProfileDashboardActionTypes.SendRequestApproval;
}

export class SendRequestApprovalSuccess implements Action {
  readonly type = IndividualProfileDashboardActionTypes.SendRequestApprovalSuccess;
  constructor(public payload: string) { }
}

export class SendRequestApprovalFault implements Action {
  readonly type = IndividualProfileDashboardActionTypes.SendRequestApprovalFault;
  constructor(public payload: string) { }
}

export type IndividualProfileDashBoardActions =
  // GetMentorDashboard
  // | GetMentorDashboardSuccess
  // | GetMentorDashboardFault
  | GetProfileCompleteness
  | GetProfileCompletenessSuccess
  | GetProfileCompletenessFault
  | SendRequestApproval
  | SendRequestApprovalSuccess
  | SendRequestApprovalFault;
