import { Action } from "@ngrx/store";
import {
    Glossary, GlossaryModel
} from "../../domain";

export enum GlossaryActionTypes {
    GetGlossary = "[Glossary] GetGlossary",
    GlossarySuccess = "[Glossary] GlossarySuccess",
    GlossaryFault = "[Glossary] GlossaryFault",
  }

export class GetGlossary implements Action {
    readonly type = GlossaryActionTypes.GetGlossary;

    constructor() {
    }
}

export class GlossarySuccess implements Action {
    readonly type = GlossaryActionTypes.GlossarySuccess;

    constructor(public payload: GlossaryModel) {
    }
}

export class GlossaryFault implements Action {
    readonly type = GlossaryActionTypes.GlossaryFault;

    constructor(public payload: string) {
    }
}



export type GlossaryActions =
    | GetGlossary
    | GlossarySuccess
    | GlossaryFault
    ;
