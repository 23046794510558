import { Action } from "@ngrx/store";
import { RegisterModel, RegisterResponsePayload, SignupCredentials, SingUpSendOtpResponse, SingUpVerifyOtpResponse } from "../../domain";

export enum SignUpActionTypes {
    SendOtp = "[SingUp] SendOtp",
    SendOtpSuccess = "[SingUp] SendOtpSuccess",
    SendOtpFault = "[SingUp] SendOtpFault",

    VerifyOtp = "[SingUp] VerifyOtp",
    VerifyOtpSuccess = "[SingUp] VerifyOtpSuccess",
    VerifyOtpFault = "[SingUp] VerifyOtpFault",

    Register = "[SingUp] Register",
    RegisterSuccess = "[SingUp] RegisterSuccess",
    RegisterFault = "[SingUp] RegisterFault",

    ResetSignUpError = "[SingUp] ResetSignUpError",
}

export class SendOtp implements Action {
    readonly type = SignUpActionTypes.SendOtp;

    constructor(public payload: SignupCredentials) {
    }
}

export class SendOtpSuccess implements Action {
    readonly type = SignUpActionTypes.SendOtpSuccess;

    constructor(public payload: SingUpSendOtpResponse) {
    }
}

export class SendOtpFault implements Action {
    readonly type = SignUpActionTypes.SendOtpFault;

    constructor(public payload: string) {
    }
}

export class VerifyOtp implements Action {
    readonly type = SignUpActionTypes.VerifyOtp;

    constructor(public payload: SignupCredentials) {
    }
}

export class VerifyOtpSuccess implements Action {
    readonly type = SignUpActionTypes.VerifyOtpSuccess;

    constructor(public payload: SingUpVerifyOtpResponse) {
    }
}

export class VerifyOtpFault implements Action {
    readonly type = SignUpActionTypes.VerifyOtpFault;

    constructor(public payload: string) {
    }
}

export class Register implements Action {
    readonly type = SignUpActionTypes.Register

    constructor(public payload: RegisterModel) {
    }
}

export class RegisterSuccess implements Action {
    readonly type = SignUpActionTypes.RegisterSuccess

    constructor(public payload: RegisterResponsePayload) {
    }
}

export class RegisterFault implements Action {
    readonly type = SignUpActionTypes.RegisterFault

    constructor(public payload: string) {
    }
}

export class ResetSignUpError implements Action {
    readonly type = SignUpActionTypes.ResetSignUpError;

    constructor() {
    }
}

export type SignUpActions =
    | SendOtp
    | SendOtpSuccess
    | SendOtpFault
    | VerifyOtp
    | VerifyOtpSuccess
    | VerifyOtpFault
    | Register
    | RegisterSuccess
    | RegisterFault
    | ResetSignUpError
    ;