import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, exhaustMap, map, mergeMap, of } from "rxjs";
import { IStartupCompareResponse } from "../../domain/startup-investor-compare.model";
import { StartupCompareService } from "../../service";
import * as StartupCompareActions from "./startup.compare.action";
import { StartupCompareActionTypes } from "./startup.compare.action";

@Injectable()
export class StartupCompareEffect {


    getCompareDashboard$ = createEffect(() => this.actions$.pipe(
        ofType<StartupCompareActions.GetStartupCompare>(StartupCompareActionTypes.GetStartupCompare),
        map((action: StartupCompareActions.GetStartupCompare) => action.payload),
        exhaustMap((ids: string) => this.startupCompareService.getStartupCompare(ids).pipe(
            mergeMap((data: IStartupCompareResponse) => [
                new StartupCompareActions.GetStartupCompareSuccess(data),
            ]),
            catchError((err: HttpErrorResponse) => of(new StartupCompareActions.GetStartupCompareFault(err.error.message)))
        ))
    ));

    constructor(private actions$: Actions, private startupCompareService: StartupCompareService) { }
}
