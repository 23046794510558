import { Action } from "@ngrx/store";
import { IStartupCompareResponse } from "../../domain/startup-investor-compare.model";

export enum StartupCompareActionTypes {
    // Startup Compare
    GetStartupCompare = "[Startup Compare] GetStartupCompare",
    GetStartupCompareSuccess = "[Startup Compare] GetStartupCompareSuccess",
    GetStartupCompareFault = "[Startup Compare] GetStartupCompareFault",
}

export class GetStartupCompare implements Action {
    readonly type = StartupCompareActionTypes.GetStartupCompare;
    constructor(public payload: string) { }
}

export class GetStartupCompareSuccess implements Action {
    readonly type = StartupCompareActionTypes.GetStartupCompareSuccess;
    constructor(public payload: IStartupCompareResponse) { }
}

export class GetStartupCompareFault implements Action {
    readonly type = StartupCompareActionTypes.GetStartupCompareFault;
    constructor(public payload: string) { }
}

export type StartupCompareActions =
    | GetStartupCompare
    | GetStartupCompareSuccess
    | GetStartupCompareFault;
